
/*
 *   File : select.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Select component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
*/

/*import package*/
import React from 'react';
import Select from 'react-select';

/*import style*/
import styles from '../../styles/components/select.module.scss';

const SelectBox = (props) => {

  const valueChange = (e) => {
    props.selectchange(e)

  }

  return (
    <div className="e-select-wraper">
      <Select
        isSearchable={props.isSearchable}
        isClearable={props.isClearable}
        //eslint-disable-next-line
        className={`${styles.e_select_box}` + " " + props.className}
        onChange={(e) => { valueChange(e) }}
        isDisabled={props.isDisabled}
        options={props.options}
        value={props.value}
        isMulti={props.selectType}
        placeholder={props.placeholder}
        // defaultMenuIsOpen="true"
        defaultValue={props.defaultValue}
        // menuIsOpen={true}
        {...props} />
    </div>

  );
}

export default SelectBox;


