/*
 *   File : editScrips.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Edit Scrip Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-03-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';


/*import componets*/
import { PrimaryBtn } from '../../components/cta/index';
import { ProgressBar } from '../formElements';
import services from '../../services/service';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/scripmodal.module.scss';

/*import assets */
import EmptyScreen from '../emptyScreen/empty';
import Loader from '../loader/loader';
import Minus from '../../assets/images/dataFormatting/minus.svg';
import Plus from '../../assets/images/dataFormatting/plus.svg';
import MinusDark from '../../assets/images/dataFormatting/minus-dark.svg';
import PlusDark from '../../assets/images/dataFormatting/plus-dark.svg';
import ScripRemoveDark from '../../assets/images/modal/scrip-delete-dark.png';
import ScripRemove from '../../assets/images/modal/scrip-delete.png';


let GAINER_COUNT = 1;
let LOSER_COUNT = 1;

const EditBookings = React.memo(function EditBookings(props) {
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    //eslint-disable-next-line
    const [update, setUpdate] = useState(false);
    //eslint-disable-next-line
    const [gainer, setGainer] = useState();
    //eslint-disable-next-line
    const [loser, setLoser] = useState();
    const [inputIndex, setInputIndex] = useState();
    const [bookingData, setBookingData] = useState([]);
    const [availableArr, setAvailableArr] = useState([]);

    const TOTAL = [];
    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        _getAllBookings();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        var myModalEl = document.getElementById('editBookings')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        // var myModalEl = document.getElementById('editBookings')
        // myModalEl.addEventListener('shown.bs.modal', (event) => {
        if (props.updated) {
            _getAllBookings();
        }
        // })
        //eslint-disable-next-line
    }, [props.updated]);

    // useEffect(() => {
    //     if (props.updated) {
    //         _getAllBookings()
    //     }
    // }, [props.updated])

    const _getAllBookings = () => {
        setLoader(true);
        var url = 'customer/game/m2m-all-bookings';
        services(false, url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setBookingData(response.data);
                let temp_available_arr = []
                for (let i = 0; i < response.data.length; i++) {
                    // availableArr Object.values(response.data[i].bookings)
                    //eslint-disable-next-line
                    Object.values(response.data[i].bookings).map((item) => {
                        if (item.performer_unit > 0 || item.under_performer_unit > 0) {
                            return (
                                temp_available_arr.push(item)
                            )
                        }
                    });
                    setAvailableArr(temp_available_arr);
                }
                localStorage.removeItem("count");
                props.getBookings(response.data);
            } else {
                setBookingData([]);
                setLoader(false);
                props.getBookings([]);
            }
        })
    }

    /* ---- Edit scrip ----*/
    const _EditScrip = async () => {

        setLoader(true);
        var availableArr = []
        //eslint-disable-next-line
        bookingData.map((item) => {
            //eslint-disable-next-line
            Object.values(item.bookings).filter((check) => check.booking_id !== null).map((booking) => {
                return (
                    availableArr.push({
                        "booking_id": booking.booking_id,
                        "perform_qty": booking.performer_unit,
                        "under_perform_qty": booking.under_performer_unit

                    })
                )

            })
        })

        const url = "customer/update-m2m-bookings";
        const data = {
            "booking_data": availableArr
        };


        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                props.update();
                $('#editBookings').trigger('click');
                setLoader(false)

            } else {
                setLoader(false);
                setError(response.message)
            }
        });
    }

    const findTotal = (temp) => {
        var sum = 0;
        if (TOTAL && TOTAL.length) {
            var lodash = require('lodash');
            sum = lodash.sum(TOTAL);
        }
        if (sum) {
            sum = sum.toLocaleString('en-IN')
        }
        return sum;

        // setTotal(sum);
    }


    // const selectScrips = (temp, idx, check) => {
    //     let sum = []
    //     for (let i = 0; i < bookingData.length; i++) {
    //         let arr = Object.values(bookingData[i].bookings).filter((checkValue) => checkValue.performer_unit > 0).length
    //         sum.push(arr)
    //     }

    //     let tempArray = [...activityIds];

    //     let itemIndex = tempArray.indexOf(temp.booking_id);

    //     if (itemIndex < 0) {
    //         tempArray.push(temp.booking_id);
    //     } else {
    //         tempArray.splice(itemIndex, 1);
    //     }
    //     setActivityIds(tempArray);

    //     var len = sum.reduce((a, b) => a + b)
    //     if (tempArray.length === len) {
    //         setActivityAll(true)
    //     } else {
    //         setActivityAll(false)
    //     }

    // }

    // var availableArr = []
    // for (let i = 0; i < bookingData.length; i++) {

    //     availableArr = [...Object.values(bookingData[i].bookings)]
    // }

    return (
        <div className={`${styles.e_scrip_modal} e-kyc-modal modal fade e-game-detail-wrapper`}
            id="editBookings"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <h4 className='ps-0 mt-2'>
                            Edit slot
                        </h4>
                        <button
                            type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    {
                        loader ?
                            <Loader />
                            :
                            bookingData.length > 0 ?
                                <>
                                    {
                                        update === false ?
                                            <>{
                                                availableArr.some((booking) => booking.performer_unit > 0 || booking.under_performer_unit > 0) ?

                                                    <div className={` px- 2 ${styles.e_modal_body}`}>
                                                        {
                                                            bookingData.map((booking, index) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            Object.values(booking.bookings).some((checkValue) => checkValue.performer_unit > 0) ?
                                                                                <div className="row pt-4">
                                                                                    <div className='col-lg-5 col-md-5 col-sm-4 col-4'>
                                                                                        <label className='mb-2 e-secondary-color' style={{ color: '#fff', fontWeight: '600' }}>
                                                                                            Symbol
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-lg-3 col-md-3 col-sm4  col-4 text-start'>
                                                                                        <label className='mb-2 e-secondary-color'>
                                                                                            Gainer qty.
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-lg-2 col-md-2 col-sm-2 col-2 text-end '>
                                                                                        <label className='mb-2 e-secondary-color'>
                                                                                            Amount
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-lg-1 col-1'></div>
                                                                                    <div className='d-flex mt-4' style={{ color: "white" }}>

                                                                                        <span className={`e-secondary-color`} >
                                                                                            {`Slot - ${booking.slot_start_time} - ${booking.slot_end_time}`}
                                                                                            {/* <div ref={slotRef}> */}

                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            Object.values(booking.bookings)?.map((item, idx) => {

                                                                                if (item.performer_unit === 0 && idx !== inputIndex) {
                                                                                    return false
                                                                                }
                                                                                TOTAL.push(item.performer_unit * props.unitPrice);
                                                                                $(`#edit-pr-input-${item.scrip_id}`).val(item.performer_unit);
                                                                                GAINER_COUNT = GAINER_COUNT + 1;
                                                                                return (
                                                                                    <>

                                                                                        <div className={`row ${styles.e_row}`}>

                                                                                            <div className='col-lg-5 col-md-5 col-sm-4 col-4 mt-4'>
                                                                                                <h6 className='e-edit-modal-symbol '>
                                                                                                    {item.scrip_code}
                                                                                                </h6>
                                                                                            </div>
                                                                                            <div className='col-lg-3 col-md-3 col-sm-4  col-4 '>
                                                                                                <span className='e-span e-edit-scrip-btn-input d-flex justify-content-between mt-3'>
                                                                                                    <img alt=""
                                                                                                        src={
                                                                                                            localStorage.getItem("mode") === "true" ?
                                                                                                                Minus
                                                                                                                :
                                                                                                                MinusDark
                                                                                                        }
                                                                                                        draggable={false}
                                                                                                        className={
                                                                                                            item.performer_unit < 1 ?
                                                                                                                'e-minus e-disabled-span-input'
                                                                                                                :
                                                                                                                'e-minus '
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            let value = Number(item.performer_unit) - 1;
                                                                                                            if (value < 1) {
                                                                                                                value = 0;
                                                                                                            }
                                                                                                            let tempArr = bookingData;

                                                                                                            $(`#edit-pr-input-${item.scrip_id}`).val(value);
                                                                                                            tempArr[index].bookings[item.scrip_code]['performer_unit'] = value;
                                                                                                            // let tempEditArr = editableData;
                                                                                                            // tempEditArr[index].bookings[item.scrip_code]['performer_unit'] = value;
                                                                                                            // setEditableData(tempEditArr[index].bookings[item.scrip_code])

                                                                                                            setBookingData(tempArr)
                                                                                                        }} />
                                                                                                    <input className="e_table_input"
                                                                                                        onInput={(e) => { e.target.value = Math.abs(e.target.value) }}
                                                                                                        min="0"
                                                                                                        value={item.performer_unit}
                                                                                                        id={`edit-pr-input-${item.scrip_id}`}
                                                                                                        type='number'
                                                                                                        placeholder="-"
                                                                                                        onBlur={() => setInputIndex(null)}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.keyCode === 38) {
                                                                                                                let tempArr = bookingData;
                                                                                                                tempArr[index].bookings[item.scrip_code]['performer_unit'] = item.performer_unit++;
                                                                                                                setBookingData(tempArr);
                                                                                                                // let tempEditArr = editableData;
                                                                                                                // tempEditArr[index].bookings[item.scrip_code]['performer_unit'] = item.performer_unit++;;
                                                                                                                // setEditableData(tempEditArr[index].bookings[item.scrip_code])
                                                                                                            }
                                                                                                            else if (e.keyCode === 40) {
                                                                                                                if (e.target.value > 0) {
                                                                                                                    let tempArr = bookingData;
                                                                                                                    tempArr[index].bookings[item.scrip_code]['performer_unit'] = item.performer_unit--;
                                                                                                                    setBookingData(tempArr);
                                                                                                                    // let tempEditArr = editableData;
                                                                                                                    // tempEditArr[index].bookings[item.scrip_code]['performer_unit'] = item.performer_unit--;
                                                                                                                    // setEditableData(tempEditArr[index].bookings[item.scrip_code])
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                        onChange={(e) => {
                                                                                                            let tempArr = bookingData;
                                                                                                            setInputIndex(idx);
                                                                                                            tempArr[index].bookings[item.scrip_code]['performer_unit'] = e.target.value;
                                                                                                            setBookingData(tempArr);
                                                                                                            // let tempEditArr = editableData;
                                                                                                            // tempEditArr[index].bookings[item.scrip_code]['performer_unit'] = e.target.value;
                                                                                                            // setEditableData(tempEditArr[index].bookings[item.scrip_code])
                                                                                                        }}
                                                                                                    />
                                                                                                    <img alt=""
                                                                                                        src={
                                                                                                            localStorage.getItem("mode") === "true" ?
                                                                                                                Plus
                                                                                                                :
                                                                                                                PlusDark
                                                                                                        }
                                                                                                        className='e-plus'
                                                                                                        draggable={false}
                                                                                                        onClick={() => {
                                                                                                            let tempArr = bookingData;
                                                                                                            let value = Number(item.performer_unit) + 1;
                                                                                                            $(`#edit-pr-input-${item.scrip_id}`).val(value);
                                                                                                            tempArr[index].bookings[item.scrip_code]['performer_unit'] = value;
                                                                                                            setInputIndex(idx);

                                                                                                        }}
                                                                                                    />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='col-lg-2 col-md-2 col-sm-2 col-2 ps-0 text-end mt-4'>
                                                                                                <h6 className='e-edit-modal-symbol'>
                                                                                                    ₹  {item.performer_unit * props.unitPrice}
                                                                                                </h6>
                                                                                            </div>
                                                                                            <div className='col-lg-1 col-1 mt-3 pt-1'>
                                                                                                <img alt=""
                                                                                                    src={
                                                                                                        localStorage.getItem("mode") === "true" ?
                                                                                                            ScripRemove
                                                                                                            :
                                                                                                            ScripRemoveDark
                                                                                                    }
                                                                                                    width="20px"
                                                                                                    className="e-remove-scrip-btn"
                                                                                                    draggable={false}
                                                                                                    onClick={() => {
                                                                                                        let deleteTempArr = [...bookingData];
                                                                                                        deleteTempArr[index].bookings[item.scrip_code]['performer_unit'] = 0;
                                                                                                        console.log("deleted", deleteTempArr, availableArr)
                                                                                                        setBookingData(deleteTempArr);
                                                                                                        setGainer(GAINER_COUNT);


                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>

                                                                                )

                                                                            })

                                                                        }
                                                                        <>
                                                                            {
                                                                                GAINER_COUNT > 1 ?
                                                                                    <div className='col-lg-12 border-bottom pe-3 '></div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                        {
                                                                            Object.values(booking.bookings).some((checkValue) => checkValue.under_performer_unit > 0) ?
                                                                                <div className="row pt-4">
                                                                                    <div className='col-lg-5 col-md-5 col-sm-4 col-4'>
                                                                                        <label className='mb-2 e-secondary-color' style={{ color: '#fff', fontWeight: '600' }}>
                                                                                            Symbol
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-lg-3 col-md-3 col-sm4  col-4 text-start'>
                                                                                        <label className='mb-2 e-secondary-color'>
                                                                                            Loser qty.
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-lg-2 col-md-2 col-sm-2 col-2 text-end '>
                                                                                        <label className='mb-2 e-secondary-color'>
                                                                                            Amount
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-lg-1 col-1'></div>
                                                                                    <div className='d-flex mt-4' style={{ color: "white" }}>

                                                                                        <span className={`e-secondary-color`} >
                                                                                            {`Slot - ${booking.slot_start_time} - ${booking.slot_end_time}`}
                                                                                            {/* <div ref={slotRef}> */}

                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }

                                                                        {
                                                                            Object.values(booking.bookings)?.map((item, idx) => {
                                                                                // let editArray = bookingData;
                                                                                // editArray.push(item);
                                                                                if (item.under_performer_unit === 0) {
                                                                                    return false
                                                                                }
                                                                                TOTAL.push(item.under_performer_unit * props.unitPrice);
                                                                                $(`#edit-undrpr-input-${item.scrip_id}`).val(item.under_performer_unit);
                                                                                LOSER_COUNT = LOSER_COUNT + 1;
                                                                                return (
                                                                                    <>
                                                                                        {/* {
                                                                                            LOSER_COUNT <= 2 ?
                                                                                                <div className="row pt-4">
                                                                                                    <div className='col-lg-5 col-md-5 col-sm-4 col-4'>
                                                                                                        <label className='mb-2' style={{ color: '#fff', fontWeight: '600' }}>
                                                                                                            Symbol
                                                                                                        </label>

                                                                                                    </div>
                                                                                                    <div className='col-lg-3 col-md-3 col-sm-4  col-4  text-start'>
                                                                                                        <label className='mb-2'>
                                                                                                            Loser qty.
                                                                                                        </label>

                                                                                                    </div>
                                                                                                    <div className='col-lg-2 col-md-2 col-sm-2 col-2  text-end '>
                                                                                                        <label className='mb-2'>
                                                                                                            Amount
                                                                                                        </label>

                                                                                                    </div>
                                                                                                    <div className='col-lg-1 col-1'>

                                                                                                    </div>
                                                                                                    <div className='d-flex' style={{ color: "white" }}>

                                                                                                        <span className={``} >
                                                                                                            {`Slot - ${booking.slot_start_time} - ${booking.slot_end_time}`}
                                                                                                          

                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        } */}

                                                                                        <div className={`row ${styles.e_row}`}>
                                                                                            <div className='col-lg-5 col-md-5 col-sm-4 col-4 mt-4'>

                                                                                                <h6 className='e-edit-modal-symbol'>
                                                                                                    {item.scrip_code}
                                                                                                </h6>
                                                                                            </div>
                                                                                            <div className='col-lg-3 col-md-3 col-sm-4  col-4  '>
                                                                                                <span className='e-span e-edit-scrip-btn-input d-flex justify-content-between mt-3'>
                                                                                                    <img alt=""
                                                                                                        src={
                                                                                                            localStorage.getItem("mode") === "true" ?
                                                                                                                Minus
                                                                                                                :
                                                                                                                MinusDark
                                                                                                        }
                                                                                                        className={
                                                                                                            item.under_performer_unit < 1 ?
                                                                                                                'e-minus e-disabled-span-input'
                                                                                                                :
                                                                                                                'e-minus '
                                                                                                        }
                                                                                                        draggable={false}
                                                                                                        onClick={() => {
                                                                                                            let value = Number(item.under_performer_unit) - 1;
                                                                                                            if (value < 1) {
                                                                                                                value = 0;
                                                                                                            }
                                                                                                            let tempArr = bookingData;
                                                                                                            $(`#edit-undrpr-input-${item.scrip_id}`).val(value);
                                                                                                            tempArr[index].bookings[item.scrip_code]['under_performer_unit'] = parseInt(value);
                                                                                                            setBookingData(tempArr)
                                                                                                        }} />
                                                                                                    <input className="e_table_input"
                                                                                                        onInput={(e) => {
                                                                                                            e.target.value = Math.abs(e.target.value)
                                                                                                        }}
                                                                                                        min="0"
                                                                                                        id={`edit-undrpr-input-${item.scrip_id}`}
                                                                                                        type='number'
                                                                                                        placeholder="-"
                                                                                                        value={item.under_performer_unit}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.keyCode === 38) {
                                                                                                                let tempArr = bookingData;
                                                                                                                tempArr[index].bookings[item.scrip_code]['under_performer_unit'] = item.under_performer_unit++;
                                                                                                                setBookingData(tempArr)
                                                                                                            }
                                                                                                            else if (e.keyCode === 40) {
                                                                                                                if (e.target.value > 0) {
                                                                                                                    let tempArr = bookingData;
                                                                                                                    tempArr[index].bookings[item.scrip_code]['under_performer_unit'] = item.under_performer_unit--;
                                                                                                                    setBookingData(tempArr)
                                                                                                                }
                                                                                                            }
                                                                                                        }}

                                                                                                        onChange={(e) => {
                                                                                                            let tempArr = bookingData;
                                                                                                            tempArr[index].bookings[item.scrip_code]['under_performer_unit'] = parseInt(e.target.value);
                                                                                                            setBookingData(tempArr);

                                                                                                        }} />
                                                                                                    <img alt="" src={localStorage.getItem("mode") === "true" ? Plus : PlusDark} className='e-plus'
                                                                                                        // id={`under-per-plus-${row.scrip_id}`}
                                                                                                        draggable={false}
                                                                                                        onClick={() => {
                                                                                                            let tempArr = bookingData;
                                                                                                            let value = Number(item.under_performer_unit) + 1;
                                                                                                            tempArr[index].bookings[item.scrip_code]['under_performer_unit'] = parseInt(value);
                                                                                                            $(`#edit-undrpr-input-${item.scrip_id}`).val(value);
                                                                                                            setBookingData(tempArr)
                                                                                                        }} />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='col-lg-2 col-md-2 col-sm-2 col-2 ps-0 text-end mt-4'>

                                                                                                <h6 className='e-edit-modal-symbol'>
                                                                                                    ₹  {item.under_performer_unit * props.unitPrice}
                                                                                                </h6>
                                                                                            </div>
                                                                                            <div className='col-lg-1 col-1 mt-3 pt-1'>
                                                                                                <img alt=""
                                                                                                    src={
                                                                                                        localStorage.getItem("mode") === "true" ?
                                                                                                            ScripRemove
                                                                                                            :
                                                                                                            ScripRemoveDark
                                                                                                    }
                                                                                                    draggable={false}
                                                                                                    width="20px"
                                                                                                    className="e-remove-scrip-btn"
                                                                                                    onClick={() => {
                                                                                                        let deleteTempArr = bookingData;
                                                                                                        deleteTempArr[index].bookings[item.scrip_code]['under_performer_unit'] = 0;
                                                                                                        setBookingData(deleteTempArr);
                                                                                                        console.log("deleted", deleteTempArr, availableArr)
                                                                                                        setLoser(LOSER_COUNT);
                                                                                                    }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                        <>
                                                                            {
                                                                                LOSER_COUNT > 1 ?
                                                                                    <div className='col-lg-12 border-bottom pe-3 '></div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </>
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                    :
                                                    <EmptyScreen
                                                        note="No bookings found"
                                                    />
                                            }
                                                <div className='row pt-4 mt-2'>
                                                    <div className='col-lg-12'>
                                                        <div className="d-flex justify-content-between">

                                                            <div className='d-flex pt-3'>
                                                                <label className=''>Total</label>
                                                                <h6 className='mt-0 ps-1 e-secondary-color'>
                                                                    ₹ {findTotal()}
                                                                </h6>
                                                            </div>
                                                            <PrimaryBtn
                                                                label="Update"
                                                                type={2}
                                                                // handleClick={() => { setTimeout(() => { setUpdate(true) }, 2000) }}
                                                                className={loader ? "e-btn-loader ms-1" : "ms-1"}
                                                                handleClick={() => _EditScrip()}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className={`${styles.e_modal_header} modal - header mb - 2`}>
                                                    <h4 className='ps-0'>Confirmation</h4>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close">
                                                    </button>
                                                </div>


                                                <div className='row'>
                                                    <div className="offset-lg-4 col-lg-4 ">
                                                        <div className={`${styles.e_progress}`}>

                                                            <ProgressBar
                                                                percent={50}
                                                                id={1}
                                                            />


                                                        </div>


                                                    </div>
                                                    <div className='col-lg-12 text-center'>
                                                        <p>1 / 2 bookings completed</p>
                                                    </div>
                                                </div>

                                            </>
                                    }


                                </>
                                :
                                <EmptyScreen />
                    }
                </div>
            </div>
            {
                error !== null ?
                    <Toast
                        data={error}
                    />
                    :
                    null
            }
        </div>
    )
});
export default EditBookings;
