/*
 *   File : V2rSearchBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Searcj box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 02-02-2023
 */
/*import package*/
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';

/*import components */
import EmptyScreen from '../emptyScreen/empty';

/*import style*/
import styles from '../../styles/components/search.module.scss';

/*import assets */
// import CLogo from '../../assets/images/scrip/wipro.svg';
// import Tata from '../../assets/images/scrip/tata.png';
// import Yamaha from '../../assets/images/scrip/yamaha.png';
// import WIndows from '../../assets/images/scrip/windows.png';


// Service import 

import Add from '../../assets/images/v2r/add.svg'


const V2rSearchBox = (props) => {

    const [value, setValue] = useState('');
    //eslint-disable-next-line
    const [suggestion, setSuggestion] = useState(false);
    const [close, setClose] = useState(false);
    const [cursor, setCursor] = useState(0);
    const [pointer, setPointer] = useState(0);
    const [height, setHeight] = useState(0);
    const wrapRef = useRef(null);
    const [onVisit, setOnVisit] = useState(null)
    const [add, setAdd] = useState(null);
    var tempSearchArray = [];

    const closeMenu = (event) => {
        if (wrapRef.current && !wrapRef.current.contains(event.target)) {
            setClose(false);
        };
    }

    useEffect(() => {
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };

    }, []);

    useEffect(() => {
        if (props.from === 'skill-race') searchWatchList();
        //eslint-disable-next-line
    }, [value])

    useEffect(() => {
        setClose(true);
        $('#v2r-search').focus();
    }, [props.scrip])

    // useEffect(() => {
    // 	if (props.scrip.length <= 0) {
    // 		setClose(false)
    // 	}
    // }, [props.scrip])

    const valueChange = (e) => {
        setValue(e.target.value);

        props.valueChange(e.target.value);

    }

    const searchWatchList = () => {
        // setSearchLoader(true);
        // let url = 'customer/game/skill-race/search-scrip';
        // let data = JSON.stringify({
        //   query: value,

        // })
        // services(true, url, data).then((response)=>{
        //   if(response.status_code === 200){
        //     setSearchList(response.data);
        //     setSearchLoader(true);
        //     // listScrips(response.data);
        //   }
        //   else{
        //     setSearchList([]);
        //     setSearchLoader(true);
        //   }
        // })
    }

    // return search suggestion array
    const listScrips = () => {
        // console.log('search list render', searchList)

        // search box have value then return matching suggestions
        // if (searchList && searchList.length) {
        for (let index = 0; index < props.scrip.length; index++) {
            const element = props.scrip[index];
            tempSearchArray.push(
                <div className={
                    index === cursor ?
                        `row mb-0 pt-3 ${styles.e_selected_item} e-v2r-selected-item `
                        :
                        `${styles.e_item} row mb-0 pt-3 e-v2r-item `
                }
                    key={index} id={"c-dropdown-palate" + index}
                    onMouseEnter={() => {
                        setOnVisit(index);
                    }}
                    onMouseLeave={() => {
                        setOnVisit(null)
                    }}
                    onClick={() => {
                        // if (!element.is_in_watchlist) {
                        //     props.result(element.scrip_id, index); setValue(''); setCursor(0); setHeight(0); setPointer(0)

                        // } else {
                        //     props.fail();
                        // }

                    }}>
                    <div className='col-xl-4 col-lg-5 col-md-4 px-0'>
                        {/* <h6 className="e-secondary-color">{element.name}</h6> */}
                        <p className={`${styles.e_company_symbol} e-secondary-color`}
                            style={{ color: index === cursor ? 'fff' : null }}>
                            {element.symbol}
                        </p>
                    </div>
                    <div className='col-xl-8 col-lg-7 col-md-8 text-end px-0'>
                        {/* {
                            !element.is_in_watchlist ?

                                <SecondaryBtn loading={props.loader && props.current === index ? true : false} label="+ Add" id={`e-btn-${index + 1}`} type={1} />
                                :
                                null
                        } */}
                        {
                            onVisit === index ?

                                add === index ?
                                    <span className={`${styles.e_add} e-action`}
                                        onClick={() => {
                                            setOnVisit(null);
                                            setAdd(null);
                                            setClose(false);
                                            $('#v2r-search').blur();
                                            props.result(element);
                                            setValue('');
                                            setCursor(0);
                                            setHeight(0);
                                            setPointer(0)
                                        }}>Add</span>
                                    :
                                    element.is_in_watchlist ?
                                        <span className={`${styles.e_add} e-action`}
                                        >Added</span>
                                        :
                                        <img src={Add} alt=""
                                            draggable={false}
                                            onMouseEnter={() => {
                                                setAdd(index)
                                            }}
                                            onMouseLeave={() => {
                                                setAdd(index)
                                            }}
                                        />


                                :
                                <p className={`${styles.e_company} e-secondary-color`}
                                    style={{ color: index === cursor ? 'fff' : null }}>
                                    {element.name}
                                </p>
                        }

                    </div>
                </div >
            );
        }
        // }
        // search box have no values then return all the  available options
        // else {
        //   return (
        //     <div className='row mb-2' >
        //       <div className='col-xl-12 col-lg-7 col-md-8'>
        //         <EmptyScreen title="No scrip found" note="Try with another name" />
        //       </div>
        //     </div>

        //   )
        // }
        return tempSearchArray;
    }



    const handleKeyDown = (e) => {
        // var el = $("#c-dropdown-palate" + cursor).scrollTop(10);
        var y = $('#e-dropdown').scrollTop();
        console.log('y', y);
        var tmpCursor = pointer;
        var offset = height;
        var tmC = cursor
        if (e.keyCode === 38) { //up
            tmC = cursor - 1;
            tmpCursor = tmpCursor - 1;
            offset = offset - 60;
        } else if (e.keyCode === 40) { //down
            tmC = cursor + 1;
            tmpCursor = tmpCursor + 1;
            offset = offset + 60;
        } else if (e.keyCode === 13) {
            $(`#c-dropdown-palate${tmC}`).trigger('click');

        }
        setCursor(tmC);
        if (tmpCursor === 3) {
            let newHeight = offset + y;
            $('#e-dropdown').scrollTop(newHeight);
            tmpCursor = 0;
        }
        setHeight(offset);
        setPointer(tmpCursor);
    }

    useEffect(() => {

        if (close) {
            $('#v2r-search').focus();
        } else {
            $('#v2r-search').blur();
        }
    }, [close])


    return (
        <div className={`${styles.e_input_wrap} position-relative`} ref={wrapRef}>
            {
                props.scrip?.length === 0 ?


                    <input id={props.id}
                        value={value}
                        type={props.type}
                        //eslint-disable-next-line
                        className={`${styles.e_search_box}` + " " + props.className}
                        onChange={(e) => { valueChange(e) }}
                        placeholder={props.placeholder}
                        onFocus={() => {
                            setSuggestion(true);

                            setClose(true);
                            props.onFocus()
                        }}
                        onKeyDown={e => handleKeyDown(e)}
                        onBlur={() => { setCursor(0); }}
                    />
                    :
                    !close ?

                        <input id={props.id}
                            value={value}
                            type={props.type}
                            //eslint-disable-next-line
                            className={`${styles.e_search_box}` + " " + props.className}
                            onChange={(e) => { valueChange(e) }}
                            placeholder={props.placeholder}
                            onFocus={() => {
                                setSuggestion(true);
                                setClose(true)
                            }}
                            onKeyDown={e => handleKeyDown(e)}
                            onBlur={() => { setCursor(0); }}
                        />
                        :
                        null
            }

            <>
                {
                    value && value?.length > 0 ?
                        <button
                            className={`${styles.e_search_close} pe-3`}
                            onClick={() => {
                                setValue('');
                                setClose(false)
                            }}>
                        </button>
                        :
                        null
                }
                {
                    close ?
                        props.scrip?.length > 0 ?
                            <div className={`${styles.e_v2r_search_wrapper} e-search-wraper`} id="e-dropdown">
                                <input id={"v2r-search"}
                                    value={value}
                                    type={props.type}
                                    //eslint-disable-next-line
                                    className={`${styles.e_v2r_search_box}` + " e-v2r-scrip-search " + props.className}
                                    onChange={(e) => { valueChange(e) }}
                                    placeholder={"INFY"}
                                    onFocus={() => {
                                        setSuggestion(true);
                                        setClose(true)
                                        // props.onFocus()
                                    }}
                                    onKeyDown={e => handleKeyDown(e)}
                                    onBlur={() => { setCursor(0); }}

                                />
                                {
                                    listScrips()
                                }
                            </div>
                            :
                            value?.length > 0 ?
                                <div className={`${styles.e_search_wrapper} e-search-wraper`} id="e-dropdown">
                                    <EmptyScreen
                                        title="Nothing found"
                                        note="    "
                                        className="py-5"
                                    />
                                </div>
                                :
                                null


                        :
                        null
                }
            </>
        </div>
    );
}
export default V2rSearchBox;


