/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect } from 'react';

/*import componets*/

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/*import assets */
import Success from '../../assets/images/scrip/success.svg';

export default function Scripsuccess(props) {


    useEffect(() => {
        let modal = document.getElementById('scripSuccess')
        modal.addEventListener('hidden.bs.modal', function (event) {
            props.modalClose();
        })
        //eslint-disable-next-line
    }, [])

    return (
        <div className={`${styles.e_edit_modal} modal fade`}
            id="scripSuccess"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <h4>Success</h4>

                        <button type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close" >
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className='col-lg-12 pt-3'>
                                <img src={Success}
                                    className={`${styles.e_img_low_balance}`}
                                    alt="no data"
                                    style={{ height: "300px", objectFit: "contain" }}
                                    draggable={false}
                                />
                                <p className={`${styles.e_low_balance_text}`}>


                                    <div>Your votes have been submitted to admin successfully.
                                        <p className='mb-0'>You can vote in the next voting session.</p>
                                    </div>

                                    {/* {`Complete KYC for seamless withdrawal`} */}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

