/*
 *   File : secondary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Secondary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import style*/
import styles from '../../styles/components/secondary.module.scss';

// Asset import 
import LoaderGif from '../../assets/images/loader/btn_loader_1.gif';
const SecondaryBtn = (props) => {

    return (
        props.loading ?
            <div className={`${styles.e_loader} text-center ms-4`}>
                <img
                    src={LoaderGif}
                    className={`${styles.e_loader_img}`}
                    alt=""
                    draggable={false}
                />
            </div>
            :
            <button
                id={props.id}
                active={
                    props.active === false ?
                        `${styles.e_s_disabled_btn}`
                        :
                        props.type
                }
                type={
                    props.type ?
                        props.type
                        :
                        "submit"
                }
                onClick={props.handleClick}
                className={
                    props.type === 1 ?
                        `${styles.e_s_btn} ${styles.e_s_p_btn} ${props.className} e-secondary-btn`
                        :
                        `${styles.e_s_btn} ${styles.e_s_w_btn} ${props.className} e-secondary-btn`
                }
                icon={props.icon}
                data-bs-dismiss={props.cancel}
                data-bs-toggle={props.toggle}
                data-bs-target={props.target}
                style={props.style}
            >
                {props.label}
            </button>
    );
}

export default SecondaryBtn;