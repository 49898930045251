/*
 *   File : bankAccount.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Bank Account Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 01-03-2022
 */

/*import packages*/
import React, { useContext, useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../formElements/index';
import { PrimaryBtn, SecondaryBtn, } from '../cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/kyc.module.scss';

/*import assets */
import services from '../../services/service';
import { FireBaseContext } from '../../context/firebaseContext';
import { logEvent } from 'firebase/analytics';



export default function BankModal(props) {

	const [name, setName] = useState("");
	const [accNum, setAccNum] = useState("");
	const [branch, setBranch] = useState("");
	const [ifsc, setIfsc] = useState("");
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
	const [holderName, setHolderName] = useState("");
	const [id, setId] = useState(null);
	const analytics = useContext(FireBaseContext)

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000)
		}
	}, [error])

	useEffect(() => {
		if (props.operation === 1) {
			setId(null);
			setHolderName('');
			setName('');
			setIfsc('');
			setBranch('');
			setAccNum('');
		} else {
			setId(props.data?.id);
			setHolderName(props.data?.account_holder);
			setName(props.data?.bank_name);
			setIfsc(props.data?.ifsc_code);
			setBranch(props.data?.branch);
			setAccNum(props.data?.account_number);
		}
	}, [props.operation, props.data])

	const _addAccount = async () => {
		setLoader(true);
		let url = 'customer/profile/add-bank-account';

		let data = {
			bank_detail_id: id,
			bank_name: name,
			account_number: accNum,
			ifsc: ifsc,
			branch: branch,
			holder_name: holderName
		}

		services(true, url, data).then((response) => {

			if (response.status_code === 200) {
				// message.success("Bank details added successfully");
				logEvent(analytics[0], "add_payment_info", {
					'bank_detail_id': id,
					'bank_name': name,
					'account_number': accNum,
					'ifsc': ifsc,
					'branch': branch,
					'holder_name': holderName,
				})
				$('#bank').trigger('click');
				props.success();
				setAccNum("");
				setBranch("");
				setIfsc("");
				setName("");
				setHolderName("");
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
			}
		});

	}
	return (
		<div className={`${styles.e_kyc_modal} e-kyc-modal modal fade`}
			id="bank"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true"
		>
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className="container">
						<div className="row">
							<div className={`${styles.e_modal_header} modal-header mb-2 `}>
								<h4 className='ms-2 ps-1'>{props.operation === 1 ? 'Add ' : 'Edit '}
									bank
								</h4>
								<button
									type="button"
									className={
										localStorage.getItem("mode") === "true" ?
											"btn-close"
											:
											"btn-close-dark"
									}
									data-bs-dismiss="modal"
									aria-label="Close">
								</button>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row">
							<div className='col-lg-6 e-select-gender'>
								<label className='mb-2 e-secondary-color'>
									Account holder name
								</label>
								<Input
									placeholder="Account holder name"
									type="text"
									value={holderName}
									valueChange={(value) => {
										setHolderName(value)
									}}
									className={`${styles.e_input_bg}`}
								/>
							</div>
							<div className='col-lg-6 e-select-gender'>
								<label className='mb-2 e-secondary-color'>
									Bank name
								</label>
								<Input
									placeholder="Bank name"
									type="text"
									value={name}
									valueChange={(value) => {
										setName(value)
									}}
									className={`${styles.e_input_bg}`}
								/>
							</div>
							<div className='col-lg-6 pt-3 mt-2'>
								<label className='mb-2 e-secondary-color'>
									A/C number
								</label>
								<Input
									placeholder="A/C number"
									type="text"
									value={accNum}
									valueChange={(value) => {
										setAccNum(value)
									}}
									className={`${styles.e_input_bg}`}
								/>
							</div>
							<div className='col-lg-6 pt-3 mt-2'>
								<label className='mb-2 e-secondary-color'>
									Branch
								</label>
								<Input
									placeholder="Branch name"
									type="text"
									value={branch}
									valueChange={(value) => {
										setBranch(value)
									}}
									className={`${styles.e_input_bg}`}
								/>
							</div>
							<div className='col-lg-6 pt-3 mt-2'>
								<label className='mb-2 e-secondary-color'>
									IFSC
								</label>
								<Input
									isCapital={true}
									placeholder="IFSC"
									type="text"
									value={ifsc}
									valueChange={(value) => {
										setIfsc(value)
									}}
									className={`${styles.e_input_bg}`}
								/>
							</div>
						</div>
						<div className='row mt-5 pt-3'>
							<div className='col-lg-12'>
								<div className="d-flex justify-content-end">
									<SecondaryBtn
										label="Cancel"
										type={2}
										className="me-3 e-secondary-color"
										cancel="modal"
									/>
									{
										name && branch &&
											ifsc && accNum ?
											<PrimaryBtn
												label="Save details"
												type={2}
												className={loader ? "e-btn-loader ms-1" : "ms-1"}
												handleClick={() => _addAccount()}
											/>
											:
											<PrimaryBtn
												label="Save details"
												type={2}
												className="ms-1 e-disabled"

											/>
									}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
					/>
					:
					null
			}
		</div>
	)
}

