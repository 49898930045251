
/*
 *   File : v2rTab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 02-02-2023
*/

/*import package*/
import { useState, useEffect } from 'react';

/*import styles*/
import styles from '../../styles/components/v2r.module.scss';

const V2rTab = (props) => {
    const [index, setIndex] = useState(props.index);

    useEffect(() => {
        setIndex(props.index)
    }, [props.index])

    return (
        <div className="e-watchlist-tabs">
            {
                props.watch_list ?
                    props.data.filter((item) => (item.active !== false)).map((item, key) => {
                        return (
                            <span
                                className={
                                    index === item.watchlist_id ?
                                        `${styles.e_active_tab} ${props.className} me-2  e-v2r-active-tab`
                                        :
                                        `${styles.e_tab} ${props.className} ${props.inactiveClass} me-2 e-link-colors e-v2r-tab`}
                                onClick={() => {
                                    setIndex(item.watchlist_id);
                                    props.setTab(item.watchlist_id, key)
                                }}
                                key={key}
                            >
                                {item.name}
                            </span>
                        )
                    })
                    :
                    props.data.map((item, key) => {
                        return (
                            <span
                                className={
                                    index === (key + 1) ?
                                        `${styles.e_active_tab} ${props.className} me-2 e-secondary-color e-v2r-active-tab`
                                        :
                                        `${styles.e_tab} ${props.className} ${props.inactiveClass} me-2 e-link-colors e-v2r-tab`}
                                onClick={() => {
                                    setIndex((key + 1));
                                    props.setTab((key + 1))
                                }}
                                key={key}
                            >
                                {item}
                            </span>
                        )
                    })
            }
        </div>
    )
}

export default V2rTab;

