/*
 *   File : editScrips.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Edit Scrip Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 11-03-2022
 */

/*import packages*/
import React, { useRef, useEffect, useState } from 'react';
import $ from 'jquery';


/*import componets*/
import { PrimaryBtn } from '../../components/cta/index';
import { ProgressBar } from '../formElements';
import services from '../../services/service';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/scripmodal.module.scss';

/*import assets */
import Minus from '../../assets/images/dataFormatting/minus.svg';
import Plus from '../../assets/images/dataFormatting/plus.svg';
import DropdownIcon from '../../assets/images/header/header_down.png'
import ScripRemove from '../../assets/images/modal/scrip-delete.png';
import MinusDark from '../../assets/images/dataFormatting/minus-dark.svg';
import PlusDark from '../../assets/images/dataFormatting/plus-dark.svg';
import ScripRemoveDark from '../../assets/images/modal/scrip-delete-dark.png';
import Slot from './slot';
import EmptyScreen from '../emptyScreen/empty';


let GAINER_COUNT = 1;
let LOSER_COUNT = 1;

const EditScrip = React.memo(function EditScrip(props) {
	const slotRef = useRef(null);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
	//eslint-disable-next-line
	const [update, setUpdate] = useState(false);
	//eslint-disable-next-line
	const [dualBooking, setDualBooking] = useState();
	const [editableData, setEditableData] = useState([]);
	let [booking_data, set_booking_data] = useState();
	const [inputIndex, setInputIndex] = useState();
	const [slotId, setSlotId] = useState([]);
	const [slotName, setSlotName] = useState(null);
	//eslint-disable-next-line
	const [slotNames, setSlotNames] = useState(null);
	const [slotCheckDropdown, setSlotCheckDropdown] = useState(true);

	const TOTAL = [];
	const _closeMenuSlot = (event) => {
		if (slotRef.current && !slotRef.current.contains(event.target)) {
			setSlotCheckDropdown(true)
		}
	}
	useEffect(() => {
		document.addEventListener('mouseup', _closeMenuSlot);
		return () => {
			document.removeEventListener('mouseup', _closeMenuSlot);
		};
	}, []);


	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (props.bookings) {
			set_booking_data(JSON.stringify(props.bookings));
		}
	}, [props?.bookings]);


	useEffect(() => {
		if (props.allow_dual_booking !== null) {
			setDualBooking(props.allow_dual_booking);
		}
	}, [props.allow_dual_booking]);

	useEffect(() => {
		var myModalEl = document.getElementById('editScrip')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			props.close();
		})
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setSlotName(props.name);
	}, [props.name])

	useEffect(() => {
		setSlotNames(props.names);
	}, [props.names])

	useEffect(() => {
		if (props.type === 2) {
			setSlotId(props.id[0]);
		}
		//eslint-disable-next-line
	}, [props.id])

	useEffect(() => {
		const myModalEl = document.getElementById('editScrip');
		myModalEl.addEventListener('shown.bs.modal', (event) => {
			if (props.type === 2) {
				if (props.bookings) {
					const arr = [];
					for (const element of props.bookings) {
						element.performer_unit = props.bookingObj[element.scrip_code]?.performer_unit;
						element.under_performer_unit = props.bookingObj[element.scrip_code]?.under_performer_unit;
						arr.push(element);
					}
					setEditableData(arr);
				}
			} else if (booking_data) {

				const arr = [];
				for (const element of JSON.parse(booking_data)) {
					// console.log("Ee", element)
					element.performer_unit = props.bookingObj[element.scrip_code]?.performer_unit;
					element.under_performer_unit = props.bookingObj[element.scrip_code]?.under_performer_unit;
					arr.push(element);

				}

				setEditableData(arr);
			}
		})
		//eslint-disable-next-line
	}, [booking_data, props.bookingObj])

	const _getBookingsBySlot = async (id) => {
		const url = 'customer/m2m-bookings-by-slot';
		const data = {
			"slot_id": id,
		};
		services(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false)
				let arr = [];
				for (let index = 0; index < Object.values(response.data.bookings).length; index++) {
					const element = Object.values(response.data.bookings)[index];

					element.performer_unit = response.data.bookings[element.scrip_code]?.performer_unit;
					element.under_performer_unit = response.data.bookings[element.scrip_code]?.under_performer_unit;
					arr.push(element);
				}
				setEditableData(arr);
				console.log("arr", arr)
			}
		});

	}

	/* ---- Edit scrip ----*/
	const _EditScrip = async () => {

		setLoader(true);
		if (props.type === 1) {
			//eslint-disable-next-line
			var url = 'customer/game/m2e-book';
			//eslint-disable-next-line
			var data = {
				"booking_data": editableData
			}
		} else {
			//eslint-disable-next-line
			var url = 'customer/m2m-book';
			//eslint-disable-next-line
			var data = {
				"slot_id": [slotId],
				"booking": editableData
			}
		}

		services(true, url, data).then((response) => {
			if (response.status_code === 200) {
				props.update(editableData, response.data);
				$('#editScrip').trigger('click');
				setLoader(false)
			} else {
				setLoader(false);
				setError(response.message)
			}
		});
	}

	// const _DeleteRow = (id, i) => {
	// 	let deleteTempArr = booking_data
	// 	let index = deleteTempArr.findIndex((data) => data.scrip_id === id)
	// 	deleteTempArr.splice(index, 1);
	// 	set_booking_data(deleteTempArr);
	// }
	const _findTotal = (temp) => {
		var SUM;

		if (TOTAL && TOTAL.length) {
			var lodash = require('lodash');
			SUM = lodash.sum(TOTAL);
		}

		if (props.type === 2) {
			var finalSum = parseInt(SUM);
			return finalSum.toLocaleString('en-IN');
		} else {
			if (SUM) {
				SUM = SUM.toLocaleString('en-IN')
			}
			return SUM
		}

		// setTotal(sum);
	}


	return (
		<div className={`${styles.e_scrip_modal} e-kyc-modal modal fade e-game-detail-wrapper`}
			id="editScrip"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} modal-header mb-2 d-flex`}>
						<h4 className='ps-0 mt-2'>Preview & edit</h4>
						{
							props.type === 2 ?
								<>
									<div className='position-relative ps-2' style={{ color: "white" }}>
										<span className={styles.e_slot_wrapper}
											onClick={() => setSlotCheckDropdown(false)}>
											Slot: {slotName ? slotName : "Select"}
											<img src={DropdownIcon}
												width="10px"
												className="ms-2"
												alt=""
												draggable={false}
											/>
											{/* <div ref={slotRef}> */}

										</span>
										{
											!slotCheckDropdown ?
												<div ref={slotRef} className="mt-2">
													<Slot
														data={props.data}
														id={slotId}
														from="summary"
														name={slotName}

														Selected={(id, name, gameId) => {

															setSlotId(id);
															setSlotName(name);

															setSlotCheckDropdown(true);
															_getBookingsBySlot(id)
														}} />
												</div>
												:
												null
										}
										{/* </div> */}

									</div>

								</>
								:
								null
						}
						<button
							type="button"
							className={
								localStorage.getItem("mode") === "true" ?
									"btn-close"
									:
									"btn-close-dark"
							}
							data-bs-dismiss="modal"
							aria-label="Close">
						</button>
					</div>
					{

						update === false ?

							<>{
								editableData.some((item) => item.performer_unit > 0 || item.under_performer_unit > 0) ?
									<div className={` px- 2 ${styles.e_modal_body}`}>
										{
											editableData?.map((item, idx) => {

												if (item.performer_unit === 0 && idx !== inputIndex) {
													return false
												}
												TOTAL.push(item.performer_unit * props.unitPrice);
												$(`#edit-pr-input-${item.scrip_id}`).val(item.performer_unit);
												GAINER_COUNT = GAINER_COUNT + 1;
												return (
													<>
														{
															GAINER_COUNT <= 2 ?
																<div className="row pt-4">
																	<div className='col-lg-5 col-md-5 col-sm-4 col-4'>
																		<label className='mb-2 e-secondary-color' style={{ color: '#fff', fontWeight: '600' }}>
																			Symbol
																		</label>
																	</div>
																	<div className='col-lg-3 col-md-3 col-sm4  col-4 text-start'>
																		<label className='mb-2 e-secondary-color'>
																			Gainer qty.
																		</label>
																	</div>
																	<div className='col-lg-2 col-md-2 col-sm-2 col-2 text-end '>
																		<label className='mb-2 e-secondary-color'>
																			Amount
																		</label>
																	</div>
																	<div className='col-lg-1 col-1'></div>
																</div>
																:
																null
														}
														<div className={`row ${styles.e_row}`}>
															<div className='col-lg-5 col-md-5 col-sm-4 col-4 mt-4'>
																<h6 className='e-edit-modal-symbol'>{item.scrip_code}</h6>
															</div>
															<div className='col-lg-3 col-md-3 col-sm-4  col-4 '>
																<span className='e-span e-edit-scrip-btn-input d-flex justify-content-between mt-3'>
																	<img src={localStorage.getItem("mode") === "true" ? Minus : MinusDark}
																		draggable={false}
																		alt=""
																		className={item.performer_unit < 1 ? 'e-minus e-disabled-span-input' : 'e-minus '}
																		onClick={() => {
																			let value = Number(item.performer_unit) - 1;
																			if (value < 1) {
																				value = 0;
																			}
																			let tempArr = editableData;
																			$(`#edit-pr-input-${item.scrip_id}`).val(value);
																			tempArr[idx]['performer_unit'] = value;
																			setEditableData(tempArr)
																		}} />
																	<input className="e_table_input"
																		onInput={(e) => { e.target.value = Math.abs(e.target.value) }}
																		min="0"
																		value={item.performer_unit}
																		id={`edit-pr-input-${item.scrip_id}`}
																		type='number'
																		placeholder="-"
																		onBlur={() => setInputIndex(null)}
																		onKeyDown={(e) => {
																			if (e.keyCode === 38) {
																				let tempArr = editableData;
																				tempArr[idx]['performer_unit'] = item.performer_unit++;
																				setEditableData(tempArr);
																			}
																			else if (e.keyCode === 40) {
																				if (e.target.value > 0) {
																					let tempArr = editableData;
																					tempArr[idx]['performer_unit'] = item.performer_unit--;
																					setEditableData(tempArr);
																				}
																			}
																		}}
																		onChange={(e) => {
																			let tempArr = editableData;
																			setInputIndex(idx);
																			tempArr[idx]['performer_unit'] = e.target.value;
																			setEditableData(tempArr);
																		}}
																	/>
																	<img src={localStorage.getItem("mode") === "true" ? Plus : PlusDark} className='e-plus'
																		alt=""
																		draggable={false}
																		onClick={() => {
																			let tempArr = editableData;
																			let value = Number(item.performer_unit) + 1;
																			$(`#edit-pr-input-${item.scrip_id}`).val(value);
																			tempArr[idx]['performer_unit'] = value;
																			setInputIndex(idx);
																		}}
																	/>
																</span>
															</div>
															<div className='col-lg-2 col-md-2 col-sm-2 col-2 ps-0 text-end mt-4'>
																<h6 className='e-edit-modal-symbol'>
																	₹  {item.performer_unit * props.unitPrice}
																</h6>
															</div>
															<div className='col-lg-1 col-1 mt-3 pt-1'>
																<img src={
																	localStorage.getItem("mode") === "true" ?
																		ScripRemove
																		:
																		ScripRemoveDark
																}
																	width="20px"
																	className="e-remove-scrip-btn"
																	alt=""
																	draggable={false}
																	onClick={() => {
																		let deleteTempArr = editableData;
																		deleteTempArr[idx]['performer_unit'] = 0;
																		setEditableData(deleteTempArr);

																	}}
																/>
															</div>
														</div>
													</>

												)

											})

										}
										<>
											{
												GAINER_COUNT > 1 ?
													<div className='col-lg-12 border-bottom pe-3'></div>
													:
													null
											}
										</>


										{
											editableData?.map((item, idx) => {
												// let editArray = editableData;
												// editArray.push(item);
												if (item.under_performer_unit === 0) {
													return false
												}
												TOTAL.push(item.under_performer_unit * props.unitPrice);
												$(`#edit-undrpr-input-${item.scrip_id}`).val(item.under_performer_unit);
												LOSER_COUNT = LOSER_COUNT + 1;
												return (
													<>
														{
															LOSER_COUNT <= 2 ?
																<div className="row pt-4">
																	<div className='col-lg-5 col-md-5 col-sm-4 col-4'>
																		<label className='mb-2' style={{ color: '#fff', fontWeight: '600' }}>
																			Symbol
																		</label>

																	</div>
																	<div className='col-lg-3 col-md-3 col-sm-4  col-4  text-start'>
																		<label className='mb-2'>
																			Loser qty.
																		</label>

																	</div>
																	<div className='col-lg-2 col-md-2 col-sm-2 col-2  text-end '>
																		<label className='mb-2'>
																			Amount
																		</label>

																	</div>
																	<div className='col-lg-1 col-1'>

																	</div>
																</div>
																:
																null
														}

														<div className={`row ${styles.e_row}`}>
															<div className='col-lg-5 col-md-5 col-sm-4 col-4 mt-4'>

																<h6 className='e-edit-modal-symbol'>
																	{item.scrip_code}
																</h6>
															</div>
															<div className='col-lg-3 col-md-3 col-sm-4  col-4  '>
																<span className='e-span e-edit-scrip-btn-input d-flex justify-content-between mt-3'>
																	<img src={
																		localStorage.getItem("mode") === "true" ?
																			Minus
																			:
																			MinusDark
																	}
																		alt=""
																		draggable={false}
																		className={
																			item.under_performer_unit < 1 ?
																				'e-minus e-disabled-span-input'
																				:
																				'e-minus '
																		}
																		onClick={() => {
																			let value = Number(item.under_performer_unit) - 1;
																			if (value < 1) {
																				value = 0;
																			}
																			let tempArr = editableData;
																			$(`#edit-undrpr-input-${item.scrip_id}`).val(value);
																			tempArr[idx]['under_performer_unit'] = parseInt(value);
																			setEditableData(tempArr)
																		}} />
																	<input className="e_table_input"
																		onInput={(e) => { e.target.value = Math.abs(e.target.value) }}
																		min="0"
																		id={`edit-undrpr-input-${item.scrip_id}`}
																		type='number'
																		placeholder="-"
																		onKeyDown={(e) => {
																			if (e.keyCode === 38) {
																				let tempArr = editableData;
																				tempArr[idx]['under_performer_unit'] = item.under_performer_unit++;
																				setEditableData(tempArr)
																			}
																			else if (e.keyCode === 40) {
																				if (e.target.value > 0) {
																					let tempArr = editableData;
																					tempArr[idx]['under_performer_unit'] = item.under_performer_unit--;
																					setEditableData(tempArr)
																				}
																			}
																		}}

																		onChange={(e) => {
																			let tempArr = editableData;
																			tempArr[idx]['under_performer_unit'] = parseInt(e.target.value);
																			setEditableData(tempArr);

																		}} />
																	<img src={
																		localStorage.getItem("mode") === "true" ?
																			Plus
																			:
																			PlusDark
																	}
																		className='e-plus'
																		// id={`under-per-plus-${row.scrip_id}`}
																		alt=""
																		draggable={false}
																		onClick={() => {
																			let tempArr = editableData;
																			let value = Number(item.under_performer_unit) + 1;
																			tempArr[idx]['under_performer_unit'] = parseInt(value);
																			$(`#edit-undrpr-input-${item.scrip_id}`).val(value);
																			setEditableData(tempArr)
																		}} />
																</span>
															</div>
															<div className='col-lg-2 col-md-2 col-sm-2 col-2 ps-0 text-end mt-4'>

																<h6 className='e-edit-modal-symbol'>
																	₹  {item.under_performer_unit * props.unitPrice}
																</h6>
															</div>
															<div className='col-lg-1 col-1 mt-3 pt-1'>
																<img src={
																	localStorage.getItem("mode") === "true" ?
																		ScripRemove
																		:
																		ScripRemoveDark
																} width="20px" alt="" className="e-remove-scrip-btn"
																	draggable={false}
																	onClick={() => {
																		let deleteTempArr = editableData;
																		deleteTempArr[idx]['under_performer_unit'] = 0;
																		setEditableData(deleteTempArr);
																	}} />
															</div>
														</div>
													</>
												)
											})
										}

									</div>
									:
									<EmptyScreen note="No bookings found" />
							}
								<div className='row pt-4 mt-2'>
									<div className='col-lg-12'>
										<div className="d-flex justify-content-between">
											{
												editableData.some((item) => item.performer_unit > 0 || item.under_performer_unit > 0) ?
													<div className='d-flex pt-3'>
														<label className=''>
															Total
														</label>
														<h6 className='mt-0 ps-1 e-secondary-color'>
															₹ {_findTotal()}
														</h6>
													</div>
													:
													<div className='d-flex pt-3'>
														<label className=''>
															Update to remove all bookings
														</label>

													</div>
											}
											<PrimaryBtn
												label="Update"
												type={2}
												// handleClick={() => { setTimeout(() => { setUpdate(true) }, 2000) }}
												className={loader ? "e-btn-loader ms-1" : "ms-1"}
												handleClick={() => _EditScrip()}
											/>

										</div>
									</div>
								</div>
							</>

							:
							<>
								<div className={`${styles.e_modal_header} modal-header mb-2`}>
									<h4 className='ps-0'>Confirmation</h4>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="modal"
										aria-label="Close">
									</button>
								</div>


								<div className='row'>
									<div className="offset-lg-4 col-lg-4 ">
										<div className={`${styles.e_progress}`}>

											<ProgressBar
												percent={50}
												id={1}
											/>


										</div>


									</div>
									<div className='col-lg-12 text-center'>
										<p>1 / 2 bookings completed</p>
									</div>
								</div>

							</>

					}


				</div>
			</div>
			{
				error !== null ?
					<Toast data={error} />
					:
					null
			}
		</div>
	)
})
export default EditScrip

