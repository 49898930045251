/*
 *   File : input.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import components */
import { Toast } from '../../components/note/index';

/*import style*/
import styles from '../../styles/components/input.module.scss';

const Input = (props) => {

	const [value, setValue] = useState();
	const [error, setError] = useState();


	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue);
		}
	}, [props.defaultValue])

	const validate = () => {
		if (props.type === "email" && value && value.length) {
			//eslint-disable-next-line
			if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
				setError("Invalid email")
			}
		}
		else if (props.type === "number" && (props.placeholder === "Phone Number" || props.placeholder === "Phone number" || props.placeholder === "Mobile Number")) {
			//eslint-disable-next-line
			if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)) {
				setError("Invalid phone")
			}
		}
		if (props.validateLength) {
			if (props.minLength && (value.length < props.minLength)) {
				setError(`Minimum ${props.minLength} charcter${props.minLength > 1 ? "s" : ""} required`);
				props.valueChange(null);
			}
			else if (value.length > props.maxLength) {
				setError(`Maximum charcter limit(${props.maxLength}) exceeded.`);
				props.valueChange(null);
			}
		}
		if (props.onlyNumber) {
			if (!/^\d+$/.test(value)) {
				setError("Enter a valid number");
				props.valueChange(null);
			}
		}
		setTimeout(() => { setError(null) }, 4000)
	}

	const valueChange = (e) => {
		if (props.type === 'email') {
			setValue(e.target.value.toLowerCase());
			setError(null);
			props.valueChange(e.target.value.toLowerCase());
		} else if (props.isCapital) {
			setValue(e.target.value.toUpperCase());
			setError(null);
			props.valueChange(e.target.value.toUpperCase());
		} else {
			// setValue(e.target.value);
			setError(null);
			props.valueChange(e.target.value);
		}
		// console.log(value);
	}

	var codes = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Delete", "Enter", "Tab"]

	var invalidChars = [
		"-",
		"+",
		"e",
	];

	const keyPress = (e) => {

		if (e.keyCode === 13 && props.searchBox) {
			props.onSearch(e.target.value);
		} if (props.mpin) {
			if (!codes.includes(e.key)) {
				e.preventDefault();
			}
			props.valueChange(e.target.value);
			// return true;
		} if (props.type === "number") {
			if (props.is_number !== true) {
				if (invalidChars.includes(e.key)) {
					e.preventDefault()
				}
			}

		}

	}

	return (
		//eslint-disable-next-line
		<div className={styles.e_input_wrap + ' ' + 'position-relative e-bg-white'}>
			<input id={props.id}
				value={props.value}
				ref={props.ref}
				name={props.name}
				// defaultValue={props.defaultValue}
				type={props.type}
				min={props.min}
				//eslint-disable-next-line
				className={error ? `${styles.e_input_error} ${styles.e_input_box} e-input-box` + " " + props.className : `${styles.e_input_box} e-input-box` + " " + props.className}
				onChange={(e) => { valueChange(e) }}
				placeholder={props.placeholder}
				required={props.required}
				onBlur={() => {
					validate();
					if (props.blurEvent) {
						props.onBlur();
					}
				}}
				onFocus={() => setValue(props.value)}
				prefix={props.prefix}
				onKeyDown={e => keyPress(e)}
				onWheel={() => document.activeElement.blur()}
				disabled={props.readOnly}
				autoComplete={props.autoComplete}
			/>
			{
				props.suffix &&
				<span className={`${styles.e_suffix} e-secondary-color e-suffix`}>
					{props.suffix}
				</span>
			}
			{props.label ?
				<label htmlFor={props.id} className="label">
					{props.label}
				</label>
				:
				null
			}
			{
				error ?
					// <p className={styles.e_error_msg+" pt-2 mb-0"}>{error}</p>
					<Toast
						data={error}
					/>
					:
					null
			}
			<span></span>
		</div>
	);
}
export default Input;

