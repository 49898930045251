/*
 *   File : textLink.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Link Text button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*Style Import*/
import styles from '../../styles/components/textLink.module.scss';

const LinkText = (props) => {
	return (
		<button
			id={props.id}
			disabled={props.disabled}
			type={props.type}
			className={
				props.type === 1 ?
					`${styles.e_linktxt_btn} 
          ${props.className}`
					:
					props.type === 2 ?
						`${styles.e_linktxt_btn} 
            ${styles.e_linktxt_l_btn} 
            ${props.className}`
						:
						`${styles.e_linktxt_btn} 
            ${styles.e_linktxt_r_btn} 
            ${props.className}`
			}
			style={props.style}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
		>
			{
				props.left ?
					<img src={props.image}
						width={
							props.width ?
								props.width
								:
								null
						}
						className={`${styles.e_icon_l_link}`}
						alt=""
					/>
					:
					null
			}

			{props.label}

			{
				props.right ?
					<img src={props.image}
						className={`${styles.e_icon_r_link}`}
						alt=""
						draggable={false}
					/>
					:
					null
			}

		</button>
	);
}


export default LinkText;
