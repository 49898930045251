/*
 *   File : loader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Loader
 *   Integrations :
 *   Version : v1.0
 *   Created : 10-11-2021
 */

/*Import Packages*/
import React from 'react';

/*import style*/
import styles from '../../styles/components/loader.module.scss';
import LoaderImg from '../../assets/images/loader/overlay.gif';


export default function Loader(props) {
  return (
    <>
      <div className={`${styles.e_loader} e-loader`}
        style={{ height: props.height ? props.height : '30vh' }}>
        <img src={LoaderImg}
          alt=""
          draggable={false}
        />
      </div>
    </>
  )
}
