/*
 *   File : exit.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : exit game Modal
 *   Integrations : 17-08-2022
 *   Version : v1.0
 *   Created : 17-08-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import services from '../../services/service';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';
import { Toast } from '../note';



export default function Exit(props) {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    // useState(() => {
    //     setLoader(props.loader)
    // }, [props.loader])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    /* ----- exit game api --*/

    const _exitGame = () => {
        setLoader(true);
        let url = "customer/game/skillrace/exit-game";

        services(false, url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                $("#exitConfirm").trigger("click")
                props.modalClose()
            } else {
                setLoader(false);
                setError(response.message);
            }
        });

    }

    return (
        <>
            <div className={`${styles.e_edit_modal} modal fade`} id="exitConfirm" tabIndex="-1" aria-labelledby="kyc" aria-hidden="true" style={{ zIndex: 99999 }}>
                <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                    <div className={`${styles.e_modal_content} modal-content`}>
                        <div className={`${styles.e_modal_header} modal-header mb-2`}>
                            <h4 className="e-secondary-color">
                                {
                                    props.title ?
                                        props.title
                                        :
                                        "Exit game"
                                }
                            </h4>

                            <button type="button"
                                className={
                                    localStorage.getItem("mode") === "true" ?
                                        "btn-close"
                                        :
                                        "btn-close-dark"
                                }
                                data-bs-dismiss="modal"
                                aria-label="Close" >
                            </button>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-12 pt-3'>
                                    {
                                        props.content ?
                                            <p className={`${styles.e_low_balance_text} px-3 text-start e-secondary-color`}>
                                                {
                                                    props.content
                                                }
                                            </p>
                                            :
                                            <p className={`${styles.e_low_balance_text} px-3 text-center e-secondary-color`}>
                                                Are you sure you want to exit the game?
                                                This action will square off any pending entries and refund the un-used amount.
                                                You will not be able to re-join in this game.
                                            </p>
                                    }
                                </div>
                            </div>

                            <div className="row pt-4 mt-4 pb-2">
                                <div className="col-lg-12 text-end pe-4">
                                    <SecondaryBtn label="Cancel"
                                        style={{ padding: "10px 16px" }}
                                        cancel="modal"
                                    />
                                    <PrimaryBtn
                                        label={props.demo ? "Yes, Switch" : "Yes, Exit"}
                                        handleClick={() => {
                                            props.demo ?
                                                props.modalClose()
                                                :
                                                _exitGame()
                                        }}
                                        className={
                                            loader || props.loader ?
                                                `${styles.e_modal_submit} e-modal-submit e-save-btn ms-3 e-btn-loader`
                                                :
                                                `${styles.e_modal_submit} e-modal-submit e-save-btn ms-3`
                                        }
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                    />
                    :
                    null
            }
        </>
    )
}

