/*
 *   File : welcome.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Welcome Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Tab } from '../../components/dataFormatting/index';
import { LinkText } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/welcome.module.scss';

/*import assets */
import Icon from '../../assets/images/modal/bull.svg';
import Game from '../../assets/images/modal/game.svg';
import Arrow from '../../assets/images/modal/down-arrow.svg';
import Explore from '../../assets/images/modal/icon.svg';
import Logo from '../../assets/images/header/new_logo.svg';
import LeftSideImage from '../../assets/images/modal/welcomeBannerArrow.png';
import RightSideImage from '../../assets/images/modal/welcomeBannergraph.png';

export default function WelcomeModal(props) {
	const [tabIndex, setTabindex] = useState(1);
	//eslint-disable-next-line
	const [visible, setVisible] = useState(true);
	const [content, setContent] = useState(false);
	const [renderImage, setRenderImage] = useState(false);
	const [userName, setUserName] = useState();
	//eslint-disable-next-line
	const [kyc_status, setKYCStatus] = useState();

	// const toggleVisible = () => {
	// 	const scrolled = document.documentElement.scrollTop;
	// 	if (scrolled > 0) {
	// 		setVisible(false)
	// 	}
	// 	else if (scrolled <= 0) {
	// 		setVisible(true)
	// 	}
	// };
	useEffect(() => {
		var myModal = document.getElementById('welcome')
		myModal.addEventListener('shown.bs.modal', () => {
			setTimeout(() => {
				setRenderImage(true)
			}, 1000)
		})
	}, [])

	useEffect(() => {
		var myModal = document.getElementById('welcome')
		myModal.addEventListener('hidden.bs.modal', () => {
			localStorage.setItem("is_welcome", true);
		})
	}, [])

	useEffect(() => {
		if (props.name) {
			let name = props.name.split(' ');
			setUserName(name[0])
		}
	}, [props.name])

	useEffect(() => {
		if (props.data) {
			setKYCStatus(props.data.kyc_status)
		}
	}, [props.data])

	useEffect(() => {
		if (renderImage) {
			var element = document.getElementById('bull');
			setTimeout(() => {
				if (window.innerWidth > 768) {
					document.getElementById('animate-wrapper').style.width = "50%";
				}
				else {
					document.getElementById('animate-wrapper').style.width = "100%";
				}
				document.getElementById('text').style.position = "relative";
				// document.getElementById('text').style.marginTop = "40%";
				document.getElementById('text').innerHTML = "Hi I am Stockbull ! I am here to assist you";
				document.getElementById("animate-wrapper").classList.remove('position-relative');
				if (window.innerWidth > 768) {
					element.classList.add(styles['e_animate_img'])
				} else {
					element.classList.add(styles['e_animate_img_small'])
				}

			}, 2000)
			setTimeout(() => {
				document.getElementById('text').style.display = 'none';
			}, 4000)
			setTimeout(() => {
				setContent(true);
			}, 5000)
		}

	}, [renderImage])

	const arrowClick = () => {
		// const element = document.getElementById("content-body");
		// element.scrollTop = element.scrollHeight;
		// // alert("hi");
		// console.log(element)
		// window.scrollTo({
		// 	top: element.scrollHeight,
		// 	behavior: 'auto'
		// 	/* you can also use 'auto' behaviour 
		// 	   in place of 'smooth' */
		// });
		var ele = document.getElementById("welcome-footer");
		ele.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest"
		});
	}


	return (
		<div className={`${styles.e_welcome_modal}  e-kyc-modal modal fade e-game-detail-modal`}
			id="welcome"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`} >
				{
					content ?
						<div className={`${styles.e_modal_content} modal-content `} >
							<div className={`${styles.e_modal_header} modal-header mb-2`}>
								<h4 className='e-secondary-color'>About stocksports</h4>
								<button type="button"
									className={localStorage.getItem("mode") === "true" ? "btn-close" : "btn-close-dark"}
									data-bs-dismiss="modal"
									aria-label="Close"
									onClick={() => {
										localStorage.setItem("is_welcome", true)
									}}></button>
							</div>
							<div className="container pt-3 px-3  e-secondary-color">
								<div className={`row ${styles.e_main_content} `}>
									<div className='col-lg-1 col-1'>
										<img alt=""
											draggable={false}
											src={Icon}
											className="e-image-bull" />
									</div>
									<div className='col-lg-10 col-11'>
										<div className={`${styles.e_content_wrapper} e-welcome-content-wrapper`} >
											<div className={`${styles.e_main_logo_section} text-center col-lg-12`}>
												<img alt=""
													draggable={false}
													src={LeftSideImage}
													className={`${styles.e_left_img} e-welcome-modal-left-img`}
												/>
												<img alt=""
													draggable={false}
													src={Logo}
													className={`${styles.e_main_logo}`}
												/>
												<img alt=""
													draggable={false}
													src={RightSideImage}
													className={`${styles.e_right_img} e-welcome-modal-right-img`}
												/>
											</div>
											{/* <img alt="" src={Banner} className={`${styles.e_banner} mb-3`} /> */}
											<img alt=""
												draggable={false}
												src={Arrow}
												className={`${styles.e_arrow}`}
												id="topButton"
												onClick={() => {
													arrowClick()
												}}
												style={{ display: visible ? 'inline' : 'none' }} />

											<p className='pt-3  e-secondary-color'>
												Investing in stocks can be a risky affair. While you can make tremendous profits, there is great potential for losses because the share market is difficult as the values fluctuate. You need to hone your skills, understand the stocks you invest in and then pick the right scrips.
											</p>

											<p className=' e-secondary-color'>
												Stock trading is an art that, if mastered, can deliver attractive returns on investments. At Stock Sports, we help you develop your trading and investment skills. You can pick stocks or scrips and then see how they perform. If your chosen stock did not perform as expected, our AI helps you understand what went wrong.
											</p>

											<p className=' e-secondary-color'>
												We, thus, help you learn from your mistakes. We mentor our participants in investing their capital in the proper manner. You can measure and hone your decision-making ability when it comes to picking the right stocks. Stock Sports also helps you develop your mindset to understand which stock would perform well through a technical and fundamental analysis of the scrips.
											</p>

											<div className='mt-4 pt-2 mb-4'>
												<Tab tabIndex={tabIndex} data={['M2E', 'M2M', 'Skill race']} setTab={(temp) => setTabindex(temp)} />
											</div>
											{
												tabIndex === 1 ?
													<>
														<img alt="" draggable={false} src={Game} />
														<p className='e-secondary-color'
															dangerouslySetInnerHTML={{ __html: props.gameData?.m2e?.settings?.about }}>
														</p>
													</>
													:
													null
											}
											{
												tabIndex === 2 ?
													<>
														<img alt="" draggable={false} src={Game} />
														<p className=' e-secondary-color'>Top 3 Performing & Under Performing scrip's get rewards in every slot.</p>
														<p className=' e-secondary-color'>1.	Unlike the M2E game (where a player can purchase Gaming Units before the opening of the stock market, and the results are declared at the closing of the stock exchanges), in the case of the M2M game, the games will be divided into periods of 60 (Sixty) minutes from 9:30:00 hrs (15 minutes after the opening of the stock exchanges).A player will be allowed to purchase the Gaming Units. The first 30 minutes will be the booking time and last 30 minutes will be the waiting time, and the results will be declared at the end of the 60 (sixty) minute period.</p>

														<p className=' e-secondary-color'>2.	Results of the top 3 (three) performing scrip percentages and top 3 (three) underperforming scrip percentages (in positive and negative percentage terms, respectively) will then be declared based on the closing price of the scrip on the Indian stock exchange in a given 60 (sixty) minute period of the game.</p>

														<p className=' e-secondary-color'>3.	Subject to (1) and (2) above, the game mechanics of the M2M game format will be the same as the M2E.</p>

														<p className=' e-secondary-color'><span><b>Note1: </b></span>Each slot will have a time frame of 60 (sixty) minutes. Out of 60 (sixty) minutes, 30 (thirty) minutes will be given as assessment time (booking time), where the player can purchase Gaming Units in the Gainer's category or the Loser's category. Modification and cancellation will be allowed only during the first (30) thirty minutes of the slot. The result will be declared at the end time of a 60 (sixty) minute period. The top three Gaining Percentage scrip and Losing Percentage scrip (based on positive and negative percentage terms, respectively) will be declared as winning scrip. (Result Number of scripts can be increased or decreased in future). </p>

														<p className='e-secondary-color'><b>M2Mmilestone</b></p>
														<p className='e-secondary-color'>The M2M game also offers milestone rewards based on your performance. Here’s how the rewards are allotted:</p>
														<ul style={localStorage.getItem("mode") === "true" ? { color: "#ffffff", fontSize: "14px" } : { color: "#1f1136" }}>
															<li className=' e-secondary-color'>There are 20 scrips in every game, and there are 6 gaming rounds on each trading day</li>
															<li className=' e-secondary-color'>If a month has 20 days, you can play 120 games in the month (6 games each day * 20 days)</li>
															<li className=' e-secondary-color'>Possible scrips that you can pick each day = 20*6 = 120</li>
															<li className=' e-secondary-color'>Possible scrips in a month = 120 * 20 = 2400</li>
															<li className=' e-secondary-color'>For each successful scrip choice, you get 1 point and for each unsuccessful pick you lose 0.5 points</li>
															<li className=' e-secondary-color'>Maximum points that you can score for guessing all the right scrips = 2400 * 1 = 2400</li>
															<li className=' e-secondary-color'>To qualify for the milestone, you have to score at least 10% of the maximum points, i.e., 10% of 2400, which is 240</li>
															<li className=' e-secondary-color'>If you do, you can earn a reward of INR 25,000</li>

														</ul>
													</>
													:
													null
											}
											{
												tabIndex === 3 ?
													<>
														<img alt="" draggable={false} src={Game} />
														<p className=' e-secondary-color'
															dangerouslySetInnerHTML={{ __html: props.gameData.skillrace.settings.about }}>
														</p>
													</>
													:
													null
											}

											<div className="text-center mt-5">
												<LinkText
													label="Explore"
													right={true}
													image={Explore}
													className={`${styles.e_btn}`}
													handleClick={() => {
														$("#welcome").trigger("click");
														localStorage.setItem("is_welcome", true)
													}} />
											</div>
											<div id="welcome-footer"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
						:
						<div className={`${styles.e_modal_content} modal-content`}>
							<div className={`${styles.e_animation_wrapper} position-relative tex-center`} id="animate-wrapper">
								{
									renderImage ?
										<img alt=""
											draggable={false}
											src={Icon} className={`${styles.e_bull}`}
											id="bull" />
										:
										null
								}
								<h5 className={
									renderImage ?
										`${styles.e_e_img_margin}  ${styles.e_user_name} e-welcome-name e-secondary-color`
										:
										`${styles.e_user_name} e-welcome-name e-secondary-color`
								}
									id="text">
									Hi {userName},
								</h5>
							</div>
						</div>
				}

			</div>
		</div>
	)
}