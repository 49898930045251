import React, { createContext, useState } from 'react';

export const FireBaseContext = createContext();

export const FirebaseProvider = props => {
    const [analytics, setAnalytics] = useState(null);
    return (
        <FireBaseContext.Provider value={[analytics, setAnalytics]}>
            {props.children}
        </FireBaseContext.Provider>
    )
}