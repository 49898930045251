/*
 *   File : solid.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Solid Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import style*/
import styles from '../../styles/components/solid.module.scss';


const SolidBtn = (props) => {

  return (
    <button
      id={props.id}
      onClick={props.handleClick}
      className={
        props.color === 1 ?
          `${styles.e_solid_btn} ${styles.e_solid_green} ${props.className}`
          :
          `${styles.e_solid_btn} ${styles.e_solid_red} ${props.className}`
      }
      data-bs-dismiss={props.cancel}
      data-bs-toggle={props.toggle}
      data-bs-target={props.target}
      disabled={props.disabled}
      style={
        props.v2r ?
          { backgroundColor: "#9c1fe9" }
          :
          {}
      }
    >
      {props.label}
    </button>
  );
}

export default SolidBtn;