/*
 *   File : game-detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Game Detail Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-01-2022
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';

/*import styles*/
import styles from '../../styles/components/game-result.module.scss';

/* import components */
import { Slot } from './index';

/*import assets */
import Result from '../../assets/images/modal/result-modal.png';
import DropdownIconDark from '../../assets/images/header/dropdown.png';
import { Datatable, Tab } from '../dataFormatting';
import DropdownIcon from '../../assets/images/header/header_down.png';
import EmptyScreen from '../emptyScreen/empty';
import services from '../../services/service';

const GameResult = React.memo(function GameResult(props) {

	const [tabIndex, setTabindex] = useState(1);
	const [resultData, setResultData] = useState();
	const [myBooking, setMyBooking] = useState([]);
	const [slotName, setSlotName] = useState(null);
	const [slotId, setSlotId] = useState(null);
	const [slotDropdown, setSlotDropdown] = useState(false);
	const [slotData, setSlotData] = useState([]);

	const slotRef = useRef(null);
	const closeMenuSlot = (event) => {
		if (slotRef.current && !slotRef.current.contains(event.target)) {
			setSlotDropdown(false)
		}
	}

	useEffect(() => {
		if (props.result) {
			_getSlots()
		}
	}, [props.result])

	useEffect(() => {
		if (props.type === "m2m") {
			_getSlots()
			document.addEventListener('mouseup', closeMenuSlot);
			return () => {
				document.removeEventListener('mouseup', closeMenuSlot);
			};
		}
		//eslint-disable-next-line
	}, []);
	useEffect(() => {
		// console.log("props.data => ", props.data)
		if (props.data) {
			setResultData(props.data);
		}
	}, [props.data])

	useEffect(() => {
		var myModalEl = document.getElementById('game-result')
		myModalEl.addEventListener('shown.bs.modal', (event) => {
			if (props.data.my_bookings) {
				let booking = [];
				for (let index = 0; index < props.data.my_bookings.length; index++) {
					const element = props.data.my_bookings[index];
					if (element.loser_qty !== '-' || element.gainer_qty !== '-') {
						booking.push(element);
					}
				}
				setMyBooking(booking);
			}
		})
	}, [props.data])

	useEffect(() => {
		var myModalEl = document.getElementById('game-result')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			setMyBooking([]);
			setTabindex(1);
			props.close();
			$("body").removeAttr("style");
			$(".fade").removeClass("modal-backdrop");
		})
		//eslint-disable-next-line
	}, [props.data])


	//result table columns
	const RESULTS_COLUMNS = [
		{
			name: 'Top Gainers %',
			selector: 'scrip_code',
			sortable: true,
			left: true,
		},
		{
			name: 'Pre. close',
			selector: row => parseFloat(row.booking_ltp),
			sortable: true,
			left: true,
			cell: (row) => <div className={'e-green'} >{row.booking_ltp}</div>
		},
		{
			name: 'LTP',
			selector: row => parseFloat(row.closing_ltp),
			sortable: true,
			left: true,
			cell: (row) => <div className={'e-green'} >{row.closing_ltp}</div>
		},
		{
			name: 'Scrip Qty',
			selector: 'scrip_qty',
			sortable: true,
			left: true,
			cell: (row) => <div className={'e-green'} >{row.scrip_qty}</div>
		},
		{
			name: '% Change',
			selector: 'percentage_change',
			sortable: true,
			left: true,
			cell: (row) => <div className={'e-green'} >{row.percentage_change}</div>
		},
		{
			name: 'Reward Amount',
			selector: row => row.prize_amount !== '-' && row.prize_amount !== "No Forecaster" ? parseFloat(row.prize_amount) : 0,
			sortable: true,
			left: true,
			cell: (row) => <div >{row.prize_amount !== '-' && row.prize_amount !== "No Forecaster" ? `₹ ${row.prize_amount}` : 'No Forecaster'}</div>
		},
	]

	// loser table 
	const LOSER_COLUMNS = [
		{
			name: 'Top Losers %',
			selector: 'scrip_code',
			sortable: true,
			left: true,

		},
		{
			name: 'Pre.Close',
			selector: row => parseFloat(row.booking_ltp),
			sortable: true,
			left: true,
			cell: (row) => <div className='e-red'  >{row.booking_ltp}</div>
		},
		{
			name: 'LTP',
			selector: row => parseFloat(row.closing_ltp),
			sortable: true,
			left: true,
			cell: (row) => <div className='e-red'  >{row.closing_ltp}</div>
		},
		{
			name: 'Scrip Qty',
			selector: 'scrip_qty',
			sortable: true,
			left: true,
			cell: (row) => <div className={'e-red'} >{row.scrip_qty}</div>
		},
		{
			name: '% Change',
			selector: row => parseFloat(row.percentage_change),
			sortable: true,
			left: true,
			cell: (row) => <div className='e-red'  >{row.percentage_change}</div>
		},
		{
			name: 'Reward Amount',
			selector: row => row.prize_amount !== '-' && row.prize_amount !== "No Forecaster" ? parseFloat(row.prize_amount) : 0,
			sortable: true,
			left: true,
			cell: (row) => <div >{row.prize_amount !== '-' && row.prize_amount !== "No Forecaster" ? `₹ ${row.prize_amount}` : 'No Forecaster'}</div>
		},
	]

	//booking table columns
	const MYBOOKINGS_COLUMNS = [
		{
			name: 'Symbol',
			selector: row => row.scrip_code,
			sortable: true,
			left: true,
			cell: (row) => <div > {row.scrip_code}</div>
		},
		{
			name: 'Gainer Qty ',
			selector: row => row.gainer_qty !== "-" ? row.gainer_qty : 0,
			sortable: true,
			left: true,
			cell: (row) => <div className='e-green'> {row.gainer_qty}</div>
		},
		{
			name: 'Loser Qty',
			selector: row => row.loser_qty !== "-" ? row.loser_qty : 0,
			sortable: true,
			left: true,
			cell: (row) => <div className='e-red'> {row.loser_qty}</div>
		},
		{
			name: '% Change',
			selector: row => parseFloat(row.percentage_change),
			sortable: true,
			left: true,
			cell: (row) => <div className={parseFloat(row.percentage_change) < 0 ? 'e-red' : 'e-green'}  >{row.percentage_change}</div>
		},
		{
			name: 'Amount',
			selector: row => parseFloat(row.booking_amount),
			sortable: true,
			left: true,
			cell: (row) => <div > {row.booking_amount !== "-" ? `₹ ${row.booking_amount}` : row.booking_amount}</div>
		},
		{
			name: 'Reward ',
			selector: row => row.rewards !== "-" ? parseFloat(row.rewards) : 0,
			sortable: true,
			left: true,
			cell: (row) => <div >{row.rewards !== '-' ? `₹ ${row.rewards}` : row.rewards}</div>
		},

	]
	// total booking table column
	const TOTAL_BOOKINGS_COLUMNS = [
		{
			name: 'Symbol',
			selector: row => row.scrip_code,
			sortable: true,
			left: true,
		},
		{
			name: 'Prev. Close',
			selector: row => parseFloat(row.booking_ltp),
			sortable: true,
			left: true,
			cell: (row) => <div>{row.booking_ltp > 0 ? `₹ ${row.booking_ltp}` : '-'}</div>
		},
		{
			name: 'Close Price',
			selector: row => parseFloat(row.closing_ltp),
			sortable: true,
			left: true,
			cell: (row) => <div>{row.closing_ltp > 0 ? `₹ ${row.closing_ltp}` : '-'}</div>
		},
		{
			name: '% Change',
			selector: row => parseFloat(row.percentage_change),
			sortable: true,
			left: true,
			cell: (row) => <div className={row.percentage_change > 0 ? 'e-green' : 'e-red'} >{row.percentage_change}</div>
		},
		{
			name: 'Gainer Qty ',
			selector: row => row.perform_quantity && row.perform_quantity !== "-" ? row.perform_quantity : 0,
			cell: (row) => <div className='e-green'>{row.perform_quantity > 0 ? row.perform_quantity : '-'}</div>,
			sortable: true,
			left: true,
		},
		{
			name: 'Loser Qty',
			selector: row => row.under_perform_quantity && row.under_perform_quantity !== "-" ? row.under_perform_quantity : 0,
			cell: (row) => <div className='e-red'>{row.under_perform_quantity > 0 ? row.under_perform_quantity : '-'}</div>,
			sortable: true,
			left: true,
		},
	]

	const _renderColumns = () => {
		switch (tabIndex) {
			case 1: return RESULTS_COLUMNS;
				//eslint-disable-next-line
				break;
			case 2: return MYBOOKINGS_COLUMNS;
				//eslint-disable-next-line
				break;
			case 3: return TOTAL_BOOKINGS_COLUMNS;
				//eslint-disable-next-line
				break;
			default: return "all"
		}
	}

	const _renderTableBody = () => {
		//eslint-disable-next-line
		switch (tabIndex) {
			case 1: return resultData?.gainers_losers?.gainers;
				//eslint-disable-next-line
				break;
			case 2: return myBooking;
				//eslint-disable-next-line
				break;
			case 3: return resultData?.total_bookings?.scrips;
				//eslint-disable-next-line
				break;
		}
	}
	const _getClass = (temp) => {
		if (temp !== undefined) {
			let sp = temp;

			if (parseFloat(sp) < 0) {
				return 'e-red';
			} else if (parseFloat(sp) > 0) {
				return 'e-green';
			} else {
				return 'e-secondary-color'
			}
		}
		// return 'e-green';
	}

	const _getSlots = () => {
		const url = "customer/m2m-slots"

		services(false, url).then((response) => {
			if (response.status_code === 200) {
				setSlotData(response.data);

				let inActiveArr = response.data
				let tempArr = inActiveArr.filter((item) => item.active === false)
				setSlotId(tempArr[tempArr.length - 1].game_id)
				setSlotName(`${tempArr[tempArr.length - 1].start_time} - ${tempArr[tempArr.length - 1].end_time}`)
			} else {
				setSlotData([])
				setSlotId(null)
				setSlotName(null)
			}
		})
	}

	return (
		<div className={`${styles.e_game_result_modal} e-game-result-modal modal fade e-game-detail-modal`}
			id="game-result"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true">
			{/* { console.log("resultData", resultData)} */}
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} modal-header mb-2`}>
						<h4 className='ps-0 e-secondary-color'>
							Game results
						</h4>
						{
							props.type === "m2m" ?
								<div className=" position-relative ps-3" style={{ color: "white", cursor: "pointer" }}>
									<span className='e-slot-wrap e-secondary-color'
										onClick={() => setSlotDropdown(true)}
										style={
											localStorage.getItem("mode") === "true" ?
												{
													border: "1px solid white",
													padding: "6px 20px",
													borderRadius: "64px"
												}
												:
												{
													border: "1px solid #9c1fe9",
													padding: "6px 20px",
													borderRadius: "64px"
												}
										}>
										Slot: {
											slotName ?
												slotName
												:
												"Select"
										}
										<img src={
											localStorage.getItem("mode") === "true" ?
												DropdownIcon
												:
												DropdownIconDark
										}
											draggable={false}
											alt=""
											width="10px"
											className="ms-2"
										/>

									</span>
									{
										slotDropdown ?
											<div ref={slotRef}>
												<Slot
													type={props.type}
													id={slotId}
													show={false}
													data={slotData.filter((item) => item.active === false)}
													name={slotName}
													className={"e-game-detail-wrapper"}
													Selected={(id, name, gameId) => { setSlotDropdown(false); setSlotName(name); setSlotId(gameId); props.Selected(gameId) }} />
											</div>
											:
											null
									}
								</div>
								:
								null
						}
						<button
							type="button"
							className={localStorage.getItem("mode") === "true" ? "btn-close" : "btn-close-dark"}
							data-bs-dismiss="modal"
							onClick={() => { props.close(); }}
							aria-label="Close">
						</button>
					</div>
					<div className="container-fluid e-sm-margin pt-3 px-3">
						<div className="row">
							<div className="col-lg-12 justify-content-center d-flex">
								<img src={Result}
									alt=""
									height="165px"
									draggable={false}
								/>
							</div>
							<p className="text-center pt-2">
								Woohooo..! Results are out.Check your score and details
							</p>
						</div>
						<div className="container">
							<div className={`${styles.e_result_table} e-result-modal-data-wrapper mt-4 mx-auto e-home-card-wrapper`}>
								<div className=" px-0  text-center e-result-data" style={{ width: "14%" }}>
									<p className="mb-2 ">
										Game ID
									</p>
									<h6 className='e-secondary-color'>
										{resultData?.game_details?.game_id}
									</h6>

								</div>
								<div className=" px-0 text-center e-result-data">
									<p className="mb-2 ">
										Date
									</p>
									<h6 className='e-secondary-color'>
										{resultData?.game_details?.date}
									</h6>

								</div>
								<div className=" px-0 text-center e-result-data">
									<p className="mb-2 ">
										Time
									</p>
									<h6 className='e-secondary-color'>
										{resultData?.game_details?.result_time}
									</h6>

								</div>
								<div className="px-0 text-center e-result-data">
									<p className="mb-2 ">
										Gainer Qty
									</p>
									<h6 className='e-secondary-color'>
										{resultData?.game_details?.gainer_qty ? resultData?.game_details?.gainer_qty : '-'}
									</h6>

								</div>
								<div className=" px-0 text-center e-result-data">
									<p className="mb-2 ">
										Loser Qty
									</p>
									<h6 className='e-secondary-color'>
										{resultData?.game_details?.loser_qty ? resultData?.game_details?.loser_qty : '-'}
									</h6>

								</div>
								<div className=" px-0 text-center e-result-data">
									<p className="mb-2 ">
										Booking Amt
									</p>
									<h6 className="e-secondary-color">
										{resultData?.game_details?.booking_amount !== '-' ?
											` ₹ ${resultData?.game_details?.booking_amount}`
											:
											resultData?.game_details?.booking_amount
										}
									</h6>
								</div>
								<div className="l px-0 text-center e-result-data">
									<p className="mb-2 ">
										Reward
									</p>
									<h6 className='e-secondary-color'>
										{
											resultData?.game_details?.total_rewards !== "-" ?
												`₹ ${resultData?.game_details?.total_rewards}`
												:
												resultData?.game_details?.total_rewards
										}
									</h6>

								</div>
								<div className=" px-0 text-center e-result-data">
									<p className="mb-2 "> P/L</p>
									<h6 className='e-secondary-color'>
										{
											resultData?.game_details?.p_and_l !== '-' ?
												<span className={_getClass(resultData?.game_details?.p_and_l)}>
													₹ {resultData?.game_details?.p_and_l}
												</span>
												:
												'-'
										}
									</h6>

								</div>
								{/* <div className="col-lg-2 px-0 text-center">
									<h6>{resultData?.game_details?.m2e_rank}</h6>
									<p>M2E Rank</p>
								</div> */}
								{/* <div className="col-lg-2 px-0 text-center">
									<h6>{resultData?.game_details?.global_rank}</h6>
									<p>Global Rank</p>
								</div> */}

							</div>
						</div>
						<div className="row my-4 py-2 ">
							<div className="col-lg-12">
								<div className="d-flex justify-content-between">
									<Tab
										className={styles.e_game_result_modal_tab}
										inactiveClass={styles.e_inactive}
										tabIndex={tabIndex}
										data={['Results', 'My bookings', 'Total bookings']}
										setTab={(temp) => setTabindex(temp)}
									/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							{
								<div>
									{
										_renderTableBody() && !_renderTableBody().length ?
											<EmptyScreen
												note={
													tabIndex === 1 ?
														""
														:
														"You don't have any winning scrips"
												}
											/>
											:
											<div className='e-16rounded-table'>
												<Datatable
													pagination={false}
													columns={_renderColumns()}
													data={_renderTableBody()}
												/>
											</div>
									}

									{
										tabIndex === 1 && resultData?.gainers_losers?.losers.length ?
											<div className='mt-3 e-16rounded-table pb-3'>
												<Datatable
													pagination={false}
													columns={LOSER_COLUMNS}
													data={resultData?.gainers_losers?.losers}
												/>
											</div>
											:
											null

									}
									{
										tabIndex === 3 ?
											<div className={`col-lg-12 py-2 ps-3  mt-3  ${styles.e_total_qty} e-total d-flex `}>
												<div className='col-lg-8 e-secondary-color'>
													Total
												</div>
												<div className='col-lg-2 ps-1 me-2 e-secondary-color'>
													{
														resultData?.total_bookings?.total_perform_quantity > 0 ?
															resultData?.total_bookings?.total_perform_quantity
															:
															'-'
													}
												</div>
												<div className='col-lg-2 ps-1 e-secondary-color'>
													{
														resultData?.total_bookings?.total_under_perform_quantity > 0 ?
															resultData?.total_bookings?.total_under_perform_quantity
															:
															'-'
													}
												</div>
											</div>
											:
											null
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
})

export default GameResult;

