/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { PrimaryBtn } from '../../components/cta/index';
import { Input } from '../formElements';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/*import assets */
// import Mpin from '../../assets/images/modal/mpin.png';

/*import assets */
import Kyc from '../../assets/images/modal/kyc.png';
import KycWhite from '../../assets/images/modal/kyc-white.png';
import Hide from '../../assets/images/auth/hide.svg';
import Show from '../../assets/images/auth/password.svg';
import HideWhite from '../../assets/images/auth/hide-white.svg';
import ShowWhite from '../../assets/images/auth/password-white.svg';

//services
import services from '../../services/service';
// import GetURLVar from '../../services/getUrl';


export default function CreateMpin(props) {
    // const [pin, setPin] = useState(false);
    const [confirmPin, setConfirmPin] = useState("");
    const [mpin, setMpin] = useState("");
    const [created, setCreated] = useState(false);
    const [error, setError] = useState(null);
    const [oldMpin, setOldMpin] = useState("");
    const [loader, setLoader] = useState(false);
    // const [url, setUrl] = useState("");
    const [updatePinStatus, setUpdatePinStatus] = useState(false);
    const [updatePinStatus1, setUpdatePinStatus1] = useState(false);
    const [updatePinStatus2, setUpdatePinStatus2] = useState(false);
    const [updatePinStatus3, setUpdatePinStatus3] = useState(false);
    const [updatePinStatus4, setUpdatePinStatus4] = useState(false);

    useEffect(() => {
        let modal = document.getElementById('create-mpin')
        modal.addEventListener('hidden.bs.modal', function (event) {
            // props.modalClose();
            $(".fade").removeClass("modal-backdrop")
            $("body").removeAttr("style")
            setOldMpin("");
            setMpin("");
            setConfirmPin("");
            setUpdatePinStatus(false);
            setUpdatePinStatus1(false);
            setUpdatePinStatus2(false);
            setUpdatePinStatus3(false);
            setUpdatePinStatus4(false);
        })
        //eslint-disable-next-line
    }, [])

    useState(() => {
        setCreated(props.pin_created)
    }, [props.pin_created])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    // //create  mpin
    // const _createPin = async () => {
    //     setLoader(true);

    //     const query = await GetURLVar()

    //     if (query.forgot) {
    //         setUrl(JSON.parse(query.forgot))
    //         var url = "customer/auth/reset-pin";
    //     } else {
    //         //eslint-disable-next-line
    //         var url = "customer/auth/create-pin";
    //     }


    //     var data = {
    //         pin: mpin
    //     }

    //     services(true, url, data).then((response) => {
    //         if (response.status_code === 200) {
    //             localStorage.setItem("mpin", true);
    //             setCreated(true);
    //             setLoader(false);
    //         } else {
    //             setError(response.message);
    //             setLoader(false);
    //         }
    //     })
    // }

    // //change  mpin
    const _changePin = () => {
        setLoader(true);
        var url = "customer/auth/change-pin";

        var data = {
            current_pin: oldMpin,
            new_pin: mpin
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                // localStorage.setItem("mpin", true);
                setCreated(true);
                setLoader(false);
            } else {
                setError(response.message);
                setLoader(false);
            }
        })
    }


    const _validatePin = () => {
        if (mpin === confirmPin) {
            if (props.update_pin) {
                _changePin();
            } else {
                // _createPin();
            }
        } else {
            if (props.update_pin) {
                setError("Entered confirm MPIN is not same as new MPIN")
            } else {
                setError("Entered confirm MPIN is not same as MPIN")
            }
        }
    }

    return (
        <div className={`${styles.e_mpin_modal} modal fade`}
            id="create-mpin"
            tabIndex="-1"
            aria-labelledby="kyc"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    {
                        // !pin ?

                        //     <div className="container">
                        //         <div className="row">
                        //             <div className='col-lg-12 pt-3 text-center'>
                        //                 <img src={Mpin}
                        //                     // className={`${styles.e_img_low_balance}`}
                        //                     alt="no data"
                        //                     draggable={false}
                        //                 // style={{ height: "300px", objectFit: "contain" }}
                        //                 />
                        //                 <div className={`${styles.e_modal_header} modal-header mb-2 d-flex justify-content-center`}>
                        //                     <h5>{props.pin_title}</h5>
                        //                 </div>
                        //                 <p className={`${styles.e_low_balance_text} e-secondary-color`}>
                        //                     {props.pin_desc}
                        //                     {/* {`Complete KYC for seamless withdrawal`} */}
                        //                 </p>
                        //             </div>
                        //         </div>



                        //         <div className='row'>
                        //             {/* <div className='col-lg-6 col-md-6 col-12'>
                        //                 <SecondaryBtn
                        //                     label={props.pin_label}
                        //                     className={styles.e_label + " w-100"}
                        //                     cancel="modal"
                        //                     handleClick={() => {
                        //                         if (localStorage.getItem("signup") === "true") {
                        //                             props.modalClose()
                        //                         }
                        //                         localStorage.setItem("mpin", true)
                        //                         localStorage.setItem("mpin_status", true)
                        //                     }}
                        //                 />
                        //             </div> */}
                        //             <div className="col-lg-12 col-md-12 col-12">
                        //                 <PrimaryBtn
                        //                     label={props.pin_btn}
                        //                     type={2}
                        //                     className={`${styles.e_modal_submit} w-100`}
                        //                     handleClick={() => {
                        //                         setPin(true)
                        //                     }}
                        //                 />
                        //             </div>
                        //         </div>


                        //     </div>

                        //     :
                        !created ?
                            <div className='container p-3'>

                                <div className='d-flex'>
                                    <p className={`${styles.e_setpin_title} e-secondary-color`}>
                                        Setting your MPIN will secure your transaction and enable you to log in.
                                    </p>
                                    {
                                        props.update_pin ?
                                            <button
                                                type="button"
                                                className={
                                                    localStorage.getItem("mode") === "true" ?
                                                        "btn-close ms-3"
                                                        :
                                                        "btn-close-dark ms-3"
                                                }
                                                data-bs-dismiss="modal"
                                                aria-label="Close" >
                                            </button>
                                            :
                                            null
                                    }
                                </div>
                                {
                                    props.update_pin ?
                                        <div className='row mt-2 pt-2'>
                                            <div className='col-12 position-relative'>
                                                <label className='mb-2'>Enter old MPIN </label>
                                                <Input mpin={true}
                                                    type={
                                                        updatePinStatus ?
                                                            "number"
                                                            :
                                                            "password"
                                                    }
                                                    value={oldMpin}
                                                    valueChange={(temp) => {
                                                        if (temp.length <= 4) {
                                                            setOldMpin(temp);
                                                        }
                                                    }}
                                                    placeholder="Enter old MPIN"
                                                    className={`${styles.e_input_bg} e-bg-white`}

                                                />
                                                <img src={
                                                    localStorage.getItem("mode") === "true" ?
                                                        updatePinStatus ?
                                                            Show
                                                            :
                                                            Hide
                                                        :
                                                        updatePinStatus ?
                                                            ShowWhite
                                                            :
                                                            HideWhite
                                                } alt=""
                                                    className={styles.e_password}
                                                    onClick={() => {
                                                        setUpdatePinStatus(!updatePinStatus)
                                                    }} />
                                            </div>

                                            <div className='col-12 mt-3 position-relative' >
                                                <label className='mb-2'>Enter new MPIN</label>
                                                <Input mpin={true}
                                                    type={
                                                        updatePinStatus1 ?
                                                            "number"
                                                            :
                                                            "password"
                                                    }
                                                    value={mpin}
                                                    className={`${styles.e_input_bg} e-bg-white`}
                                                    valueChange={(temp) => {
                                                        if (temp.length <= 4) {
                                                            setMpin(temp)
                                                        }
                                                    }}
                                                    placeholder="Enter new MPIN"
                                                />
                                                <img src={
                                                    localStorage.getItem("mode") === "true" ?
                                                        updatePinStatus1 ?
                                                            Show
                                                            :
                                                            Hide
                                                        :
                                                        updatePinStatus1 ?
                                                            ShowWhite
                                                            :
                                                            HideWhite
                                                } alt=""
                                                    className={styles.e_password}
                                                    onClick={() => {
                                                        setUpdatePinStatus1(!updatePinStatus1)
                                                    }} />
                                            </div>
                                            <div className='col-12 mt-3 position-relative'>
                                                <label className='mb-2'>Confirm new MPIN</label>
                                                <Input mpin={true}
                                                    type={
                                                        updatePinStatus2 ?
                                                            "number"
                                                            :
                                                            "password"
                                                    }
                                                    value={confirmPin}
                                                    className={`${styles.e_input_bg} e-bg-white`}
                                                    valueChange={(temp) => {
                                                        if (temp.length <= 4) {
                                                            setConfirmPin(temp)
                                                        }
                                                    }}
                                                    placeholder="Confirm MPIN"
                                                />
                                                <img src={
                                                    localStorage.getItem("mode") === "true" ?
                                                        updatePinStatus2 ?
                                                            Show
                                                            :
                                                            Hide
                                                        :
                                                        updatePinStatus2 ?
                                                            ShowWhite
                                                            :
                                                            HideWhite
                                                } alt=""
                                                    className={styles.e_password}
                                                    onClick={() => {
                                                        setUpdatePinStatus2(!updatePinStatus2)
                                                    }} />

                                            </div>
                                            <div className='col-12 pt-3'>
                                                {
                                                    confirmPin && mpin && oldMpin ?
                                                        <PrimaryBtn label="Save"
                                                            className={
                                                                loader ?
                                                                    "e-btn-loader w-100 mt-4"
                                                                    :
                                                                    "w-100 mt-4"
                                                            }
                                                            type={2}
                                                            handleClick={() => {
                                                                _validatePin()
                                                            }} />
                                                        :
                                                        <PrimaryBtn label="Save"
                                                            className="w-100 mt-4 e-disabled"
                                                            type={2}
                                                        />
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className='row mt-2 pt-2'>
                                            <div className='col-12 position-relative'>
                                                <label className='mb-2'>Enter your MPIN *</label>
                                                <Input mpin={true}
                                                    type={
                                                        updatePinStatus3 ?
                                                            "number"
                                                            :
                                                            "password"
                                                    }
                                                    value={mpin}
                                                    valueChange={(temp) => {
                                                        if (temp.length <= 4) {
                                                            setMpin(temp)
                                                        }
                                                    }}
                                                    placeholder="Enter your MPIN"
                                                    className={`${styles.e_input_bg} e-bg-white`}

                                                />
                                                <img src={
                                                    localStorage.getItem("mode") === "true" ?
                                                        updatePinStatus3 ?
                                                            Show
                                                            :
                                                            Hide
                                                        :
                                                        updatePinStatus3 ?
                                                            ShowWhite
                                                            :
                                                            HideWhite
                                                } alt=""
                                                    className={styles.e_password}
                                                    onClick={() => {
                                                        setUpdatePinStatus3(!updatePinStatus3)
                                                    }} />
                                            </div>

                                            <div className='col-12 mt-3 position-relative'>
                                                <label className='mb-2'>Enter confirm MPIN *</label>
                                                <Input mpin={true}
                                                    type={
                                                        updatePinStatus4 ?
                                                            "number"
                                                            :
                                                            "password"
                                                    }
                                                    value={confirmPin}
                                                    className={`${styles.e_input_bg} e-bg-white`}
                                                    valueChange={(temp) => {
                                                        if (temp.length <= 4) {
                                                            setConfirmPin(temp)
                                                        }
                                                    }}
                                                    placeholder="Confirm MPIN"
                                                />
                                                <img src={
                                                    localStorage.getItem("mode") === "true" ?
                                                        updatePinStatus4 ?
                                                            Show
                                                            :
                                                            Hide
                                                        :
                                                        updatePinStatus4 ?
                                                            ShowWhite
                                                            :
                                                            HideWhite
                                                } alt=""
                                                    className={styles.e_password}
                                                    onClick={() => {
                                                        setUpdatePinStatus4(!updatePinStatus4)
                                                    }} />
                                            </div>
                                            <div className='col-12 pt-3'>
                                                {
                                                    confirmPin && mpin ?
                                                        <PrimaryBtn label="Save"
                                                            className={
                                                                loader ?
                                                                    "e-btn-loader w-100 mt-4"
                                                                    :
                                                                    "w-100 mt-4"
                                                            }
                                                            type={2}
                                                            handleClick={() => {
                                                                _validatePin()
                                                            }} />
                                                        :
                                                        <PrimaryBtn label="Save"
                                                            className="w-100 mt-4 e-disabled"
                                                            type={2}
                                                        />
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                            :
                            <div className='container mt-5'>
                                {
                                    props.update_pin ?
                                        <button
                                            type="button"
                                            className={
                                                localStorage.getItem("mode") === "true" ?
                                                    "btn-close ms-3 d-none"
                                                    :
                                                    "btn-close-dark ms-3 d-none"
                                            }
                                            data-bs-dismiss="modal"
                                            id="e-close"
                                            aria-label="Close" >
                                        </button>
                                        :
                                        null
                                }
                                <img src={

                                    localStorage.getItem("mode") === "false" ? KycWhite : Kyc
                                }
                                    className={`${styles.e_img_low_balance}`}
                                    alt="no data"
                                    draggable={false}
                                    style={{ height: "240px", objectFit: "cover" }}
                                />
                                <div className={`${styles.e_modal_header} modal-header mb-2 d-flex justify-content-center`}>
                                    <h5 className='e-secondary-color'>Congratulations!</h5>
                                </div>
                                {
                                    props.update_pin ?
                                        <p className={`${styles.e_low_balance_text} e-secondary-color`}>
                                            Successfully changed your MPIN
                                        </p>
                                        :
                                        <p className={`${styles.e_low_balance_text} e-secondary-color`}>
                                            MPIN successfully created
                                        </p>
                                }

                                <div className='col-12'>
                                    <div className='col-12' id="modal-close" >
                                        <PrimaryBtn label={
                                            props.update_pin ?
                                                "Back to home"
                                                :
                                                "Go to homepage"
                                        }
                                            className="w-100 mt-1"
                                            type={2}
                                            cancel="modal"
                                            handleClick={() => {
                                                localStorage.setItem("mpin_status", true)
                                                // $("#create-mpin").trigger("click");

                                                if (props.update_pin) {
                                                    window.location.href = "/"
                                                } else {
                                                    if (localStorage.getItem("signup") === "true") {
                                                        props.modalClose()
                                                    } else {
                                                        // $("#e-close").trigger("click")
                                                        // $("#modal-close").attr("data-bs-dismiss", 'modal')
                                                        // $("#create-mpin").trigger("click");
                                                        props.closeLogin()
                                                    }
                                                }
                                            }} />
                                    </div>
                                </div>
                            </div>

                    }
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }

        </div >
    )
}

