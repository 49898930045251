/*import packages*/
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';

/*import assets*/


const Datatable = (props) => {
  const [data, setData] = useState(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])
  // console.log("Hello from Datatable: props.columns: ",props.columns);

  return (
    <>
      <DataTable
        columns={props.columns}
        data={data}
        paginationPerPage={20}
        pagination={props.pagination}
        conditionalRowStyles={props.conditionalRowStyles}
        onRowMouseEnter={(row, index) => {
          if (props.onRowMouseEnter) {
            props.onRowMouseEnter(row, index)
          }
        }}
        onSort={(selectedColumn, sortDirection, sortedRows) => {
          if (props.onSort) {
            props.onSort(selectedColumn, sortDirection, sortedRows)
          }
        }}
        onRowMouseLeave={(row) => {
          if (props.onRowMouseLeave) {
            props.onRowMouseLeave(row)
          }
        }}
      />
    </>
  );
}
export default Datatable;




