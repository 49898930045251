/*   File : sideTab.js
*   Author : https://evoqueinnovativelab.com
*   Description : Side Tab component
*   Integrations : null
*   Version : 1.0.0
*   Created : 25-01-2022
*/

/*import package*/
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*import styles*/
import styles from '../../styles/components/profileTab.module.scss';

const ProfileTab = (props) => {
  const [index, setIndex] = useState(1);
  useEffect(() => {
    // console.log(index)
    if (props.index) {
      setIndex(props.index)

    }
    // props.idx(index)
  }, [props,])
  const navigate = useNavigate()
  return (
    <div>
      {
        props.data.map((item, key) => {
          return (
            <span
              className={
                index === (key + 1) ?
                  `${styles.e_active_tab} me-4 e-secondary-color`
                  :
                  `${styles.e_tab} me-4 e-link-colors`
              }
              onClick={() => {
                setIndex((key + 1));
                props.setTab((key + 1));
                navigate(`/profile?tabIndex=${key + 1} `)
              }}
              key={key}
            >
              {item}
            </span>
          )
        })
      }
    </div>
  )
}

export default ProfileTab;