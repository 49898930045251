/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../../components/formElements/index';
import { PrimaryBtn, LinkText } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/*import assets */
import Hide from '../../assets/images/game-detail/hide.svg';
import HideDark from '../../assets/images/game-detail/hide-dark.svg';
import Show from '../../assets/images/game-detail/view.png'
import ShowDark from '../../assets/images/game-detail/view-dark.svg'
import services from '../../services/service';

// import services from '../../services/service';

export default function EditWatchlistV2r(props) {

    const [rename, setRename] = useState(false);
    const [watchlistName, setWatchlistName] = useState([]);
    //eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [toast, setToast] = useState(null);
    const [activeIds, setActiveIds] = useState([]);
    // const  [];
    // useEffect(() => {
    //     if (props.data && props.data.length > 0) {
    //         let temp_watchlist = [...activeIds];
    //         let w_list = JSON.stringify(props.data);
    //         setWatchlistName(JSON.parse(w_list))
    //         for (let index = 0; index < JSON.parse(w_list).length; index++) {
    //             const element = JSON.parse(w_list)[index];
    //             if (element.active) {
    //                 if (temp_watchlist.indexOf(element.watchlist_id < 0)) {
    //                     temp_watchlist.push(element.watchlist_id);
    //                 }

    //             }
    //         }
    //         setActiveIds(temp_watchlist);
    //     }
    // }, [props.data]);

    useEffect(() => {
        let modal = document.getElementById('editWatchlistV2r')
        modal.addEventListener('shown.bs.modal', function (event) {
            if (props.data && props.data.length > 0) {
                let temp_watchlist = [...activeIds];
                let w_list = JSON.stringify(props.data);
                setWatchlistName(JSON.parse(w_list))
                for (let index = 0; index < JSON.parse(w_list).length; index++) {
                    const element = JSON.parse(w_list)[index];
                    if (element.active) {
                        if (temp_watchlist.indexOf(element.watchlist_id < 0)) {
                            temp_watchlist.push(element.watchlist_id);
                        }

                    }
                }
                setActiveIds(temp_watchlist);
            }
        })
        //eslint-disable-next-line
    }, [props.data]);

    const _updateWatchList = () => {
        setLoader(true);
        let url = 'customer/game/v2r/rename-watchlist';
        let data = JSON.stringify({
            watchlist_id: rename.id,
            watchlist_name: rename.name
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                props.success(rename);
                $("#editWatchlistV2r").trigger("click")
                setRename(null)
                setLoader(false);
            }
            else {
                setToast(response.message);
                setLoader(false);
            }
        })
        setTimeout(() => {
            setToast(null)
        }, 3000)
    }

    /*hide watchlist */
    const _hideWatchList = () => {
        setLoader(true);
        let tmp = [];
        for (let index = 0; index < watchlistName.length; index++) {
            const element = watchlistName[index];
            tmp.push({ watchlist_id: element.watchlist_id, active: element.active, items: element.items })
        }
        let url = 'customer/game/v2r/manage-watchlist';
        let data = {
            watchlist_data: tmp,
            v2r_frequency_id: Number(props.frequency)
        }
        services(true, url, data).then((response) => {

            if (response.status_code === 200) {
                // setSearchList(response.data);
                $('#editWatchlistV2r').trigger('click');
                props.updateList(watchlistName);
                setLoader(false);
            }
            else {
                setToast(response.message)
                setLoader(false);
            }
        })
        setTimeout(() => {
            setToast(null)
        }, 3000)
    }

    return (
        <div className={`${styles.e_edit_modal} modal fade`}
            id="editWatchlistV2r"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    {
                        rename ?
                            <>
                                <div className={`${styles.e_modal_header} modal-header mb-2`}>
                                    <h4>
                                        Rename
                                    </h4>
                                    <button type="button"
                                        className={
                                            localStorage.getItem("mode") === "true" ?
                                                "btn-close"
                                                :
                                                "btn-close-dark"
                                        }
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            setRename(null);

                                        }}>
                                    </button>
                                </div>
                                <div className="container px-3 pt-3">
                                    <div className="row pe-0">
                                        <div className='col-lg-12'>
                                            <Input
                                                searchBox={true}
                                                onSearch={() => {
                                                    if (rename.name) _updateWatchList();
                                                }}
                                                placeholder="Watch list name"
                                                type="text"
                                                value={rename.name}
                                                // defaultValue="Watchlist1"
                                                valueChange={(value) => {
                                                    let temp = rename;
                                                    temp['name'] = value;
                                                    // console.log('rename', temp)
                                                    setRename({ ...temp })
                                                }}
                                                className={`${styles.e_edit_watchlist_input}`}
                                            />

                                        </div>
                                        <div className="row mt-3 pe-0">
                                            <div className="col-lg-12 pe-0">
                                                <div className="d-flex justify-content-end">
                                                    {
                                                        rename.name ?
                                                            <PrimaryBtn label="Rename"
                                                                type={2}
                                                                className={loader ? "e-btn-loader" : ""}
                                                                handleClick={() => _updateWatchList()}
                                                            />
                                                            :
                                                            <PrimaryBtn label="Rename"
                                                                type={2}
                                                                className="e-disabled"
                                                            />

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={`${styles.e_modal_header} modal-header mb-2`}>
                                    <h4>
                                        Edit watchlists
                                    </h4>
                                    <button type="button"
                                        className={
                                            localStorage.getItem("mode") === "true" ?
                                                "btn-close"
                                                :
                                                "btn-close-dark"
                                        }
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            setRename(false)
                                        }}>
                                    </button>
                                </div>
                                <div className="container px-3 pt-3">
                                    <div className="row">
                                        {
                                            watchlistName && watchlistName.map((item, key) => {
                                                return (
                                                    <div className='col-lg-12' key={key}>
                                                        <div className={`${styles.e_edit_watchlist_wraper} mb-3 e-home-card-wrapper e-secondary-color`}>
                                                            <div className='row' key={key}>
                                                                <div className='col-lg-6 col-6'>
                                                                    <span>
                                                                        {item.name}
                                                                    </span>
                                                                </div>
                                                                <div className='col-lg-6 col-6 text-end'>
                                                                    <LinkText
                                                                        label="Rename"
                                                                        className="me-2"
                                                                        handleClick={() => {
                                                                            setRename({
                                                                                name: item.name,
                                                                                id: item.watchlist_id
                                                                            });
                                                                        }}
                                                                    />
                                                                    <img alt=""
                                                                        draggable={false}
                                                                        src={
                                                                            localStorage.getItem("mode") === "true" ?
                                                                                item.active ?
                                                                                    Show
                                                                                    :
                                                                                    Hide
                                                                                :
                                                                                item.active ?
                                                                                    ShowDark
                                                                                    :
                                                                                    HideDark
                                                                        }
                                                                        // className={localStorage.getItem("mode") === "true" ? "btn-close" : "btn-close-dark"}
                                                                        className={
                                                                            activeIds <= 1 && activeIds.indexOf(item.watchlist_id) < 0 ?
                                                                                'e-eye-disabled' :
                                                                                `${styles.e_icon}`
                                                                        }
                                                                        onClick={() => {
                                                                            props.getWatcListIndex(item.watchlist_id)
                                                                            let enabled = activeIds;
                                                                            if (enabled.length <= 1 && item.active === true) {
                                                                                setToast('Minimum 1 watchlist required');
                                                                            } else {
                                                                                let tmp = [...watchlistName];
                                                                                let ids = [...activeIds];
                                                                                if (item.active === true) {

                                                                                    if (ids.indexOf(item.watchlist_id) >= 0) {
                                                                                        ids.splice(ids.indexOf(item.watchlist_id), 1)
                                                                                    }
                                                                                } else {
                                                                                    if (ids.indexOf(item.watchlist_id) < 0) {
                                                                                        ids.push(item.watchlist_id)
                                                                                    }
                                                                                }
                                                                                setActiveIds(ids);
                                                                                tmp[key].active = !item.active;
                                                                                setWatchlistName(tmp);
                                                                            }
                                                                            setTimeout(() => {
                                                                                setToast(null);
                                                                            }, 3000)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="row pe-0">
                                            <div className="col-lg-12 pe-0">
                                                <div className="d-flex justify-content-end">
                                                    <PrimaryBtn
                                                        label="Save"
                                                        type={2}
                                                        className={loader ? "e-btn-loader" : ""}
                                                        handleClick={() => _hideWatchList()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            {
                toast ?
                    <Toast data={toast} type="fail" />
                    :
                    null
            }
        </div>
    )
}

