/*
 *   File : buyV2rStock.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations : 22-03-2022
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../../components/formElements/index';
import { SecondaryBtn, SolidBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/buyStock.module.scss';

/*import assets */
// import services from '../../services/service';


// import Info from '../../assets/images/v2r/info.svg';

export default function SquareOffV2r(props) {

    //eslint-disable-next-line
    const [amount, setAmount] = useState(null);
    //eslint-disable-next-line
    const [typeId, setTypeId] = useState(1);
    const [marketPrice, setMarketPrice] = useState(null);
    const [error, setError] = useState(null);
    //eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    //eslint-disable-next-line
    const [currentPrice, setCurrentPrice] = useState(0)
    //eslint-disable-next-line
    const [change, setChange] = useState(0)
    //eslint-disable-next-line
    const [changePercentage, setChangePercentage] = useState(0)
    //eslint-disable-next-line
    const [unused, setUnUsed] = useState();
    const [stopLoss, setStopLoss] = useState("")


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        var myModalEl = document.getElementById('buyV2rStock')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
            $('#inputRef').focus();
        })
    }, [])

    useEffect(() => {
        if (props.liveData && props.buyData) {
            if ($("#market-price").is(":focus")) {
                setCurrentPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setChange(props.liveData[props.buyData.scrip_code]?.change);
                setChangePercentage(props.liveData[props.buyData?.scrip_code]?.change_percentage);
            } else {
                setCurrentPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setMarketPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setChange(props.liveData[props.buyData.scrip_code]?.change);
                setChangePercentage(props.liveData[props.buyData?.scrip_code]?.change_percentage);
            }
            //I have the focus
        }
        //eslint-disable-next-line
    }, [props.buyData, props.liveData])


    return (
        <div className={`${styles.e_buy_v2r_stock_modal} e-kyc-modal modal fade`}
            id="squareOffV2r"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>

                    <div className={`${styles.e_modal_header} modal-header`}>
                        <h4>Square Off</h4>
                    </div>

                    <div className='row mt-md-0 mt-sm-3 mt-3'>

                        <div className='col-lg-4  col-md-4 col-6 offset-0'>
                            <label className='mb-2'>
                                Stop loss
                            </label>
                            <Input
                                min={1}

                                id="inputRef"
                                placeholder="Stop loss"
                                type="number"
                                value={stopLoss}
                                valueChange={(value) => {
                                    setStopLoss(value);

                                }}

                                className={`${styles.e_input}`}
                            />

                        </div>

                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className=''>
                                <label className='mb-2'>
                                    Target price
                                </label>
                                <Input
                                    id="market-price"
                                    placeholder="Price"
                                    type="number"

                                    value={marketPrice}
                                    valueChange={(value) => {
                                        setMarketPrice(value);

                                    }}
                                    className={
                                        `${styles.e_input}`
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row pt-4 mt-2'>

                        <div className='col-lg-12'>
                            <div className="d-flex justify-content-end">
                                <SecondaryBtn
                                    label="Cancel"
                                    // style={{ padding: "10px 16px" }}
                                    cancel="modal"
                                    className={styles.e_cancel}
                                />
                                {
                                    stopLoss && currentPrice ?
                                        <SolidBtn
                                            label="Update"
                                            color={1}
                                            v2r={true}
                                            cancel="modal"
                                            className={loader ? "e-butn-loader ms-3 e-disabled" : "ms-3"}
                                            handleClick={() => {
                                                // if (localStorage.getItem('v2r_joined')) verifyBuying();
                                                // else joinGame();
                                            }}
                                        />
                                        :
                                        <SolidBtn
                                            label="Update"
                                            color={1}
                                            v2r={true}
                                            cance="modal"
                                            className="e-disabled ms-3"
                                            handleClick={() => {
                                                // if (localStorage.getItem('v2r_joined')) verifyBuying();
                                                // else joinGame();
                                            }}

                                        />
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div >
    )
}

