/*
 *   File : headerDropdown.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header Dropdown menu
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useState } from 'react';

/*import components */
import ToggleSwitch from '../cta/toggle';
// import DarkModeToggle from '../other/mode.js';

/*import styles*/
import styles from '../../styles/components/profile.module.scss';

/*import assets */
import Profile from '../../assets/images/modal/profile.svg'
import Star from '../../assets/images/profile/star.svg'
import Wallet from '../../assets/images/profile/wallet.svg';
import Account from '../../assets/images/profile/bank.svg';
import Booking from '../../assets/images/profile/booking.svg';
import Settings from '../../assets/images/profile/settings.svg';
import Faq from '../../assets/images/profile/faq.svg';
import SignOut from '../../assets/images/profile/signout.svg';
import Rating from '../../assets/images/profile/rate.svg';
import Results from '../../assets/images/profile/result.svg';
import Report from '../../assets/images/profile/report.png';
import Promote from '../../assets/images/profile/promote.png';
import Mode from '../../assets/images/profile/mode.svg';

import KYC from '../../assets/images/modal/kyc.svg';
import { Toast } from '../note';


const ProfileModal = (props) => {
	const [success, setSucess] = useState(null);

	//logout
	const _logOut = () => {
		setSucess("Signout successfull");
		localStorage.removeItem("credential");
		localStorage.removeItem("access_token");
		setTimeout(() => {
			window.location.href = "/";
			sessionStorage.clear();
			localStorage.removeItem("is_welcome");
			localStorage.removeItem("maintenance");
		}, 500);

	}

	return (
		<>
			<div className={`${styles.e_menu_dropdown} dropdown`}>
				<div className={`${styles.e_logout_div} e-profile-dropdown`}>
					<span onClick={() => window.location.href = "/profile"} style={{ cursor: "pointer" }}>
						<div className={`${styles.e_profile_bg} row pe-3`}>
							<div className="col-lg-12 col-12 d-inline-flex">
								{
									props.profile?.profile_image ?
										<div className={`${styles.e_user_img_wrapper} text-center mt-3`}>
											<img src={props.profile?.profile_image}
												className={styles.e_user_img}
												alt=""
												width="38px"
												height="38px"
												draggable={false} />
										</div>
										:
										<img src={Profile}
											className='mt-3'
											alt=""
											width="38px"
											height="38px"
											draggable={false} />
								}
								<span className={`${styles.e_span} ps-2`}>
									<h4 className='pt-3'>
										{props.profile.name}
									</h4>
									<p className={`${styles.e_location}`}>
										{props.profile.customer_id}
									</p>
								</span>
							</div>
						</div>
						<p className={`${styles.e_rating}`}>
							<img src={Rating}
								className='pe-2'
								alt=""
								draggable={false}
							/>
							{
								props.profile.rating ?
									props.profile.rating
									:
									1
							}/5 stars</p>
					</span>
					{/* <a href='#'>
						<div className={`${styles.e_menu} d-flex mt-4`}>
							<span className='pe-2 pt-0 pb-0'>
								<img src={Mode} alt="" />
							</span>
							<span className=' ps-0'>Dark mode</span>
							<span className='ps-2'>
								<ToggleSwitch
									className="mx-3"
									onChange={(e) => props.Check(e)}
								/>
								<span className={`${styles.e_toggle_label}`}>ON</span>
							</span>
						</div>
					</a> */}
					<div className={styles.e_menu_links_wrap}>

						<div className={
							localStorage.getItem("mode") === "true" ?
								`${styles.e_menu} ${styles.e_menu_active} d-flex mt-4`
								:
								`${styles.e_menu} d-flex mt-4`
						}>
							<span className='pe-2 pt-0 pb-0'>
								<img src={Mode}
									alt=""
									width="20px"
									height="21px"
									draggable={false} />
							</span>
							<span className=' ps-0'>
								Dark mode
							</span>
							<span className='ps-0'>

								<ToggleSwitch
									className="e-mode-toggle"
									check={localStorage.getItem("mode") === "true" ? true : false}
									onChange={(e) => {
										props.change(e);
									}}
								/>
								<span>
									{
										localStorage.getItem("mode") === "true" ?
											"ON"
											:
											"OFF"
									}
								</span>


							</span>
						</div>
						<a href='/wallet'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Wallet}
										alt=""
										width="20px"
										height="21px"
										draggable={false} />
								</span>
								<span className=' ps-0'>
									Wallet
								</span>
							</div>
						</a>
						<div
							onClick={() => {
								window.location.href = "/profile?tabIndex=4"
							}}
							className={`${styles.e_link}`}>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Account}
										alt=""
										width="20px"
										height="21px"
										draggable={false}
									/>
								</span>
								<span className=' ps-0'>
									Bank accounts
								</span>

							</div>
						</div>
						<a href='/bookings'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Booking}
										alt=""
										width="20px"
										height="21px"
										draggable={false}
									/>
								</span>
								<span className=' ps-0'>Bookings</span>

							</div>
						</a>
						<a href='/results'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Results}
										alt="results"
										width="20px"
										height="21px"
										draggable={false} />
								</span>
								<span className=' ps-0'>
									Results
								</span>

							</div>
						</a>
						<a href='/profile?tabIndex=3'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Report} alt="" width="20px"
										height="18px" draggable={false} />
								</span>
								<span className=' ps-0'>
									Reports
								</span>

							</div>
						</a>
						<a href='/results-history'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Results}
										alt="results"
										width="20px"
										height="21px"
										draggable={false} />
								</span>
								<span className=' ps-0'>
									Result reports
								</span>

							</div>
						</a>
						<a href='/profile?tabIndex=2'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Star}
										alt=""
										width="20px"
										height="18px"
										draggable={false}
									/>
								</span>
								<span className=' ps-0'>
									Game dashboard
								</span>

							</div>
						</a>
						<div onClick={() => { window.location.href = "/profile" }} className={`${styles.e_link}`}>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={KYC}
										alt=""
										width="20px"
										height="21px"
										draggable={false}
									/>
								</span>
								<span className=' ps-0'>
									Kyc details
								</span>

							</div>
						</div>
						<a href='/profile?tabIndex=5'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Promote}
										className={`${styles.e_refer_icon}`}
										alt=""
										draggable={false}
										width="20px"
										height="21px" />
								</span>
								<span className=' ps-0'>Refer & earn</span>

							</div>
						</a>
						<a href='/profile'>
							<div className={`${styles.e_menu} d-flex mt-2`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Settings}
										alt=""
										width="20px"
										height="21px"
										draggable={false}
									/>
								</span>
								<span className=' ps-0'>
									Profile & settings
								</span>

							</div>
						</a>
						<a href='/faq'>
							<div className={`${styles.e_menu} d-flex mt-2 mb-3`}>
								<span className='pe-2 pt-0 pb-0'>
									<img src={Faq}
										alt=""
										width="20px"
										height="21px"
										draggable={false}
									/>
								</span>
								<span className=' ps-0'>
									FAQs
								</span>
							</div>
						</a>

						<p className={`${styles.e_border}`}></p>
						<div className={`${styles.e_menu} d-flex`}
							onClick={() => {
								_logOut()
							}}
							style={{ cursor: "pointer" }}>
							<span className='pe-2 pt-0 pb-0'>
								<img src={SignOut}
									alt=""
									width="20px"
									height="21px"
									draggable={false}
								/>
							</span>
							<span className=' ps-0'>
								Sign out
							</span>

						</div>

					</div>
				</div>
			</div>

			{/* Success toast */}
			{
				success !== null ?
					<Toast
						type="success"
						data={success}
					/>
					:
					null
			}

		</>
	)
}
export default ProfileModal;

