import { takeLatest, put, delay } from "redux-saga/effects";



function* _walletBalance(data) {
    yield delay(1000); // delay for 1 second
    console.log(data)
    try {

        yield put({
            type: 'WALLET_BALANCE',
            payload: data.payload
        });
    } catch (err) {
        yield put({
            type: 'WALLET_BALANCE',
            payload: 0
        });
    }
}


export function* _getBalance() {
    yield takeLatest('WALLET_BALANCE', _walletBalance);
}
