/*
 *   File : customizeTable.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Customize Table
 *   Integrations :
 *   Version : v1.0
 *   Created : 08-03-2022
 */

/*import packages*/
import React from 'react';
import $ from 'jquery';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/*import componets*/
import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';
import Loader from '../loader/loader';
/*import styles*/
import styles from '../../styles/components/customizeTable.module.scss';

/*import assets */
import Hide from '../../assets/images/game-detail/hide.svg';
import Show from '../../assets/images/game-detail/view.png';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	cursor: 'grab !important',
	userSelect: "none",
	padding: '10px 15px',
	margin: `0 0 ${grid}px 0`,
	borderRadius: '4px',

	// change background colour if dragging
	background: localStorage.getItem("mode") === "true" ? isDragging ? "#4e07bb" : "#1F1136" : isDragging ? "#4e07bb" : "#322E67",
	color: '#fff',
	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
	borderRadius: '6px',
	background: localStorage.getItem("mode") === "true" ? isDraggingOver ? "#524363" : "#524363" : isDraggingOver ? "none" : "none",
	padding: '8px 8px 1px 8px',
	width: '100%',
	boxShadow: localStorage.getItem("mode") === "true" ? `0 2px 24px rgb(0 0 0 / 16 %)` : "none"

});
class AddTopic extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			topic: "",
			error: "",
			// topicData: [{ topic: "Prev. close", topic_id: 0 }, { topic: "Bid", topic_id: 1 }, { topic: "Ask", topic_id: 2 }, { topic: "LTP", topic_id: 3 }, { topic: "Change%", topic_id: 4 }, { topic: "Open", topic_id: 5 }, { topic: "High", topic_id: 6 }, { topic: "Low", topic_id: 7 }],
			topicData: null,
			status: "",
			enabled: [],
			loader: true,
			// items: getItems(10)
		};
		this.onDragEnd = this.onDragEnd.bind(this);

	}
	componentDidMount() {
		var myModalEl = document.getElementById('customizetable')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			this.setState({ topicData: null, enabled: [], loader: true })
		})
		myModalEl.addEventListener('shown.bs.modal', (event) => {
			this.setState({ loader: true })
			let Data = JSON.stringify(this.props.data);
			let arr = [];
			let enableData = [];
			for (let index = 0; index < JSON.parse(Data).length; index++) {
				const element = JSON.parse(Data)[index];
				element['newIndex'] = index;
				arr.push(element);
				if (element.show) enableData.push(element.id);
			}
			this.setState({ topicData: arr, enabled: enableData });
			setTimeout(() => {
				this.setState({ loader: false });
			}, 1000)
		})
	}

	async reorder(list, startIndex, endIndex) {
		const result = Array.from(list);
		// let arr = [];
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		for (let i = 0; i < result.length; i++) {
			let newele = result[i].newIndex = i
			// arr.push(result[i]['newIndex'])
			console.log(newele);
		}

		this.setState({
			topicData: result
		});

		return result;
	};

	async onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {

			return;
		}
		this.reorder(
			this.state.topicData,
			result.source.index,
			result.destination.index
		);
		// console.log('topic'. topicData);

	}
	render() {
		const getClass = (id) => {
			if (this.state.enabled.length >= 8 && this.state.enabled.indexOf(id) < 0) {
				return 'e-eye-disabled';
			}
			return 'e-eye';
		}
		return (
			<div className={`${styles.e_customize_modal} modal fade e-game-detail-modal`}
				id="customizetable"
				tabIndex="-1"
				aria-labelledby="success"
				aria-hidden="true">
				<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
					<div className={`${styles.e_modal_content} modal-content`}>
						<div className={`${styles.e_modal_header} modal-header mb-2`}>
							<h4 className='e-secondary-color'>
								Customize table
							</h4>
							<button
								type="button"
								className={
									localStorage.getItem("mode") === "true" ?
										"btn-close"
										:
										"btn-close-dark"
								}
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() => {
									this.setState({ topic: "" });
								}}>
							</button>
						</div>

						<div className={`container ${styles.e_content}`}>
							{
								this.state.loader ?
									<Loader
										height="50vh"
									/>
									:
									<>
										<div className="row pt-3">
											<div className="col-lg-6 col-6">
												<label>
													Contents
												</label>
											</div>
											<div className="col-lg-6 col-6 text-end">
												<label>
													Show/hide
												</label>
											</div>

										</div>
										<div className="row pt-3">
											<div className='col-lg-12'>
												<DragDropContext onDragEnd={this.onDragEnd}>
													<Droppable droppableId="droppable">
														{(provided, snapshot) => (
															<div
																{...provided.droppableProps}
																ref={provided.innerRef}
																style={getListStyle(snapshot.isDraggingOver)}
															>
																{this.state.topicData?.map((item, index) => {

																	return (
																		<Draggable
																			key={`${item.id}-index`}
																			draggableId={`${item.id}-index`}
																			index={Number(index)}
																		>
																			{(provided, snapshot) => (
																				<div
																					className="e-topics d-flex justify-content-between"
																					ref={provided.innerRef}
																					{...provided.draggableProps}
																					{...provided.dragHandleProps}
																					style={getItemStyle(
																						snapshot.isDragging,
																						provided.draggableProps.style
																					)}
																				>
																					<span className={`ps-3  mt-1`}>{item.name}</span>
																					<span className={`${styles.e_icon_wrapper}`}
																						onClick={() => {
																							let checkClickable = getClass(item.id);
																							if (checkClickable === 'e-eye') {
																								let enableIds = this.state.enabled;
																								let tempArry = this.state.topicData;
																								if (!item.show === true) {
																									let Index = enableIds.indexOf(item.id);
																									if (Index < 0) enableIds.push(item.id);
																								} else {
																									let Index = enableIds.indexOf(item.id);
																									if (Index >= 0) enableIds.splice(Index, 1);
																								}
																								tempArry[index].show = !item.show;
																								this.setState({ topicData: tempArry, enabled: enableIds });
																							} else {
																								this.setState({ toast: 'Can show only 8 columns in table' });
																								setTimeout(() => {
																									this.setState({ toast: null });
																								}, 3000)
																							}

																						}}
																					>

																						<img
																							alt=""
																							className={getClass(item.id)}
																							src={
																								// localStorage.getItem("mode") === "true" ?
																								item.show ?
																									Show
																									:
																									Hide
																								// :
																								// item.show ?
																								// 	ShowWhite
																								// 	:
																								// 	HideWhite
																							} width="18px" height="18px"
																						/>
																					</span>
																				</div>
																			)}
																		</Draggable>
																	)
																})}
																{provided.placeholder}

															</div>
														)}
													</Droppable>
												</DragDropContext>
											</div>
										</div>
										<div className='row pt-4'>
											<div className='col-lg-12'>
												<div className="d-flex justify-content-end">
													<PrimaryBtn
														label="Save preferences"
														type={2}
														className=""
														handleClick={() => {
															$('#customizetable').trigger('click');
															this.props.updateTable(this.state.topicData);
														}}
													/>
												</div>
											</div>
										</div>
									</>
							}
						</div>
					</div>
				</div>
				{
					this.state.toast ?
						<Toast
							data={this.state.toast}
							type="fail"
						/>
						:
						null
				}
			</div >
		);
	}
}

export default AddTopic;