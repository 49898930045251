// Package import
import React from 'react';

/*import styles*/
import styles from '../../styles/components/blockedModal.module.scss';

// asset import

// component import
import { PrimaryBtn } from '../cta';


const Maintenance = React.memo(function Maintenance() {
    return (
        <div className={`${styles.e_preference_modal} e-kyc-modal modal fade`}
            id="maintenance"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content p-2`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2 d-flex justify-content-end`}>
                        <button type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                localStorage.setItem("maintenance", true)
                            }} >
                        </button>
                    </div>
                    <div className="container">
                        <div className='row'>
                            <div className='col-lg-12 text-center p-4'>
                                {/* <img src={Illustration} width="200px" /> */}
                                <h5 className="pb-4 "
                                    style={
                                        localStorage.getItem("mode") === "true" ?
                                            { color: 'white' }
                                            :
                                            { color: '#1f1136' }
                                    }>
                                    Welcome bonus has ended, You can continue to start learning about the financial market.<br />
                                </h5>

                                <span className="pb-6 p-4" style={localStorage.getItem("mode") === "true" ? { color: 'white' } : { color: '#1f1136' }}>
                                    Thanks for all the interested users, We’ve seen an overwhelming response to the launch offer and we are very delighted with your interest. <br /><br />

                                    Please note: We launched this learning platform to give awareness to the general public who want to enter into financial market, we purposely launched for educating and  promoting  stock Trading in our country and not for any short term profit seekers or speculators.
                                    <br /><br />As you must be aware, the offer was for initial 15,000 sign-ups and hence it’s no more available.
                                    <hr />
                                    प्रिय ग्राहक,<br />
                                    हमारे एपीपी में रुचि दिखाने के लिए धन्यवाद; यह आपके ध्यान में लाना है कि वेलकम कम बोनस ऑफर केवल शुरुआती 15,000 साइन-अप के लिए था, और हमने उन साइन-अप की संख्या को पार कर लिया है; इसलिए बोनस राशि अब उपलब्ध नहीं है।
                                    <br /><br />
                                    हमने वित्तीय बाजार में प्रवेश करने के इच्छुक आम जनता को जागरूकता देने के लिए इस लर्निंग प्लेटफॉर्म को लॉन्च किया है। हमने जानबूझकर अपने देश में स्टॉक ट्रेडिंग को शिक्षित करने और बढ़ावा देने के लिए पाया, न कि किसी अल्पकालिक लाभ चाहने वाले या सट्टेबाजों के लिए।
                                    <br /><br />
                                    <PrimaryBtn
                                        label="Okay, Got it"
                                        type={2}
                                        handleClick={() => {
                                            // var myModal = document.getElementById('maintenance');
                                            // myModal.modal('hide');
                                            // var modalInstance = new Modal(document.getElementById('maintenance'));
                                            // var modal = document.getElementById('maintenance'); 
                                            // modalInstance.hide(modal);
                                            // modalInstance.hide()
                                            localStorage.setItem("maintenance", true)
                                        }}
                                        cancel={"modal"}
                                        className={`${styles.e_modal_submit} e-modal-submit e-save-btn`}
                                    />
                                    <br />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Maintenance;