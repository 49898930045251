/*
 *   File : game-detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Game Detail Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import styles*/
import styles from '../../styles/components/game-result.module.scss';

/*import assets */
import Result from '../../assets/images/modal/result-modal.png';
import { Datatable, Tab } from '../dataFormatting';



export default function V2rResult(props) {

    const [tabIndex, setTabindex] = useState(1);
    const [resultData, setResultData] = useState({});
    //eslint-disable-next-line
    const [myBooking, setMyBooking] = useState([]);

    useEffect(() => {
        // console.log("props.data => ", props.data)
        if (props.data && Object.keys(props.data).length > 0) {
            setResultData(props.data);
        }
    }, [props.data])

    useEffect(() => {
        var myModalEl = document.getElementById('v2r-result')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
        })
    }, [props.data])

    useEffect(() => {
        var myModalEl = document.getElementById('v2r-result')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setMyBooking([]);
            setTabindex(1);
        })
    }, [props.data])


    const _getClass = (temp) => {
        if (temp !== undefined) {
            let sp = temp;
            if (parseFloat(sp) < 0) {
                return 'e-red';
            } else if (parseFloat(sp) > 0) {
                return 'e-green';
            } else {
                return 'e-secondary-color'
            }
        }
        // return 'e-green';
    }

    const RESULTS_COLUMNS = [
        {
            name: 'Rank',
            sortable: false,
            left: true,
            cell: (row) => <div >{row.rank ? row.rank : '-'}</div>,
            maxWidth: '120px'
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            left: true,
            cell: (row) => <div  >{row.name}</div>,
            minWidth: '220px'
        },
        {
            name: 'P&L',
            selector: row => row.pandl,
            sortable: true,
            left: true,
            maxWidth: "120px",
            cell: (row) => <div >{row.pandl}</div>
        },
        {
            name: 'P&L%',
            selector: row => row.pandl_percentage,
            sortable: true,
            left: true,
            maxWidth: "120px",
            cell: (row) => <div >{row.pandl_percentage}</div>
        },

        {
            name: 'Reward',
            selector: row => row.reward !== '-' ? parseFloat(row.reward) : 0,
            sortable: true,
            left: true,
            cell: (row) => <div>₹ {row.reward}</div>
        },
        {
            name: 'Orders',
            selector: row => row.total_orders,
            sortable: true,
            left: true,
            maxWidth: "80px",
            cell: (row) => <div >{row.total_orders}</div>
        },
        {
            name: 'Reward for 0.001%',
            selector: row => row.each_percentage_reward !== '-' ? parseFloat(row.each_percentage_reward) : 0,
            sortable: true,
            left: true,
            cell: (row) => <div >₹ {row.each_percentage_reward}</div>
        },
    ]


    return (
        <div className={`${styles.e_v2r_game_result_modal} e-game-result-modal modal fade e-game-detail-modal`}
            id="v2r-result"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            {/* { console.log("resultData", resultData)} */}
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <h4 className='ps-0'>
                            Game results V2R {resultData && resultData.frequency_name}
                        </h4>
                        <button
                            type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            onClick={() => props.close()}
                            aria-label="Close">
                        </button>
                    </div>
                    <div className="container-fluid e-sm-margin pt-3 px-3">
                        <div className="row">
                            <div className="col-lg-12 justify-content-center d-flex">
                                <img src={Result} alt="" height="165px" draggable={false} />
                            </div>
                            <p className="text-center pt-2">
                                Woohooo..! Results are out.Check your score and details
                            </p>
                        </div>
                        <div className="container">
                            <div className={`${styles.e_result_table} row justify-content-between mt-4 mx-auto e-home-card-wrapper`}>
                                <div className="col   text-center">
                                    <p className="mb-2">Game ID</p>
                                    <h6 className='e-secondary-color'>{resultData && resultData.game_id}</h6>

                                </div>
                                <div className="col  text-center">
                                    <p className="mb-2">Date</p>
                                    <h6 className='e-secondary-color'>{resultData && resultData.date} </h6>

                                </div>
                                <div className="col  text-center">
                                    <p className="mb-2">Time</p>
                                    <h6 className='e-secondary-color'>{resultData && resultData.time}</h6>

                                </div>
                                <div className="col  text-center">
                                    <p className="mb-2"> P/L</p>
                                    <h6>
                                        {
                                            resultData && resultData.pandl !== '-' ?
                                                <span className={_getClass(resultData.pandl)}>
                                                    ₹ {resultData.pandl}
                                                </span>
                                                :
                                                "-"
                                        }
                                    </h6>

                                </div>
                                <div className="col  text-center">
                                    <p className="mb-2"> P/L %</p>
                                    <h6>
                                        {
                                            resultData && resultData.pandl_percentage !== '-' ?
                                                <span className={_getClass(resultData.pandl_percentage)}>
                                                    {resultData.pandl_percentage}
                                                </span>
                                                :
                                                "-"
                                        }
                                    </h6>

                                </div>
                                <div className="col  text-center">
                                    <p className="mb-2">Orders</p>
                                    <h6 className='e-secondary-color'>{resultData ? resultData.orders : 0}</h6>

                                </div>
                                <div className="col  text-center">
                                    <p className="mb-2"> Platform fee</p>
                                    <h6 className='e-secondary-color'>₹ {resultData ? resultData.platform_fee : 0}</h6>

                                </div>

                                <div className="col  text-center">
                                    <p className="mb-2"> Reward</p>
                                    <h6 className='e-secondary-color'>₹ {resultData ? resultData.reward : 0}</h6>

                                </div>

                                {/* <div className="col-lg-2 px-0 text-center">
									<h6>{resultData?.game_details?.m2e_rank}</h6>
									<p>M2E Rank</p>
								</div> */}
                                {/* <div className="col-lg-2 px-0 text-center">
									<h6>{resultData?.game_details?.global_rank}</h6>
									<p>Global Rank</p>
								</div> */}

                            </div>
                        </div>
                        <div className="row my-4 py-2 ">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-between">
                                    <Tab
                                        className={styles.e_game_result_modal_tab}
                                        inactiveClass={styles.e_inactive}
                                        tabIndex={tabIndex}
                                        data={['Results']}
                                        setTab={(temp) => setTabindex(temp)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            {
                                <div className="col-lg-12 e-result-table ">
                                    {
                                        tabIndex === 1 && resultData ?
                                            <div className={`mb-3 ${styles.e_total_qty} e-table-skillrace-result e-table-bg e-border-bottom`} style={{ fontSize: "14px" }}>
                                                <div className='row p-3 e-border-bottom gx-0 e-table-header' style={{ borderBottom: "solid 1px #2B213A", fontWeight: 600 }}>
                                                    <div className='col-2'>
                                                        Total participants
                                                    </div>
                                                    <div className='col-2'>
                                                        Total orders
                                                    </div>
                                                    <div className='col-3'>
                                                        Participants with +ve returns
                                                    </div>
                                                    <div className='col-3'>
                                                        Participants with -ve returns
                                                    </div>
                                                    <div className='col-2'>
                                                        Reward for 0.001 %
                                                    </div>
                                                </div>
                                                <div className='row p-3 e-table-body' style={{ fontWeight: 400 }}>
                                                    {
                                                        resultData && resultData.general_results_info && resultData.general_results_info.map((item) => {
                                                            return (
                                                                <>
                                                                    <div className='col-2 e-secondary-color'>
                                                                        {item.total_participants}
                                                                    </div>
                                                                    <div className='col-2 e-secondary-color'>
                                                                        {item.total_orders}
                                                                    </div>
                                                                    <div className='col-3 e-secondary-color'>
                                                                        {item.people_with_positive_returns}
                                                                    </div>
                                                                    <div className='col-3 e-secondary-color'>
                                                                        {item.people_with_negative_returns}
                                                                    </div>
                                                                    <div className='col-2 e-secondary-color'>
                                                                        ₹ {item.each_percentage_reward}
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                    {/* {
                                        renderTableBody() && !renderTableBody().length ?
                                            <EmptyScreen note={tabIndex === 1 ? "" : "You don't have any winning scrips"} />
                                            :
                                            <div className='e-16rounded-table'>
                                                <Datatable
                                                    pagination={false}
                                                    columns={renderColumns()}
                                                    data={renderTableBody()}
                                                />
                                            </div>
                                    } */}


                                </div>
                            }
                        </div>
                        <div className="row mt-3">
                            {
                                <div className="col-lg-12 e-result-table ">
                                    {
                                        tabIndex === 1 && resultData && resultData.top_ten_players && resultData.top_ten_players.length > 0 ?
                                            <div className='e-16rounded-table'>
                                                <Datatable
                                                    pagination={false}
                                                    columns={RESULTS_COLUMNS}
                                                    data={resultData.top_ten_players}
                                                />
                                            </div>
                                            :
                                            null

                                    }
                                    {/* {
                                        renderTableBody() && !renderTableBody().length ?
                                            <EmptyScreen note={tabIndex === 1 ? "" : "You don't have any winning scrips"} />
                                            :
                                            <div className='e-16rounded-table'>
                                                <Datatable
                                                    pagination={false}
                                                    columns={renderColumns()}
                                                    data={renderTableBody()}
                                                />
                                            </div>
                                    } */}


                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

