/*
 *   File : headerDropdown.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Header Dropdown menu
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React from 'react';
import { useState } from 'react';
/*import components */
import ToggleSwitch from '../cta/toggle';

/*import styles*/
import styles from '../../styles/components/profile.module.scss';

/*import assets */
import Profile from '../../assets/images/modal/profile.svg'
import Home from '../../assets/images/profile/dashboard.svg'
import Wallet from '../../assets/images/profile/wallet.svg';
import Mode from '../../assets/images/profile/mode.svg';
import Account from '../../assets/images/profile/bank.svg';
import Booking from '../../assets/images/profile/booking.svg';
import Settings from '../../assets/images/profile/settings.svg';
import Faq from '../../assets/images/profile/faq.svg';
import SignOut from '../../assets/images/profile/signout.svg';
import Location from '../../assets/images/profile/location.svg';
import Rating from '../../assets/images/profile/rate.svg';
import Results from '../../assets/images/profile/result.svg';
import Report from '../../assets/images/profile/report.png';
import Promote from '../../assets/images/profile/promote.svg';
import Star from '../../assets/images/profile/star.svg'
import KYC from '../../assets/images/modal/kyc.svg';

/* import components */
import { Toast } from '../note';
import { Modal } from 'bootstrap';
import { Store } from '../../store';

const ProfileMobModal = (props) => {
    const [success, setSucess] = useState(null);

    //logout
    const _logOut = () => {
        setSucess("Signout successfull");
        localStorage.removeItem("credential");
        localStorage.removeItem("access_token");
        Store.dispatch({ type: "LOGIN_USER", payload: null });
        setTimeout(() => {
            window.location.href = "/";
            sessionStorage.clear();
            localStorage.removeItem("is_welcome");
            localStorage.removeItem("maintenance");
        }, 500);
    }

    const _confirm = (check) => {
        var modal = new Modal(document.getElementById("exitConfirm"), {});
        modal.show()
    }

    return (
        <>
            <div className={`${styles.e_menu_drop_down_menu} e-menu-dropdown`}>
                <div className="">
                    <span onClick={() => window.location.href = "/profile"} style={{ cursor: "pointer" }}>
                        <div className={`${styles.e_profile_bg} row pe-3`}>
                            <div className="col-lg-12 col-12 d-inline-flex">
                                {
                                    props.profile?.profile_image ?
                                        <div className={`${styles.e_user_img_wrapper} text-center mt-3`}>
                                            <img src={props.profile?.profile_image}
                                                className={styles.e_user_img}
                                                alt=""
                                                draggable={false}
                                            />
                                        </div>
                                        :
                                        <img src={Profile}
                                            className='mt-3'
                                            alt=""
                                            draggable={false}
                                        />
                                }
                                <span className={`${styles.e_span} ps-2`}>
                                    <h4 className='pt-3 e-secondary-color'>{props.profile.name}</h4>
                                    <p className={`${styles.e_location}`}>
                                        <img src={Location}
                                            alt=""
                                            draggable={false}
                                        />
                                        {props.profile?.state}
                                    </p>
                                </span>
                            </div>
                        </div>
                        <p className={`${styles.e_rating}`}>
                            <img src={Rating}
                                className='pe-2'
                                alt=""
                                draggable={false}
                            />
                            {props.profile.rating ? props.profile.rating : 1}/5 stars
                        </p>
                    </span>
                    <div className={
                        localStorage.getItem("mode") === "true" ?
                            `${styles.e_menu} ${styles.e_menu_active} d-flex mt-4`
                            :
                            `${styles.e_menu} d-flex mt-4`}>
                        <span className='e-secondary-color' >Demo</span>
                        <span className='ps-2 e-secondary-color'>
                            <ToggleSwitch
                                // className="ms-2"
                                id="demoToggle"
                                // check={check}
                                check={localStorage.getItem("demo") === "true" ? true : false}
                                onChange={(e) => {
                                    props.getCheck(e.target.checked)
                                    _confirm()
                                    // setTimeout(() => {
                                    //   window.location.reload()
                                    // }, 500)
                                }}
                            />
                            <span className='ps-3 mt-1 e-secondary-color'>{localStorage.getItem("demo") === "true" ? "On" : "Off"}</span>
                        </span>
                    </div>
                    <div className={
                        localStorage.getItem("mode") === "true" ?
                            `${styles.e_menu} ${styles.e_menu_active} d-flex mt-3`
                            :
                            `${styles.e_menu} d-flex mt-4`
                    }>
                        <span className='pe-2 pt-0 pb-0'>
                            <img src={Mode}
                                alt=""
                                draggable={false}
                            />
                        </span>
                        <span className=' ps-0 e-secondary-color'>
                            Dark mode
                        </span>
                        <span className='ps-2'>

                            <ToggleSwitch
                                className="e-mode-toggle"
                                check={
                                    localStorage.getItem("mode") === "true" ?
                                        true
                                        :
                                        false
                                }
                                onChange={(e) => {
                                    props.change(e);
                                }}
                            />
                            <span className='e-secondary-color'>
                                {localStorage.getItem("mode") === "true" ? "ON" : "OFF"}
                            </span>


                        </span>
                    </div>


                    <a href='/'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Home} alt="" draggable={false} />
                            </span>
                            <span className=' ps-0 e-secondary-color'>Home</span>
                        </div>
                    </a>
                    <a href='/wallet'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Wallet} alt="" draggable={false} />
                            </span>
                            <span className=' ps-0 e-secondary-color'>Wallet</span>
                        </div>
                    </a>
                    {/* <a href='#'>
            <div className={`${styles.e_menu} d-flex mt-2`} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} >
              <span className='pe-2 pt-0 pb-0'>
                <img src={Wallet} alt="" />
              </span>
              <span className=' ps-0'>Games</span>
              {
                isShown ?
                  <div className={`${styles.e_dropdown_game} game-dropdown`}>
                    <div className={`${styles.e_game_div} `}>
                      <a href='/'>
                        <div className={`${styles.e_menu_div} d-flex mb-3`}>
                          <span className=''>M2E</span>
                        </div>
                      </a>
                      <a href='/'>
                        <div className={`${styles.e_menu_div} d-flex mb-3`}>
                          <span className=''>M2M</span>
                        </div>
                      </a>
                      <a href='/'>
                        <div className={`${styles.e_menu_div} d-flex`}>
                          <span className=''>Skill race</span>
                        </div>
                      </a>
                    </div>

                  </div>
                  :
                  null
              }
            </div>
          </a> */}
                    {/* <a href='/scrips'>
            <div className={`${styles.e_menu} d-flex mt-2`}>
              <span className='pe-2 pt-0 pb-0'>
                <img src={Wallet} alt="" />
              </span>
              <span className=' ps-0'>Scrips</span>
            </div>
          </a> */}
                    <div onClick={() => { window.location.href = "/profile?tabIndex=4" }} className={`${styles.e_link}`}>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Account}
                                    alt=""
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Bank accounts
                            </span>

                        </div>
                    </div>
                    <a href='/bookings'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Booking}
                                    alt=""
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Bookings
                            </span>

                        </div>
                    </a>
                    <a href='/results'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Results}
                                    alt="results"
                                    width="20px"
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Results
                            </span>

                        </div>
                    </a>
                    <a href='/profile?tabIndex=3'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Report}
                                    alt=""
                                    height="18px"
                                    draggable={false} />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Reports
                            </span>
                        </div>
                    </a>
                    <a href='/results-history'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Results}
                                    alt="results"
                                    width="20px"
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Result reports
                            </span>

                        </div>
                    </a>
                    <a href='/profile?tabIndex=2'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Star}
                                    alt=""
                                    height="18px"
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Game dashboard
                            </span>

                        </div>
                    </a>
                    <div onClick={() => { window.location.href = "/profile" }} className={`${styles.e_link}`}>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={KYC}
                                    alt=""
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Kyc details
                            </span>

                        </div>
                    </div>
                    <a href='/profile?tabIndex=5'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Promote}
                                    className={`${styles.e_refer_icon}`}
                                    alt=""
                                    draggable={false} />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Refer & earn
                            </span>

                        </div>
                    </a>
                    <a href='/profile'>
                        <div className={`${styles.e_menu} d-flex mt-2`}>
                            <span className='pe-2 pt-0 pb-0'>
                                <img src={Settings}
                                    alt=""
                                    draggable={false}
                                />
                            </span>
                            <span className='e-secondary-color ps-0'>
                                Profile & settings
                            </span>

                        </div>
                    </a>

                    <div className={`${styles.e_menu} d-flex mt-2 mb-3`}>
                        <span className='pe-2 pt-0 pb-0'>
                            <img src={Faq}
                                alt=""
                                draggable={false}
                            />
                        </span>
                        <span className='e-secondary-color ps-0'>
                            FAQs
                        </span>
                    </div>
                    <p className={`${styles.e_border}`}></p>
                    <div className={`${styles.e_menu} d-flex`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            _logOut()
                        }}>
                        <span className='pe-2 pt-0 pb-0'>
                            <img src={SignOut}
                                alt=""
                                draggable={false} />
                        </span>
                        <span className='e-secondary-color ps-0'>
                            Sign out
                        </span>
                    </div>
                </div>
            </div>


            {/* Success toast */}
            {
                success !== null ?
                    <Toast
                        type="success"
                        data={success}
                    />
                    :
                    null
            }

        </>
    )
}
export default ProfileMobModal;

