/*
 *   File : primary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Primary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import style*/
import styles from '../../styles/components/primary.module.scss';

// Asset import 
import LoaderGif from '../../assets/images/loader/btn_loader_1.gif';

const PrimaryBtn = (props) => {

	return (
		<button
			id={props.id}
			type={props.type ? props.type : "submit"}
			onClick={props.handleClick}
			className={
				props.type === 1 ?
					`${styles.e_p_btn} 
				${styles.e_p_full_width_btn} 
				${props.className}`
					:
					props.type === 2 ?
						`${styles.e_p_btn} 
				${styles.e_p_padding_btn} 
				${props.className}`
						:
						`${styles.e_p_btn} 
				${styles.e_p_small_btn} 
				${props.className}`
			}
			icon={props.icon}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			disabled={props.disabled}
		>
			{
				props.loader ?
					<img src={LoaderGif}
						alt=""
						className={`${styles.e_loader_img}`}
						draggable={false}
					/>
					:
					<span>
						{props.label}
						{
							props.image ?
								<img src={props.image}
									alt=""
									draggable={false}
								/>
								:
								null
						}
					</span>
			}
		</button>
	);
}

export default PrimaryBtn;