/*
 *   File : buyV2rStock.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations : 22-03-2022
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../../components/formElements/index';
import { SecondaryBtn, SolidBtn } from '../../components/cta/index';
import RadioButton from '../../components/formElements/radio';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/buyStock.module.scss';

/*import assets */
// import services from '../../services/service';
import SwitchV2r from '../cta/switch-v2r';

// import Info from '../../assets/images/v2r/info.svg';
import services from '../../services/service';


const TYPE_OPTIONS = [
    { id: 1, label: "Market" },
    { id: 2, label: "Limit" }
]

const STOPLOSS_OPTIONS = [
    { id: 5, label: "SL" },
    { id: 6, label: "SL-M" }
]

const MARKET_OPTIONS = [
    { id: 3, label: "Intraday ", another: "MIS" },
    { id: 4, label: "Longterm", another: "CNC" }
]

let MARKET_PRICE = 0;
const BuyV2rStock = React.memo(function BuyV2rStock(props) {

    const priceRef = useRef(false)

    const selectedType = useRef(false);
    const [quantity, setQuantity] = useState(1);
    //eslint-disable-next-line
    const [amount, setAmount] = useState(null);
    //eslint-disable-next-line
    const [typeId, setTypeId] = useState(1);
    const [marketPrice, setMarketPrice] = useState(0);
    const [error, setError] = useState(null);
    //eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [isBuy, setIsBuy] = useState(props.isBuy)
    //eslint-disable-next-line
    const [currentPrice, setCurrentPrice] = useState(0)
    //eslint-disable-next-line
    const [change, setChange] = useState(0)
    //eslint-disable-next-line
    const [changePercentage, setChangePercentage] = useState(0)
    const [edited, setEdited] = useState(false);
    const [marketId, setMarketId] = useState(3);
    const [stoplossType, setStopLossType] = useState(null);
    const [triggerPrice, setTriggerPrice] = useState(0);
    const [stopLossPercent, setStoplossPercent] = useState(0);
    const [targetPercent, setTargetPercent] = useState(0);



    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        setIsBuy(props.isBuy)
    }, [props.isBuy])


    useEffect(() => {
        var myModalEl = document.getElementById('buyV2rStock')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setEdited(false);
            setTypeId(1);
            setQuantity(1);
            setMarketId(3);
            setAmount(null);
            setStopLossType(null);
            setTriggerPrice(0);
            setMarketPrice(0);
            setStoplossPercent(0);
            setTargetPercent(0);
            props.resetData();
            selectedType.current = false;
            priceRef.current = false;
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        var myModalEl = document.getElementById('buyV2rStock')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
            $('#inputRef').focus();
        })

    }, [])

    if (props.pending === true) {
        MARKET_PRICE = props.buyData.trade_price !== "-" ? props.buyData.trade_price : 0
    } else {
        MARKET_PRICE = props.liveData[props.buyData.scrip_code]?.ltp
    }

    useEffect(() => {
        setCurrentPrice(MARKET_PRICE);
        // if (!edited) {
        //     setMarketPrice(MARKET_PRICE);
        // }
        // if (props.pending === true) {
        //     setTriggerPrice(MARKET_PRICE)
        // }
        //eslint-disable-next-line
    }, [MARKET_PRICE]);

    useEffect(() => {
        if (props.liveData && props.buyData && props.pending === false) {
            if ($("#market-price").is(":focus") || edited) {
                setCurrentPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setChange(props.liveData[props.buyData.scrip_code]?.change);
                setChangePercentage(props.liveData[props.buyData?.scrip_code]?.change_percentage);
                if (props.buyData.order === "CNC") {
                    setMarketId(4)
                } else if (props.buyData.order === "MIS") {
                    setMarketId(3)
                }
            } else {
                // setCurrentPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                // setMarketPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setChange(props.liveData[props.buyData.scrip_code]?.change);
                MARKET_PRICE = props.liveData[props.buyData.scrip_code]?.ltp
                setChangePercentage(props.liveData[props.buyData?.scrip_code]?.change_percentage);
                if (props.buyData.order === "CNC") {
                    setMarketId(4)
                } else if (props.buyData.order === "MIS") {
                    setMarketId(3)
                }
                if (props.is_holdings === true) {
                    setMarketId(4)
                } else if (!props.is_position) {
                    setMarketId(3);
                }
            }
            //I have the focus
        } else if (props.liveData && props.buyData && props.pending === true) {
            priceRef.current = true
            MARKET_PRICE = props.buyData.trade_price
            setMarketPrice(props.buyData.price !== "-" ? props.buyData.price : 0);
            setQuantity(props.buyData.quantity);
            setTriggerPrice(props.buyData.trigger_price !== "-" ? props.buyData.trigger_price : 0);
            setStoplossPercent(props.buyData.stop_loss !== "-" ? props.buyData.stop_loss : 0);
            setTargetPercent(props.buyData.target_price !== "-" ? props.buyData.target_price : 0);
            if (props.buyData.order_type_id === 2) {
                setMarketId(4);
                setTypeId(2);
            } else if (props.buyData.order_type_id === 1) {
                setMarketId(4);
                setTypeId(1);
            } else if (props.buyData.order_type_id === 4) {
                setMarketId(3);
                setTypeId(2)
            } else if (props.buyData.order_type_id === 3) {
                setMarketId(3);
                setTypeId(1)
            }
            if (props.buyData.is_sl === true || props.buyData.is_sl_m === true) {
                if (props.buyData.is_sl_m) {
                    setTypeId(2);
                    setStopLossType(6);
                } else if (props.buyData.is_sl) {
                    setTypeId(2);
                    setStopLossType(5);
                }

            }
        }
        //eslint-disable-next-line
    }, [props.buyData, props.liveData, props.pending, props.is_holdings]);

    const _validatePrice = () => {
        // const total = edited ? (quantity * marketPrice) : (quantity * currentPrice)
        // if (total < props.balance || props.balance === 0) {

        if (isBuy) {
            if ((triggerPrice > marketPrice) && stoplossType === 5) {
                setError(`Price should be higher than LTP ${currentPrice} and Trigger price`)
            } else if ((triggerPrice < currentPrice) && stoplossType === 5) {
                setError(`Trigger price should be higher than last trade price (LTP) ${currentPrice}.`)
            } else if (stoplossType === 6 && triggerPrice < currentPrice) {
                setError(`Trigger price for stoploss buy orders should be higher than the last traded price (LTP) ${currentPrice}.`)
            } else if (typeId === 2 && marketPrice <= 0 && selectedType.current === true) {
                setError(`Please add valid price amount`)
            } else {
                if (props.pending === true) {
                    _manageOrders()
                } else {
                    _verifyBuying()
                }
            }
        } else {

            if ((marketPrice > currentPrice || marketPrice === 0) && props.pending === false && stoplossType === 5) {
                setError(`Price should be lower than last trade price (LTP) ${currentPrice} other than 0.`)
            } else if ((triggerPrice > currentPrice || triggerPrice === 0) && props.pending === false && stoplossType === 5) {
                setError(`Trigger price should be higher than price & lower than last trade price (LTP) ${currentPrice}.`)
            } else if (stoplossType === 6 && (triggerPrice > currentPrice || triggerPrice === 0) && props.pending === false) {
                setError(`Trigger price for stoploss sell orders should be lower than the last traded price (LTP) ${currentPrice}.`)
            } else if (typeId === 2 && marketPrice <= 0 && selectedType.current === true) {
                setError(`Please add valid price amount`)
            } else {
                if (props.pending === true) {
                    _manageOrders()
                } else {
                    _verifyBuying()
                }
            }
        }
        // } 
        // else {
        //     setError("Your fund balance is low.")
        // }
    }

    const _manageOrders = () => {
        const url = "customer/game/v2r/manage-orders";
        const data = {
            booking_id: props.buyData.booking_id,
            to_cancel: false,
            is_buy: isBuy,
            quantity: quantity,
            order_type: !props.is_holdings ? typeId === 1 && marketId === 3 ? 3 : marketId === 3 && typeId === 2 ? 4 : marketId === 4 && typeId === 1 ? 1 : marketId === 4 && typeId === 2 ? 2 : typeId : typeId,
            trigger_price: parseFloat(triggerPrice),
            stop_loss: parseFloat(stopLossPercent),
            price: parseFloat(marketPrice),
            target_price: parseFloat(targetPercent),
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                $("#buyV2rStock").trigger('click');
                setLoader(false);
                props.updatedOrder();
                // setTypeId(null);
                // setMarketPrice(0);
                setAmount(null);
            } else {
                setError(response.message);
                setLoader(false);
            }
        });

    }


    /* ----- verify buying v2r api ----*/
    const _verifyBuying = () => {
        setLoader(true);
        let url = "customer/game/v2r/orders";

        let data = {
            frequency_id: props.frequency,
            watchlist_id: props.is_holdings ? 0 : props.is_position ? 0 : props.watch_id,
            scrip_id: props.buyData.scrip_id,
            unit: quantity,
            is_buy: isBuy,
            order_type: !props.is_holdings ? typeId === 1 && marketId === 3 ? 3 : marketId === 3 && typeId === 2 ? 4 : marketId === 4 && typeId === 1 ? 1 : marketId === 4 && typeId === 2 ? 2 : typeId : typeId,
            trigger_price: parseFloat(triggerPrice),
            stop_loss: parseFloat(stopLossPercent),
            price: marketPrice === null ? 0 : parseFloat(marketPrice),
            target_price: parseFloat(targetPercent),
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                $("#buyV2rStock").trigger('click');
                setLoader(false);
                props.success(quantity);
                localStorage.setItem("v2r_joined", true)
                // setTypeId(null);
                // setMarketPrice(0);
                setAmount(null);
            } else {
                setError(response.message);
                setLoader(false);
            }
        });
    }

    const _joinGame = () => {
        const url = 'customer/game/v2r/join';

        const data = {
            v2r_frequency_id: Number(props.frequency)
        }
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                localStorage.setItem('v2r_joined', true);
                _validatePrice();
            } else {
                setError(response.message);
            }
        })
    }
    return (
        <div className={`${styles.e_buy_v2r_stock_modal} e-kyc-modal e-buy-v2r modal fade`}
            id="buyV2rStock"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-12 ps-0'>
                                    <div className=' d-flex align-items-center'>
                                        <h4 className='ps-0'>
                                            {isBuy ? "Buy" : "Sell"}
                                        </h4>
                                        <span className={`e-secondary-color ${styles.e_scrip_name}`}>
                                            {props.buyData?.scrip_code}
                                            {/* <span style={{ color: " #7D7688" }}>
                                                x 2 Qty
                                            </span> */}
                                        </span>
                                    </div>
                                    <p className={`${styles.e_scrip_detail} e-secondary-color`} style={{ fontWeight: "400" }}>
                                        {currentPrice}
                                        <span className={parseFloat(props.liveData[props.buyData?.scrip_code]?.change && props.liveData[props.buyData?.scrip_code]?.change) > 0 ? "e-green ps-1" : parseFloat(props.liveData[props.buyData?.scrip_code]?.change && props.liveData[props.buyData?.scrip_code]?.change) < 0 ? "e-red ps-2" : "ps-2"} style={{ fontSize: "12px" }}>
                                            {props.liveData[props.buyData.scrip_code]?.change}
                                            ({props.liveData[props.buyData?.scrip_code]?.change_percentage && props.liveData[props.buyData?.scrip_code]?.change_percentage}%)
                                        </span>
                                    </p>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 text-md-end text-sm-start text-start ps-0'>
                                    <div className='d-inline '>
                                        <span
                                            className={
                                                isBuy === false ?
                                                    `${styles.e_toggle_label} pe-3  e-toggle-v2r`
                                                    :
                                                    `${styles.e_toggle_label_false} pe-3  e-toggle-v2r-false`}>
                                            SELL
                                        </span>
                                        <SwitchV2r
                                            disabled={props.pending}
                                            className={`${styles.e_v2r_toggle}`}
                                            onChange={(e) => {
                                                props.setBuy(e.target.checked);
                                            }}
                                            checked={isBuy}
                                        />
                                        <span className={
                                            isBuy === true ?
                                                `${styles.e_toggle_label} ps-3  e-toggle-v2r`
                                                :
                                                `${styles.e_toggle_label_false} ps-3  e-toggle-v2r-false`}>
                                            BUY
                                        </span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    {

                        <div className='row gx-0'>
                            <div className="col-lg-12 col-md-12 col-12 ">
                                <div className='d-flex'>
                                    {
                                        MARKET_OPTIONS.map((item) => (
                                            <RadioButton
                                                value={marketId}
                                                v2r={true}
                                                checked={item.id === marketId}
                                                label={item.label}
                                                disabled={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === false ? false : props.modify ? props.modify : props.is_holdings}
                                                labelAnother={item.another}
                                                id={item.id}
                                                onClick={(value) => {
                                                    setMarketId(Number(value));


                                                }} />
                                        ))
                                    }
                                </div>
                                {/* <RadioButton label="When price reaches" id='2' /> */}
                            </div>

                        </div>

                    }


                    <div className='row mt-md-0 mt-sm-3 mt-3'>

                        <div className='col-lg-4  col-md-4 col-6 offset-0'>
                            <label className='mb-2'>
                                Enter qty
                            </label>
                            <Input
                                min="1"
                                searchBox={true}
                                readOnly={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === true ? true : false}
                                id="inputRef"
                                placeholder="Quantity"
                                type="number"
                                value={quantity}
                                valueChange={(value) => {
                                    if (Number(value) > 0) {
                                        setQuantity(value);
                                    } else {
                                        setQuantity("")
                                    }
                                }}
                                onSearch={() => {

                                    if (quantity && quantity > 0) {
                                        // if (props.pending) {
                                        //     _manageOrders()
                                        // } else {
                                        if (localStorage.getItem('v2r_joined'))
                                            _validatePrice();
                                        else _joinGame();
                                        // }
                                    } else {
                                        setError("Enter valid quantity")
                                    }
                                }}
                                className={
                                    props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ?
                                        `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                        :
                                        props.buyData && props.buyData.is_child_order === true ?
                                            `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                            :
                                            `${styles.e_input}`}
                            />

                        </div>

                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className=''>
                                <label className='mb-2'>
                                    Price
                                </label>
                                <Input
                                    id="market-price"
                                    placeholder="Price"
                                    type="number"
                                    defaultValue={currentPrice}
                                    readOnly={props.buyData && props.buyData.is_child_order === true && props.buyData.status_id === 1 && props.buyData.is_target === false ? true : typeId === 1 ? true : stoplossType === 6 ? true : false}
                                    value={!priceRef.current ? currentPrice : marketPrice}
                                    valueChange={(value) => {

                                        setMarketPrice(value);
                                        priceRef.current = true
                                        setEdited(true);
                                    }}
                                    className={
                                        props.buyData && props.buyData.is_child_order === true && props.buyData.status_id === 1 && props.buyData.is_target === false ?
                                            `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                            :
                                            stoplossType === 6 ?
                                                `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                                :
                                                typeId !== 1 ?
                                                    ` ${styles.e_input}`
                                                    :
                                                    `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                    }
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className=''>
                                <label className='mb-2'>
                                    Trigger price
                                </label>
                                <Input
                                    id="stoploss-price"
                                    placeholder="Price"
                                    type="number"
                                    // defaultValue={props.liveData[props.buyData.scrip_code]?.ltp}
                                    readOnly={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === true && props.buyData.status_id === 1 && props.buyData.is_target === false ? false : stoplossType === null ? true : false}
                                    value={triggerPrice}
                                    valueChange={(value) => {
                                        setTriggerPrice(value);
                                        setEdited(true);
                                    }}
                                    className={
                                        props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ?
                                            `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                            :
                                            props.buyData && props.buyData.is_child_order === true && props.buyData.status_id === 1 && props.buyData.is_target === false ?
                                                ` ${styles.e_input}`
                                                :
                                                stoplossType !== null ? ` ${styles.e_input}`
                                                    :
                                                    `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row gx-0">
                        <div className="col-lg-4 offset-4 col-md-12">

                            <div className='d-flex'>
                                {
                                    TYPE_OPTIONS.map((item) => (
                                        <RadioButton
                                            v2r={true}
                                            value={typeId}
                                            checked={stoplossType !== null ? false : item.id === typeId}
                                            disabled={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === true ? true : false}
                                            label={item.label}
                                            id={item.id}
                                            onClick={(value) => {
                                                setTypeId(Number(value));
                                                setStopLossType(null);
                                                setTriggerPrice(0);
                                                if (item.id === 2) {
                                                    setMarketPrice(currentPrice);
                                                    selectedType.current = true
                                                } else if (item.id === 1) {
                                                    setMarketPrice(0);
                                                    setTriggerPrice(0)
                                                }
                                                if (item.id !== 2) {
                                                    setEdited(false);
                                                    priceRef.current = false
                                                }
                                            }} />
                                    ))
                                }
                            </div>
                            {/* <RadioButton label="When price reaches" id='2' /> */}
                        </div>
                        <div className="col-lg-4  col-md-12">

                            <div className='d-flex justify-content-center'>
                                {
                                    STOPLOSS_OPTIONS.map((item) => (
                                        <RadioButton
                                            v2r={true}
                                            value={stoplossType}
                                            checked={item.id === stoplossType}
                                            label={item.label}
                                            id={item.id}
                                            disabled={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === true ? true : false}
                                            onClick={(value) => {
                                                setStopLossType(Number(value));
                                                if (Number(value) === 5) {
                                                    setTypeId(2);
                                                    setMarketPrice(currentPrice)
                                                    priceRef.current = false
                                                } else {
                                                    setTypeId(2);
                                                    setMarketPrice(0);
                                                    priceRef.current = true
                                                }

                                            }} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-start'>
                        <div className='col-3'>
                            <label>Stoploss%</label>
                            <Input type="number"
                                value={stopLossPercent}
                                is_number={true}
                                readOnly={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === true ? true : false}
                                className={
                                    props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ?
                                        `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                        :
                                        props.buyData && props.buyData.is_child_order === true ?
                                            `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                            :
                                            `${styles.e_input}`}
                                valueChange={(value) => {
                                  
                                    if (value <= 3 && value >= -25) {
                                   
                                        if (value === 0 || value.length <= 2) {
                                            setStoplossPercent(value)
                                        } else if (!value.includes(0)) {
                                            setStoplossPercent(value)
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className='col-3'>
                            <label>Traget%</label>
                            <Input type="number"
                                value={targetPercent}
                                is_number={true}
                                readOnly={props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ? true : props.buyData && props.buyData.is_child_order === true ? true : false}
                                className={
                                    props.buyData && props.buyData.is_sl === true && props.buyData.status_id === 1 ?
                                        `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                        :
                                        props.buyData && props.buyData.is_child_order === true ?
                                            `${styles.e_disabled_input} ${styles.e_input} e-disabled-input`
                                            :
                                            `${styles.e_input}`
                                }
                                valueChange={(value) => {
                                    if (value >= 0 && value <= 25) {
                                        if (value === 0 || value.length <= 2) {
                                            setTargetPercent(value)
                                        } else if (value > 0) {
                                            setTargetPercent(value)
                                        }
                                    }

                                }} />
                        </div>
                    </div>
                    <div className='row pt-4 mt-2'>
                        <div className='col-6'>
                            <p style={{ color: "white", fontSize: "14px" }} className="e-secondary-color">
                                Total cost {
                                    priceRef.current ?
                                        marketPrice === 0 && triggerPrice === 0 ? `₹${(quantity * currentPrice).toFixed(2)}` :
                                            stoplossType === 6 ? `₹${(quantity * triggerPrice).toFixed(2)}` :
                                                stoplossType === 5 ? `₹${(quantity * marketPrice).toFixed(2)}` :
                                                    `₹${(quantity * marketPrice).toFixed(2)}`
                                        :
                                        marketPrice === 0 && triggerPrice === 0 ? `₹${(quantity * currentPrice).toFixed(2)}` :
                                            stoplossType === 6 ? `₹${(quantity * triggerPrice).toFixed(2)}` :
                                                stoplossType === 5 ? `₹${(quantity * marketPrice).toFixed(2)}` :
                                                    `₹${(quantity * currentPrice).toFixed(2)}`
                                }
                            </p>
                        </div>
                        <div className='col-lg-6'>
                            <div className="d-flex justify-content-end">
                                <SecondaryBtn
                                    label="Cancel"
                                    // style={{ padding: "10px 16px" }}
                                    cancel="modal"
                                    className={`${styles.e_cancel} e-v2r-cancel`}
                                />
                                {
                                    isBuy ?
                                        <SolidBtn
                                            label="Buy"
                                            color={1}
                                            v2r={true}
                                            className={loader ? "e-butn-loader ms-3 e-disabled" : "ms-3"}
                                            handleClick={() => {
                                                // if (props.pending) {
                                                //     _manageOrders()
                                                // } else {
                                                if (localStorage.getItem('v2r_joined'))
                                                    _validatePrice();
                                                else _joinGame();
                                                // }
                                            }}
                                            disabled={quantity > 0 && quantity ? false : true}
                                        />
                                        :
                                        <SolidBtn
                                            label="Sell"
                                            color={2}
                                            className={loader ? "e-butn-loader ms-3 e-disabled" : "ms-3"}
                                            handleClick={() => {
                                                // if (props.pending) {
                                                //     _manageOrders()
                                                // } else {
                                                if (localStorage.getItem('v2r_joined'))
                                                    _validatePrice();
                                                else _joinGame();
                                                // }
                                            }}
                                            disabled={quantity > 0 && quantity ? false : true}
                                        />
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div >
    )
})
export default BuyV2rStock

