/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useContext, useEffect, useState } from 'react';
import $ from 'jquery';
import { logEvent } from 'firebase/analytics';

/*import componets*/
import { Input, PhoneInput } from '../formElements/index';
import { PrimaryBtn } from '../cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/kyc.module.scss';

/*import assets */
import services from '../../services/service';


import { FireBaseContext } from '../../context/firebaseContext';



export default function ProfileEdit(props) {

    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address1, setAddress1] = useState(null);
    const [address2, setAddress2] = useState(null);
    const [pincode, setPincode] = useState(null);
    const [number, setNumber] = useState();
    const [countryCode, setCountryCode] = useState();
    const [loader, setLoader] = useState(false);
    //eslint-disable-next-line
    const [clear, setClear] = useState(false);
    //eslint-disable-next-line
    const [credentialEdit, setCredentialEdit] = useState(false);
    const [can_verify, set_can_verify] = useState(false);
    const [old_mail_otp, set_old_mail_otp] = useState(null);
    const [old_number_otp, set_old_number_otp] = useState(null);
    const [new_mail_otp, set_new_mail_otp] = useState(null);
    const [new_number_otp, set_new_number_otp] = useState(null);
    const [toast, setToast] = useState(null);
    const [timer, setTimer] = useState(15);
    const analytics = useContext(FireBaseContext);

    useEffect(() => {
        var myModalEl = document.getElementById('profileEdit')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            $("body").removeAttr("style");
            $(".fade").removeClass("modal-backdrop");
        })
    }, []);

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 3000);
        }
    }, [toast]);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => {
                setTimer((timer - 1));
            }, 1000);
        }
    }, [timer]);

    useEffect(() => {
        setEmail(props.profile?.email);
        setPhone(props.profile?.phone_number?.country_code + props.profile?.phone_number?.number);
        setCountryCode(props.profile?.phone_number?.country_code);
        setNumber(props.profile?.phone_number?.number);
        setAddress1(props.profile?.location?.address?.line_1);
        setAddress2(props.profile?.location?.address?.line_2);
        setPincode(props.profile?.location?.pin_code);
    }, [props.profile]);


    /*----update address---- */
    const _updateAddress = () => {

        setLoader(true);
        props.update(false)
        var pattern = new RegExp(/^[0-9]{6}$/);
        if (pattern.test(pincode)) {
            let url = 'customer/profile/update';
            let data = {
                email: email,
                phone_number: phone,
                address_line_1: address1,
                address_line_2: address2,
                pincode: pincode,
            }

            services(true, url, data).then((response) => {
                if (response.status_code === 200) {
                    logEvent(analytics[0], "add_shipping_info", {
                        'email': email,
                        'phone': phone,
                        'address_line_1': address1,
                        'address_line_2': address2,
                        'pincode': pincode,
                    })
                    $('#profileEdit').trigger('click');
                    props.update(true);
                    setLoader(false);
                    setAddress1("");
                    setAddress2("");
                    setPincode("");
                } else {
                    setToast({ data: response.message, type: 'fail' });
                    setLoader(false);
                }
            });
        } else {
            setToast({ data: 'Enter valid pincode', type: 'fail' });
            setLoader(false);
        }
    }

    const _sendOtp = () => {
        let url = 'customer/profile/update-email-mobile-send-otp';
        let data = JSON.stringify({
            email: email,
            country_code: countryCode,
            phone_number: number
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setToast({ data: 'OTP send ', type: 'success' });
                set_can_verify(true);
            } else {
                setToast({ data: response.message, type: 'fail' });
            }
        })
    }

    const _verifyOtp = () => {
        let url = 'customer/profile/update-email-mobile-verify-otp';
        let data;
        if (props.profile?.email !== email) {
            data = JSON.stringify({
                email: email,
                new_email_otp: new_mail_otp,
                current_email_otp: old_mail_otp
            })
        }
        else if ((props.profile?.phone_number?.country_code + props.profile?.phone_number?.number) !== phone) {
            data = JSON.stringify({
                country_code: countryCode,
                phone_number: number,
                new_mobile_otp: new_number_otp,
                current_mobile_otp: old_number_otp,
            })
        }
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setToast({ data: 'Updated successfully', type: 'success' });
                $('#profileEdit').trigger('click');
                set_can_verify(false);
                props.update(true);
            } else {
                setToast({ data: response.message, type: 'fail' });
            }
        })
    }

    return (
        <div className={`${styles.e_kyc_modal} e-kyc-modal modal fade`}
            id="profileEdit"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2 px-2`}>
                        <h4>
                            Edit profile
                        </h4>
                        <button
                            type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setClear(true)}
                        >
                        </button>
                    </div>
                    <div className="container px-1">
                        <div className={`${styles.e_profile_card} p-3  e-home-card-wrapper`}>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h6 className='e-secondary-color'>
                                        Edit Contact
                                    </h6>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <label className='mb-2 e-secondary-color'>
                                        Mail
                                    </label>
                                    <Input
                                        placeholder="Eg: Johndoe@outlook.com"
                                        type="email"
                                        value={email}
                                        // readOnly={true}
                                        valueChange={(value) => {
                                            setCredentialEdit(true);
                                            setEmail(value)
                                        }}
                                        className="e-secondary-color"
                                    />
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <label className='mb-2 e-secondary-color'>
                                        Phone
                                    </label>
                                    <PhoneInput
                                        label="Phone number"
                                        country={(val) => {
                                            setCountryCode("+" + val)
                                        }}
                                        number={(val) => {
                                            setNumber(val)
                                        }}
                                        value={phone}
                                        valueChange={(value) => {
                                            setCredentialEdit(true);
                                            setPhone(value)
                                        }}
                                        type="number"
                                        className="e-secondary-color"
                                    />
                                </div>
                                {
                                    can_verify ?
                                        <>
                                            {
                                                props.profile?.email !== email ?
                                                    <>
                                                        <div className='col-lg-6 mt-2 pt-3'>
                                                            <label className='mb-2'>
                                                                Old mail OTP
                                                            </label>
                                                            <Input
                                                                placeholder="Eg: 2357"
                                                                type="number"
                                                                value={old_mail_otp}
                                                                valueChange={(value) => {
                                                                    set_old_mail_otp(value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 mt-2 pt-3'>
                                                            <label className='mb-2'>
                                                                New mail OTP
                                                            </label>
                                                            <Input
                                                                placeholder="Eg: 2357"
                                                                type="number"
                                                                value={new_mail_otp}
                                                                valueChange={(value) => {
                                                                    set_new_mail_otp(value)
                                                                }}
                                                            />
                                                        </div>
                                                        <p className='e-text pt-3'>
                                                            Please enter OTP received on Both email ids for verification
                                                        </p>

                                                    </>
                                                    :
                                                    null
                                            }
                                            {
                                                (props.profile?.phone_number?.country_code + props.profile?.phone_number?.number) !== phone ?
                                                    <>
                                                        <div className='col-lg-6 mt-2 pt-3'>
                                                            <label className='mb-2'>
                                                                Old phone number OTP
                                                            </label>
                                                            <Input
                                                                placeholder="Eg: 2357"
                                                                type="number"
                                                                value={old_number_otp}
                                                                valueChange={(value) => {
                                                                    set_old_number_otp(value)
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-lg-6 mt-2 pt-3'>
                                                            <label className='mb-2'>
                                                                New phone number OTP
                                                            </label>
                                                            <Input
                                                                placeholder="Eg: 2357"
                                                                type="number"
                                                                value={new_number_otp}
                                                                valueChange={(value) => {
                                                                    set_new_number_otp(value)
                                                                }}

                                                            />
                                                        </div>
                                                        <p className='e-text pt-3'> Please enter OTP received on Both Mobile Numbers
                                                            for verification</p>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 text-end my-3">
                        {
                            (props.profile?.phone_number?.country_code + props.profile?.phone_number?.number) !== phone || props.profile?.email !== email ?
                                <PrimaryBtn
                                    label={can_verify ? "Verify OTP" : "Send OTP"}
                                    type={2}
                                    className={loader ? `e-btn-loader ms-1 ${styles.e_profile_edit_btn}` : `ms-1 ${styles.e_profile_edit_btn}`}
                                    handleClick={() => { can_verify ? _verifyOtp() : _sendOtp() }}
                                />
                                :
                                <PrimaryBtn
                                    label="Send OTP"
                                    type={2}
                                    className={`ms-1 e-disabled ${styles.e_profile_edit_btn}`}
                                />
                        }

                    </div>

                    <div className={`${styles.e_profile_card} e-profile-card p-3`}>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h6 className='e-secondary-color'> Edit Address</h6>
                            </div>
                            <div className='col-lg-6 col-md-6 pt-3 mt-2'>
                                <label className='mb-2'>
                                    Address 1
                                </label>
                                <Input
                                    placeholder="Add address"
                                    type="text"
                                    value={address1}
                                    valueChange={(value) => {
                                        setAddress1(value)
                                    }}

                                />
                            </div>
                            <div className='col-lg-6 col-md-6 pt-3 mt-2'>
                                <label className='mb-2'>
                                    Address 2
                                </label>
                                <Input
                                    placeholder="Add address"
                                    type="text"
                                    value={address2}
                                    valueChange={(value) => {
                                        setAddress2(value)
                                    }}
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 pt-3 mt-2'>
                                <label className='mb-2'>
                                    Pincode
                                </label>
                                <Input
                                    placeholder="Eg: 566 732"
                                    type="number"
                                    value={pincode}
                                    valueChange={(value) => {
                                        if (value.length <= 6) {
                                            setPincode(value)
                                        }
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-12 mt-3'>
                        <div className="d-flex justify-content-end">
                            {
                                address1 && address2 && pincode ?
                                    <PrimaryBtn
                                        label="Save details"
                                        type={2}
                                        className={
                                            loader ?
                                                `e-btn-loader ms-1 ${styles.e_profile_edit_btn}`
                                                :
                                                `ms-1 ${styles.e_profile_edit_btn}`
                                        }
                                        handleClick={() => _updateAddress()}
                                    />
                                    :
                                    <PrimaryBtn
                                        label="Save details"
                                        type={2}
                                        className={`ms-1 e-disabled ${styles.e_profile_edit_btn}`}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                toast ?
                    <Toast
                        data={toast.data}
                        type={toast.type}
                    />
                    :
                    null
            }
        </div>
    )
}

