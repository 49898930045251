/*
 *   File : sideTab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Side Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
*/

/*import package*/
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*import styles*/
import styles from '../../styles/components/sideTab.module.scss';

const SideTab = (props) => {
	const [index, setIndex] = useState(1);
	useEffect(() => {
		// console.log(index)
		if (props.index) {
			setIndex(props.index);

		}
		// props.idx(index)
	}, [props,]);
	const navigate = useNavigate();
	return (
		<div>
			{
				props.data.map((item, key) => {
					return (
						<div
							className={
								index === (key + 1) ?
									key + 1 === props.data.length
										?
										`${styles.e_active_tab_rounded} me-0 row `
										:
										`${styles.e_active_tab} me-0 row`
									: key + 1 === props.data.length ?
										`${styles.e_tab}  ${styles.e_rounded}  me-0 row e-side-tab`
										:
										`${styles.e_tab} me-0 row e-side-tab`}
							onClick={() => {
								setIndex((key + 1));
								props.setTab((key + 1));
								navigate(`/profile?tabIndex=${key + 1} `)
							}}
							key={key}
						>

							{item}
						</div>
					);
				})
			}
		</div>
	);
}

export default SideTab;

