
/*
 *   File : tab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
*/

/*import package*/
import { useState, useEffect } from 'react';

/*import styles*/
import styles from '../../styles/components/tab.module.scss';

const Tab = (props) => {
	const [index, setIndex] = useState(1);
	const [tabData, setTabData] = useState([]);

	useEffect(() => {
		if (props.data) {
			setIndex(props.tabIndex)
			if (props.data_from_api) {
				setIndex(props.tabIndex)
			}
			setTabData(props.data)
		}
		//eslint-disable-next-line
	}, [props.data, props.tabIndex])
	return (
		<div className="">
			{
				props.data_from_api ?
					tabData && tabData
						.filter((item) => (item.active !== false))
						.map((item, key) => {
							return (
								<span
									className={
										index === item.watchlist_id ?
											`${styles.e_active_tab} ${props.className} me-4 e-secondary-color`
											:
											`${styles.e_tab} ${props.className} me-4 e-secondary-color-opacity`
									}
									onClick={() => {
										setIndex((item.watchlist_id));
										props.setTab(item.watchlist_id);
									}}
									key={key}
								>
									{item.name}
								</span>
							)
						})

					:
					tabData.map((item, key) => {
						return (
							<span
								className={
									index === (key + 1) ?
										`${styles.e_active_tab} ${props.className} me-4 e-secondary-color e-active-tab`
										:
										`${styles.e_tab} ${props.className} ${props.inactiveClass} me-4 e-link-colors e-inactive-tab`
								}
								onClick={() => {
									setIndex((key + 1));
									props.setTab((key + 1));
								}}
								key={key}
							>
								{item}
							</span>
						)
					})
			}
		</div>
	)
}

export default Tab;

