/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/

import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';
/*import styles*/
import styles from '../../styles/components/scripmodal.module.scss';

/*import assets */

import services from '../../services/service';

export default function SquareOff(props) {
	const [data, setData] = useState([]);
	const [ids, setIds] = useState([]);
	const [loader, setLoader] = useState(false);
	const [toast, setToast] = useState(null);
	const [liveData, setLiveData] = useState({});

	// useEffect(()=>{
	//   if(props.data){
	//     console.log('data changes', props.data)
	//     setData(props.data);
	//   }
	// },[props.data])


	useEffect(() => {
		if (props.data) {
			setData(props.data);
			setIds(props.ids);
			setLiveData({ ...props.liveData });
		}
		// var myModalEl = document.getElementById('squareOff')
		// myModalEl.addEventListener('shown.bs.modal', function (event) {
		// 	console.log('prpos.data', props.data);

		// })
		//eslint-disable-next-line
	}, [props.data])

	useEffect(() => {
		var myModalEl = document.getElementById('squareOff')
		myModalEl.addEventListener('hidden.bs.modal', function (event) {
			// setData(null);
			// setIds();
			setLiveData({})
		})
		//eslint-disable-next-line
	}, [])

	const _squareOff = () => {
		setLoader(true);
		let url = 'customer/game/skill-race/square-off';
		let data = JSON.stringify({
			booking_ids: ids,
		})
		services(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				$('#squareOff').trigger('click');
				setData(null);
				setIds();
				props.success();
			}
			else {
				setLoader(false);
				setToast(response.message);
			}
		})
		setTimeout(() => {
			setToast(null)
		}, 3000)
	}

	return (
		<div className={`${styles.e_scrip_modal} e-kyc-modal modal fade`}
			id="squareOff"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} modal-header mb-2`}>
						<h4 className='ps-0'>
							Square off
						</h4>
						<button
							type="button"
							className={
								localStorage.getItem("mode") === "true" ?
									"btn-close"
									:
									"btn-close-dark"
							}
							data-bs-dismiss="modal"
							aria-label="Close">
						</button>
					</div>
					<div className="row pt-4">
						<div className='col-lg-4'>
							<label className='mb-2'>
								Symbol
							</label>
						</div>
						<div className='col-lg-3'>
							<label className='mb-2'>
								Qty
							</label>
						</div>
						<div className='col-lg-3'>
							<label className='mb-2'>
								Type
							</label>
						</div>
						<div className='col-lg-2 text-end '>
							<label className='mb-2'>
								Price
							</label>
						</div>
					</div>
					{
						//eslint-disable-next-line
						data && data.map((item, key) => {
							if (item.status === 1 || item.status === "1") {
								return (
									<div className="row pt-4">
										<div className='col-lg-4'>
											<h6 className='e-secondary-color'>
												{item.scrip_code}
											</h6>
										</div>
										<div className='col-lg-3'>
											<h6 className='e-secondary-color'>
												1
											</h6>
										</div>
										<div className='col-lg-3'>
											<h6 className='e-secondary-color'>
												{
													item.is_buy ?
														"Buy"
														:
														'Sell'
												}</h6>
										</div>
										<div className='col-lg-2 text-end '>
											<h6 className='e-secondary-color'>
												{
													liveData[item.scrip_code] ?
														'₹' + liveData[item.scrip_code].ltp
														:
														'-'
												}
											</h6>
										</div>

									</div>
								)
							}
						})
					}
					<div className='row pt-4 mt-2'>
						<div className='col-lg-12'>
							<div className="d-flex justify-content-end">


								<PrimaryBtn
									loader={loader}
									label="Confirm square-off"
									type={2}
									className=""
									handleClick={() => _squareOff()}
								/>

							</div>
						</div>
					</div>

				</div>
			</div>
			{
				toast ?
					<Toast data={toast} type="fail" />
					:
					null
			}
		</div>

	)
}

