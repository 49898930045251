
// import style 
import style from '../../styles/components/toggle.module.scss';


const Toggle = (props) => {
	return (
		<label className={`${style.e_switch} ${props.className}`} >
			<input
				type="checkbox"
				onChange={(e) => {
					props.onChange(e)
				}}
				id={props.id}
				className={props.className}
				disabled={props.disabled}
				checked={props.check}
				{...props}
			/>
			<span className={`${style.e_slider}`}></span>
		</label>
	)
}

export default Toggle;