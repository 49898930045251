/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../../components/formElements/index';
import { PrimaryBtn, SecondaryBtn, LinkText } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';
import services from '../../services/service';
import { Toast } from '../note';

/*import assets */

export default function EditEmail(props) {

	const [email, setEmail] = useState('');
	const [timer, setTimer] = useState(15);
	const [error, setError] = useState(null);
	const [verify, setVerify] = useState(false);
	const [otp, setOtp] = useState(null);

	useEffect(() => {
		if (props.add) {
			var myModalEl = document.getElementById('email')
			myModalEl.addEventListener('hidden.bs.modal', (event) => {
				setVerify(false);
				setEmail("");
				$("body").removeAttr("style");
				$(".fade").removeClass("modal-backdrop");
			})
		}
	}, [props.add]);


	useEffect(() => {
		setEmail(props.email);
	}, [props.email]);

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 2000);
		}
	}, [error]);

	useEffect(() => {
		if (timer > 0 && props.add) {
			setTimeout(() => {
				setTimer((timer - 1));
			}, 1000);
		}
	}, [timer, props.add]);

	const _submitEmail = () => {
		let url = 'customer/profile/get-email-otp';

		let data = {
			email: email
		}

		services(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setVerify(true)
			} else {
				setError(response.message)
			}
		})
	}

	const _verify = () => {
		let url = 'customer/profile/verify-email-otp';

		let data = {
			email: email,
			otp: otp
		}

		services(true, url, data).then((response) => {
			if (response.status_code === 200) {
				$("#email").trigger("click")
				props.Success()
			} else {
				setError(response.message)
			}
		})
	}

	return (
		<>
			<div className={`${styles.e_edit_modal} modal fade`}
				id="email"
				tabIndex="-1"
				aria-labelledby="kyc"
				aria-hidden="true">
				<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
					<div className={`${styles.e_modal_content} modal-content`}>
						<div className={`${styles.e_modal_header} modal-header mb-2`}>
							<h4>
								{
									props.add ?
										"Add"
										:
										"Edit"
								} email address
							</h4>
							<button
								type="button"
								className={
									localStorage.getItem("mode") === "true" ?
										"btn-close"
										:
										"btn-close-dark"
								}
								data-bs-dismiss="modal"
								aria-label="Close">
							</button>
						</div>
						<div className="container">
							<div className="row">
								{
									props.add && verify ?
										<div className='col-lg-12'>
											<label className='mb-2'>
												Verify otp
											</label>
											<Input
												placeholder="Enter otp"
												type="number"
												value={otp}
												valueChange={(value) => {
													setOtp(value)
												}}
												className={`${styles.e_edit_input} e-edit-input`}
											/>
										</div>
										:
										<div className='col-lg-12'>
											<label className='mb-2'>
												Email address
											</label>
											<Input
												placeholder="Email address"
												type="email"
												value={email}
												valueChange={(value) => {
													setEmail(value)
												}}
												className={`${styles.e_edit_input}`}
											/>
										</div>
								}


							</div>
							{
								props.add && verify ?
									<div className={` col-lg-12 pt-4`}>
										<p className={`${styles.e_nav} e-secondary-color`}>
											OTP not received yet?
											{
												timer ?
													<> Resend new OTP in 00:{timer >= 10 ? timer : ["0" + timer]}</>
													:
													<LinkText
														handleClick={(e) => {
															e.preventDefault();
															if (timer === 0) {
																setTimer(30);
																_submitEmail();
															}
														}}
														label="Resend new OTP"
														className="mx-2"
													/>
											}
										</p>
									</div>
									:
									null
							}
							<div className="row pt-4 mt-2 pb-2">
								<div className="col-lg-12">
									<div className="d-flex justify-content-end">
										<SecondaryBtn
											label="Cancel"
											type={2}
											className={`${styles.e_cancel} e-cancel me-3`}
											cancel="modal"
										/>
										{
											email ?
												props.add && verify ?
													<PrimaryBtn
														label="Verify otp"
														type={2}
														className={`${styles.e_modal_submit} e-modal-submit e-save-btn`}
														handleClick={() => _verify()}
													/>
													:
													<PrimaryBtn
														label="Save"
														type={2}
														className={`${styles.e_modal_submit} e-modal-submit e-save-btn`}
														handleClick={() => _submitEmail()}
													/>
												:
												<PrimaryBtn
													label="Save"
													type={2}
													className="e-disabled"
												/>
										}


									</div>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast
						data={error}
					/>
					:
					null
			}
		</>
	)
}

