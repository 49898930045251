// import package
import { useState, useEffect } from 'react';
import $ from 'jquery'

// import style
import style from '../../styles/components/indices.module.scss';

// import components
import { CheckBox } from '../formElements';
import { Toast } from '../note';
//service import
import services from '../../services/service';
import { PrimaryBtn } from '../cta';

const Indices = (props) => {
    const [data, setData] = useState();
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [toast, setToast] = useState(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        let modal = document.getElementById("indices-modal")
        modal.addEventListener('shown.bs.modal', () => {
            _getIndices()

        })
    }, [])

    // get available indices
    const _getIndices = () => {
        let url = 'customer/indices/get';
        services(false, url).then((response) => {
            if (response.status_code === 200) {
                setSelectedIndices(response.data.selected_indices);
                setData(response.data.indices);
            }
        })
    }


    //  set indices
    const _setIndices = (temp) => {
        setLoader(true);
        let url = 'customer/indices/set';
        let data = JSON.stringify({
            indices: temp
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                props.selectedIndices(temp)
                setLoader(false)
                $("#indices-modal").trigger("click")
            }
        })
    }



    const checkEnable = (temp) => {
        if (selectedIndices.length >= 2 && selectedIndices.indexOf(temp) < 0) {
            return true
        } else {
            return false
        }
    }
    return (
        <div className={`${style.e_indices_modal} modal`}
            id="indices-modal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog ${style.e_modal_dialog} e-indices-modal-dialog modal-dialog-centered`}>
                <div className={`modal-content ${style.e_modal_content}`}>
                    <div className="modal-header border-0">
                        <h5 className='mt-3 ps-2 e-secondary-color'>
                            Choose two indices
                        </h5>
                        <button type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div className={`modal-body ${style.e_modal_body} pt-0`}>
                        <p className='pb-2'>
                            Index
                        </p>
                        {
                            data && data.map((item, key) => {
                                return (
                                    <CheckBox
                                        disabled={checkEnable(item.id)}
                                        id={`${key}_${item.id}`}
                                        checkboxText={item.index}
                                        check={selectedIndices.indexOf(item.id) < 0 ? false : true}
                                        className="text-uppercase pb-2"
                                        checkedClass={style.e_checl_mark}
                                        onChange={() => {
                                            if (checkEnable(item.id)) {
                                                setToast('Two indices are allowed at a time; uncheck one and select another.')
                                            } else {
                                                let temp = selectedIndices;
                                                let item_index = temp.indexOf(item.id);
                                                console.log('index', item_index);
                                                if (item_index < 0) {
                                                    temp.push(item.id);
                                                }
                                                else {
                                                    temp.splice(item_index, 1);
                                                }
                                                console.log(temp);
                                                setSelectedIndices([...temp])
                                                // _setIndices([...temp]);
                                            }
                                            setTimeout(() => {
                                                setToast(null);
                                            }, 3000)
                                        }}
                                    />
                                )
                            })
                        }
                        <div className='col-12 text-end mb-3'>
                            <PrimaryBtn
                                label="Save"
                                className={loader ? "e-butn-loader" : ""}
                                handleClick={() => {
                                    _setIndices([...selectedIndices])
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            {
                toast ?
                    <Toast data={toast} type="fail" />
                    :
                    null
            }
        </div>
    )
}

export default Indices;