/*
 *   File : exit.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : exit game Modal
 *   Integrations : 17-08-2022
 *   Version : v1.0
 *   Created : 17-08-2022
 */

/*import packages*/
import React from 'react';
import $ from 'jquery'

/*import componets*/
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';


/*import styles*/
import styles from '../../styles/components/edit.module.scss';
import { useState } from 'react';
import services from '../../services/service';
import { Toast } from '../note';
import { useEffect } from 'react';


export default function Cancel(props) {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    const _removeScrip = () => {
        setLoader(true)
        const url = "customer/game/v2r/remove-scrip";

        const data = {
            watchlist_id: props.id,
            scrip_id: props.scrip_id
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                $("#cancel").trigger("click")
                props.updatedList(response)
            } else {
                setLoader(false);
                setError(response.message);
            }
        })
    }

    const _cancelOrder = () => {
        setLoader(true);
        const url = "customer/game/v2r/manage-orders"

        const data = {
            "booking_id": props.booking_id,
            "to_cancel": true,
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                $("#cancel").trigger("click")
                props.updateOrder()
            } else {
                setLoader(false);
                setError(response.message);
            }
        })
    }

    return (
        <>
            <div className={`${styles.e_cancel_modal} modal fade`}
                id="cancel"
                tabIndex="-1"
                aria-labelledby="kyc"
                aria-hidden="true">
                <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                    <div className={`${styles.e_modal_content} modal-content`}>
                        <div className={`${styles.e_modal_header} modal-header mb-2`}>
                            <h4>
                                {
                                    props.title ?
                                        "Remove Scrip" :
                                        "Cancel order"
                                }
                            </h4>

                            {/* <button type="button" className={localStorage.getItem("mode") === "true" ? "btn-close" : "btn-close-dark"} data-bs-dismiss="modal" aria-label="Close" ></button> */}
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-12 pt-2'>
                                    <p className={`${styles.e_sub_tile}  text-start e-secondary-color`}>
                                        {
                                            props.title ?
                                                props.title :
                                                "Are you sure you want to cancel the order?"
                                        }
                                    </p>

                                </div>
                            </div>

                            <div className="row pt-4 mt-4 pb-2">
                                <div className="col-lg-12 text-end pe-4">
                                    <SecondaryBtn label="Cancel"
                                        style={{ padding: "10px 16px" }}
                                        cancel="modal"
                                        className={styles.e_cancel}
                                    />
                                    <PrimaryBtn
                                        label={props.title ? "Remove" : "Update order"}
                                        handleClick={() => {
                                            if (props.title) {
                                                _removeScrip()
                                            } else {
                                                _cancelOrder();
                                            }
                                        }}
                                        className={loader ?
                                            `${styles.e_modal_submit} e-modal-submit e-save-btn ms-3 e-btn-loader`
                                            :
                                            `${styles.e_modal_submit} e-modal-submit e-save-btn ms-3`

                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type='fail' />
                    :
                    null
            }

        </>
    )
}

