
import React from 'react';

//Style import
import style from '../../styles/components/overlay.module.scss';

// Assets import
import LoaderImg from '../../assets/images/loader/overlay.gif';

const OverlayLoader = (props) => {
  return (
    <div className={`${style.e_overlay} e-overlay`}>
      <div className={style.e_loader_wrap}>
        <img src={LoaderImg}
          className={style.e_loader_img}
          alt=""
          draggable={false}
        />
        <h6 style={
          localStorage.getItem("mode") === "true" ?
            { color: 'White' }
            :
            { color: '#1f1136' }}
        >
          {
            props.note ?
              props.note
              :
              'Game loading'
          }
        </h6>
      </div>

    </div>
  )
}

export default OverlayLoader;