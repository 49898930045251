/*
 *   File : checkBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 07-12-2021
 */
/*import package*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/checkBox.module.scss';

const CheckBox = (props) => {
    return (
        <>
            {
                props.v2r ?
                    <label
                        className={
                            props.disabled ?
                                `${styles.e_v2r_checkbox_container} ${props.className} ${styles.e_disabled_check} pt-0`
                                :
                                ` pt-0 ${styles.e_v2r_checkbox_container} ${props.className}`}
                        style={{ color: props.color }}
                    >
                        <input
                            type="checkbox"
                            name={
                                props?.name
                                    ?
                                    props?.name
                                    :
                                    "editList"
                            }
                            id={props.id}
                            disabled={props.disabled}
                            required={props.required}
                            onChange={(e) => {
                                props.onChange(e.target.checked)
                            }}
                            checked={props.check}
                        />
                        {props.checkboxText}
                        {
                            props.icon ?
                                <img
                                    src={props.icon}
                                    alt=""
                                    className='ps-2'
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Good till cancel orders"
                                    draggable={false}
                                />
                                :
                                null
                        }
                        <span className={styles.checkmark + " " + props.checkedClass}></span>
                    </label>
                    :
                    <label
                        className={
                            props.disabled ?
                                `${styles.e_checkbox_container} ${props.className} ${styles.e_disabled_check} pt-0`
                                :
                                ` pt-0 ${styles.e_checkbox_container} ${props.className}`}
                        style={{ color: props.color }}
                    >
                        <input
                            type="checkbox"
                            name={
                                props?.name ?
                                    props?.name
                                    :
                                    "editList"
                            }
                            id={props.id}
                            disabled={props.disabled}
                            required={props.required}
                            onChange={(e) => {
                                props.onChange(e.target.checked)
                            }}
                            checked={props.check}
                        />
                        {props.checkboxText}
                        {
                            props.icon ?
                                <img
                                    src={props.icon}
                                    alt=""
                                    className='ps-2'
                                    draggable={false}
                                />
                                :
                                null
                        }
                        <span className={styles.checkmark + " " + props.checkedClass}></span>
                    </label>
            }
        </>
    );
}
export default CheckBox;

