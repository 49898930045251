/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect } from 'react';

/*import componets*/
import { LinkText } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/*import assets */
import Kyc from '../../assets/images/modal/kyc.png';
import Failed from '../../assets/images/modal/failed-kyc.png';
import KycWhite from '../../assets/images/modal/kyc-white.png';
import FailedWhite from '../../assets/images/modal/failed-kyc-white.png';

export default function Confirm(props) {


    useEffect(() => {
        let modal = document.getElementById('confirm')
        modal.addEventListener('hidden.bs.modal', function (event) {
            props.modalClose();
        })
        //eslint-disable-next-line
    }, [])

    return (
        <div className={`${styles.e_edit_modal} modal fade`}
            id="confirm"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <h4>{props.profile.kyc_status_string === "KYC Failed" ?
                            props.profile.kyc_status_string
                            : props.profile.kyc_status_string}</h4>

                        <button
                            type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close" >
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className='col-lg-12 pt-3'>
                                <img src={
                                    props.profile.kyc_status_string === "KYC Failed"
                                        ?
                                        localStorage.getItem("mode") === "false" ? FailedWhite : Failed
                                        :
                                        localStorage.getItem("mode") === "false" ? KycWhite : Kyc
                                }
                                    className={`${styles.e_img_low_balance}`}
                                    alt="no data"
                                    draggable={false}
                                    style={{ height: "300px", objectFit: "contain" }}
                                />
                                <p className={`${styles.e_low_balance_text} e-secondary-color`}>
                                    {
                                        props.failed ?
                                            <>

                                                <div>
                                                    {props.profile?.kyc_message}
                                                </div>

                                            </>
                                            :
                                            null
                                    }
                                    {/* {`Complete KYC for seamless withdrawal`} */}
                                </p>
                            </div>
                        </div>
                        <div className='text-center'>
                            <LinkText
                                label="Complete KYC"
                                handleClick={() => {
                                    window.location.href = "/profile";
                                    props.modalClose();
                                }}
                            />
                        </div>
                        {/* <div className="row pt-4 mt-2 pb-2">
                            <div className="col-lg-12 text-end pe-4">
                                <PrimaryBtn
                                    label="Add balance"
                                    type={2}
                                    handleClick={() => { window.location.href = "/wallet" }}
                                    className={`${styles.e_modal_submit} e-modal-submit e-save-btn`}
                                />
                            </div>
                        </div> */}


                    </div>
                </div>
            </div>
        </div>
    )
}

