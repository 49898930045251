// Package import
import React from 'react';

/*import styles*/
import styles from '../../styles/components/blockedModal.module.scss';

// asset import
import Illustration from '../../assets/images/nodata/no-data.png';

// component import
import { PrimaryBtn } from '../cta';

const BlockedModal = () => {
    return (
        <div className={`${styles.e_preference_modal} e-kyc-modal modal fade`}
            id="blocked-state"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                    </div>
                    <div className="container">
                        <div className='row'>
                            <div className='col-lg-12 text-center'>
                                <img src={Illustration}
                                    width="200px"
                                    alt=""
                                    draggable={false}
                                />
                                <h6>
                                    The game not available in your region please try later
                                </h6>
                            </div>
                            <div className='col-lg-12 text-end'>
                                <PrimaryBtn
                                    label="Okay"
                                    handleClick={() => {
                                        localStorage.clear();
                                        window.location.href = "/"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockedModal;