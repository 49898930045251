/*
 *   File : exit.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : exit game Modal
 *   Integrations : 17-08-2022
 *   Version : v1.0
 *   Created : 17-08-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { PrimaryBtn, SecondaryBtn } from '../cta/index';
import services from '../../services/service';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';
import { Toast } from '../note';



export default function ExitGame(props) {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);

    // useState(() => {
    //     setLoader(props.loader)
    // }, [props.loader])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    /* ----- exit game api --*/

    const _exitSkillraceGame = () => {
        setLoader(true);
        const url = "customer/game/skillrace/exit-game";

        services(false, url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                $("#exitGame").trigger("click")
                props.modalClose()
            } else {
                setLoader(false);
                setError(response.message);
            }
        });

    }

    const _exitV2rGame = () => {
        setLoader(true);
        const url = "customer/game/v2r/exit-game";

        const data = {
            v2r_frequency_id: props.frequency
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                $("#exitGame").trigger("click")
                props.modalClose()
            } else {
                setLoader(false);
                setError(response.message);
            }
        });

    }

    return (
        <>
            <div className={`${styles.e_edit_modal} modal fade`} id="exitGame" tabIndex="-1" aria-labelledby="kyc" aria-hidden="true">
                <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                    <div className={`${styles.e_modal_content} modal-content`}>
                        <div className={`${styles.e_modal_header} modal-header mb-2`}>
                            <h4>{"Exit game"}</h4>

                            <button
                                type="button"
                                className={
                                    localStorage.getItem("mode") === "true" ?
                                        "btn-close"
                                        :
                                        "btn-close-dark"
                                }
                                data-bs-dismiss="modal"
                                aria-label="Close" >
                            </button>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className='col-lg-12 pt-3'>


                                    <p className={`${styles.e_low_balance_text} px-3 text-center e-secondary-color`}>
                                        Are you sure you want to exit the game?
                                        This action will square off any pending entries and refund the un-used amount.
                                        You will not be able to re-join in this game.
                                    </p>

                                </div>
                            </div>

                            <div className="row pt-4 mt-4 pb-2">
                                <div className="col-lg-12 text-end pe-4">
                                    <SecondaryBtn label="Cancel"
                                        style={{ padding: "10px 16px" }}
                                        cancel="modal"
                                    />
                                    <PrimaryBtn
                                        label={"Yes, Exit"}
                                        handleClick={() => {
                                            if (props.type === 'v2r') {
                                                _exitV2rGame();
                                            } else {
                                                _exitSkillraceGame();
                                            }
                                        }}
                                        className={
                                            loader ?
                                                `${styles.e_modal_submit} e-modal-submit e-save-btn ms-3 e-btn-loader`
                                                :
                                                `${styles.e_modal_submit} e-modal-submit e-save-btn ms-3`
                                        }
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error}
                        type="fail"
                    />
                    :
                    null
            }
        </>
    )
}

