
/*
 *   File : slot.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : slot Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 04-02-2022
 */

/* import components */
import { useEffect, useState } from 'react';
import { CheckBox } from '../formElements';
import { SecondaryBtn } from '../../components/cta/index';

/* import style */
import style from '../../styles/components/slotCheck.module.scss';

const SlotWithCheck = (props) => {
    const [id, setId] = useState(null)
    const [name, setName] = useState(null);
    const [slotNames, setSlotNames] = useState([]);
    const [slotData, setSlotData] = useState([]);
    const [slotIds, setSlotIds] = useState([]);
    const [gameId, setGameId] = useState(null)

    // useEffect(() => {
    //     if (props.type) {
    //         _getSlots();
    //     }
    // }, [props.type])

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            setSlotData(props.data)
        }
        //eslint-disable-next-line
    }, [props.data])

    useEffect(() => {
        if (props.id && props.multiple) {
            setSlotIds(props.id)
        }
        //eslint-disable-next-line
    }, [props.id])

    useEffect(() => {
        if (props.id && !props.multiple && props.from === "summary") {
            setId(props.id)
        } else if (props.id && !props.multiple && !props.from) {
            setGameId(props.id)
        }
        //eslint-disable-next-line
    }, [props.id])

    useEffect(() => {
        if (props.name) {
            console.log('os', props.name)
            if (props.multiple) {
                setSlotNames(props.name)
            }
            else setName(props.name)
        }
        //eslint-disable-next-line
    }, [props.name])

    const _selectSlot = (obj) => {
        if (props.multiple) {
            let temp = [...slotIds];
            let names = [...slotNames];
            let idx = temp.findIndex((item) => item === obj.slot_id)
            let nameIndex = names.findIndex((item) => item === `${obj.start_time} - ${obj.end_time}`);
            if (nameIndex === -1) {
                names.push(`${obj.start_time} - ${obj.end_time}`);
            }
            else {
                if (temp.length > 1) {
                    names.splice(nameIndex, 1);
                }

            }
            setSlotNames(names);
            setGameId(obj.game_id);
            if (idx === -1) {
                temp.push(obj.slot_id)
            } else {
                if (temp.length > 1) {
                    temp.splice(idx, 1)
                }
            }
            setSlotIds(temp)
        } else {
            setId(obj.slot_id);
            setName(`${obj.start_time} - ${obj.end_time}`)
            setGameId(obj.game_id)
        }
    }
    return (
        <div className={`${style.e_slot_modal} e-slot-modal text-start mt-2 ${props.className}`} ref={props.ref}>
            <p>Slots</p>
            {
                <div className={`${style.e_option_wrapper}`}>
                    {
                        slotData.map((item, key) => {
                            return (
                                <CheckBox
                                    checkboxText={`${item.start_time} - ${item.end_time}`}
                                    disabled={props.multiple ? props.gameStatus === "cooling" ? false : !item.active : false}
                                    color={item.slot_id === props.activeId ? "#9c1fe9" : localStorage.getItem("mode") === "false" ? "#130727" : "#ffffff"}
                                    check={props.multiple ?
                                        slotIds.indexOf(item.slot_id) < 0 ? false : true
                                        :
                                        props.from === "summary" ?
                                            item.slot_id === id ? true : false
                                            :
                                            item.game_id === gameId ? true : false}
                                    onChange={() => { _selectSlot(item) }} />
                            )
                        })
                    }
                </div>
            }

            {
                slotData.length > 0 ?
                    <div className='row mt-3'>
                        <div className='d-flex'>
                            <SecondaryBtn
                                label="Cancel"
                                type={2}
                                className="e-cancel-btn me-2"
                                handleClick={() => {
                                    if (props.multiple) {
                                        props.Selected(props.id, props.name, props.id);
                                    } else {
                                        props.Selected(props.id, props.name, props.id);
                                    }
                                }} />
                            <SecondaryBtn
                                label="Save"
                                type={1}
                                handleClick={() => {
                                    if (props.multiple) {
                                        props.Selected(slotIds, slotNames, gameId)
                                    } else {
                                        props.Selected(id, name, gameId)
                                    }
                                }} />
                        </div>
                    </div>

                    :
                    <p className='e-secondary-color'>No slots</p>
            }
        </div>
    )
}

export default SlotWithCheck;