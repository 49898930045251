

/*import package*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/radioButton.module.scss';

const RadioButton = (props) => {
	const onClick = (e) => {
		props.onClick(e.target.id)
	}

	// console.log(props.id)

	return (
		<>
			{
				props.v2r ?
					<div className={props.disabled ? `${styles.e_v2r_radiobutton} e-v2r-radio-btn ${props.radioClass} ${styles.e_disabled_radio}` : `${styles.e_v2r_radiobutton} e-v2r-radio-btn ${props.radioClass}`}>
						<input value={props.value}
							id={props.id}
							onClick={(e) => { onClick(e) }}
							type="radio"
							name={props.name}
							disabled={props.disabled}
							checked={props.checked} />
						<label
							htmlFor={props.id}
							className={props.className}
							style={{ color: `${props.color}` }}>
							{props.label}
							<span style={{ color: "#7d7688" }} className="ps-1">
								{props.labelAnother}
							</span>
						</label>
					</div>
					:
					<div className={`${styles.e_radiobutton} ${props.radioClass}`}>
						<input value={props.value}
							id={props.id}
							onClick={(e) => { onClick(e) }}
							type="radio"
							name={props.name}
							checked={props.checked} />
						<label htmlFor={props.id}
							className={props.className}
							style={{ color: `${props.color}` }}>
							{props.label}
						</label>
					</div>
			}
		</>
	);
}

export default RadioButton;