/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for stock sports
 *   Version : 1.0.0
 *   Created : 13-01-2022
 */

/*----Meta -----*/

let API_URL = ""
const IS_DEMO = localStorage.getItem("demo")
if (IS_DEMO === "false" || !IS_DEMO) {
  //eslint-disable-next-line
  API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE
} else {
  //eslint-disable-next-line
  API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEMO_MODE : process.env.REACT_APP_DEMO_PRO_MODE
}
const environment = process.env.NODE_ENV
const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL //  "https://websocket.stocksports.in/api/v1/"
const v2r_time = {
  1: { url: '/v2r-daily-time', status: "v2r_daily_game_status" },
  2: { url: '/v2r-weekly-time', status: "v2r_weekly_game_status" },
  3: { url: '/v2r-bi-weekly-time', status: "v2r_bi_weekly_game_status" },
  4: { url: '/v2r-monthly-time', status: "v2r_monthly_game_status" },
}
var devconfigOne = {
  user: API_URL,
  datafeed: WS_BASE_URL + "datafeed",
  WEBSOCKET_URL: environment === "development" ? process.env.REACT_APP_WEBSOCKET_URL_LOCAL : process.env.REACT_APP_WEBSOCKET_URL_LIVE, // process.env.REACT_APP_WEBSOCKET_URL_LIVE,
  TIME_SERVER: environment === "development" ? process.env.REACT_APP_WEBSOCKET_TIMESERVER_LOCAL : process.env.REACT_APP_WEBSOCKET_TIMESERVER_LIVE,  //process.env.REACT_APP_WEBSOCKET_TIMESERVER_LIVE,
  GLOBAL_TIME_SERVER: environment === "development" ? process.env.REACT_APP_WEBSOCKET_GLOBAL_TIMESERVER_LOCAL : process.env.REACT_APP_WEBSOCKET_GLOBAL_TIMESERVER, //process.env.REACT_APP_WEBSOCKET_GLOBAL_TIMESERVER,
  M2E_SOCKET: environment === "development" ? process.env.REACT_APP_WEBSOCKET_URL : process.env.REACT_APP_WEBSOCKET_URL, //process.env.REACT_APP_WEBSOCKET_URL,
  SKILLRACE_SOCKET: environment === "development" ? process.env.REACT_APP_WEBSOCKET_URL : process.env.REACT_APP_WEBSOCKET_URL, //process.env.REACT_APP_WEBSOCKET_URL,
  V2R_SOCKET: environment === "development" ? process.env.REACT_APP_WEBSOCKET_URL : process.env.REACT_APP_WEBSOCKET_URL, //process.env.REACT_APP_DEMO_WEBSOCKET_URL,
  INDICES_SOCKET: environment === "development" ? process.env.REACT_APP_WEBSOCKET_URL : process.env.REACT_APP_WEBSOCKET_URL, //process.env.REACT_APP_WEBSOCKET_URL,
  CASH_FREE_MODE: environment === "development" ? process.env.REACT_APP_CASHFREE_DEV : process.env.REACT_APP_CASHFREE_LIVE,
  SHOW_SKILL_RACE: environment === 'development' ? process.env.REACT_APP_DEV_GAME_ENV : process.env.REACT_APP_PRO_GAME_ENV,
  SHOW_M2M: environment === 'development' ? process.env.REACT_APP_DEV_M2M_GAME_ENV : process.env.REACT_APP_PRO_M2M_GAME_ENV,
  SHOW_V2R: environment === 'development' ? process.env.REACT_APP_DEV_V2R_GAME_ENV : process.env.REACT_APP_PRO_V2R_GAME_ENV,
  REACT_APP_ONESIGNAL_APP_ID: process.env.REACT_APP_ONESIGNAL_APP_ID,
  V2R_TIME: v2r_time,
}


var devconfigTwo = {
  user: URL,
  datafeed: WS_BASE_URL + "datafeed",
  WEBSOCKET_URL: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_URL_LOCAL : process.env.REACT_APP_DEMO_WEBSOCKET_URL_LIVE, // process.env.REACT_APP_WEBSOCKET_URL_LIVE,
  TIME_SERVER: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_TIMESERVER_LOCAL : process.env.REACT_APP_DEMO_WEBSOCKET_TIMESERVER_LIVE,  //process.env.REACT_APP_WEBSOCKET_TIMESERVER_LIVE,
  GLOBAL_TIME_SERVER: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_GLOBAL_TIMESERVER_LOCAL : process.env.REACT_APP_DEMO_WEBSOCKET_GLOBAL_TIMESERVER, //process.env.REACT_APP_WEBSOCKET_GLOBAL_TIMESERVER,
  M2E_SOCKET: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_URL : process.env.REACT_APP_DEMO_WEBSOCKET_URL_LIVE, //process.env.REACT_APP_DEMO_WEBSOCKET_URL,
  SKILLRACE_SOCKET: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_URL : process.env.REACT_APP_DEMO_WEBSOCKET_URL_LIVE, //process.env.REACT_APP_DEMO_WEBSOCKET_URL,
  V2R_SOCKET: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_URL : process.env.REACT_APP_DEMO_WEBSOCKET_URL_LIVE, //process.env.REACT_APP_DEMO_WEBSOCKET_URL,
  INDICES_SOCKET: environment === "development" ? process.env.REACT_APP_DEMO_WEBSOCKET_URL : process.env.REACT_APP_DEMO_WEBSOCKET_URL_LIVE, //process.env.REACT_APP_WEBSOCKET_URL,
  CASH_FREE_MODE: environment === "development" ? process.env.REACT_APP_CASHFREE_DEV : process.env.REACT_APP_CASHFREE_LIVE,
  SHOW_SKILL_RACE: environment === 'development' ? process.env.REACT_APP_DEV_GAME_ENV : process.env.REACT_APP_PRO_GAME_ENV,
  SHOW_M2M: environment === 'development' ? process.env.REACT_APP_DEV_M2M_GAME_ENV : process.env.REACT_APP_PRO_M2M_GAME_ENV,
  SHOW_V2R: environment === 'development' ? process.env.REACT_APP_DEV_V2R_GAME_ENV : process.env.REACT_APP_PRO_V2R_GAME_ENV,
  REACT_APP_ONESIGNAL_APP_ID: process.env.REACT_APP_ONESIGNAL_APP_ID,
  V2R_TIME: v2r_time,
}

var devconfig = ""
if (localStorage.getItem("demo") === "true") {
  devconfig = devconfigTwo
} else {
  devconfig = devconfigOne
}



export default devconfig;