import { takeLatest, put, delay } from "redux-saga/effects";


function* _loginUser(data) {
    yield delay(1000); // delay for 1 second
    try {

        yield put({
            type: 'LOGIN_USER',
            payload: data.payload,
        });
    } catch (err) {
        yield put({
            type: 'LOGIN_FAILURE',
            payload: data.payload,
        });
    }
}


export function* _login() {
    yield takeLatest('LOGIN_USER', _loginUser);
}


