// /*
//  *   File : datepicker.js
//  *   Author : https://evoqueinnovativelab.com
//  *   Description : Datepicker
//  *   Integrations : 09-03-2022
//  *   Version : 1.0.0
//  *   Created : 14-01-2022
//  */

// /*import package*/
// import { DatePicker, Space } from 'antd';
// import 'antd/dist/antd.css';
// import moment from 'moment';

// /*import style*/
// // import styles from '../../styles/components/datepicker.module.scss';

// // import Icon from '../../assets/images/input/calendar.svg';

// const Rangepicker = (props) => {
// 	const { RangePicker } = DatePicker;
// 	return (
// 		<>
// 			<RangePicker
// 				value={[props.start, props.end]}
// 				placeholder={props.placeholder}
// 				format={props.format ? props.format : "DD-MM-YYYY"}
// 				disabledDate={props.disabledDate}
// 				allowClear={dateString => console.log(dateString)}
// 				// disabledDate={props.disabledDate}
// 				// minDate={moment().subtract(500, "years")}
// 				// maxDate={moment().subtract(18, "years")}
// 				className={props.className}
// 				onChange={(date, dateString) => {
// 					props.onDatesChange(dateString);
// 				}}
// 			/>
// 		</>
// 	);
// }

// export default Rangepicker;
/*
 *   File : yearRangePicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : singleDatepicker.js
 *   Integrations : 
 *   Version : v1.0
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { DatePicker } from 'antd';

const RangePickerAnt = (props) => {

  const { RangePicker } = DatePicker;
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    if (props.start === null) {
      setStart('');
    }

    if (props.end === null) {
      setEnd('');
    }
  }, [props.start, props.end])

  const handleChange = (date, dateString) => {
    console.log(date);
    if (date) {
      var tempStart = moment(date[0], 'DD-MM-YYYY');
      var tempEnd = moment(date[1], 'DD-MM-YYYY');
      setStart(tempStart);
      setEnd(tempEnd);
      setStart(tempStart);
      setEnd(tempEnd);
      props.onDatesChange(tempStart.format('YYYY-MM-DD'), tempEnd.format('YYYY-MM-DD'));
    }
    else {
      setStart(null);
      setEnd(null);
      props.onDatesChange(null, null);
    }
  }

  return (
    <div className="e-ant-daterange-picker">
      <RangePicker onChange={handleChange}
        format={props.format ? props.format : "DD-MM-YYYY"}
        value={[start, end]}
        // defaultPickerValue={ props.isDecadeBack ? moment().subtract(14, "years") : null} 
        placeholder={props.placeholder}
        allowClear={true}
        disabledDate={props.disabledDate}
      />

    </div>
  );
}

export default RangePickerAnt;
