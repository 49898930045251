import React, { createContext, useState } from 'react';

export const GameStatusContext = createContext();

export const GameStatusProvider = props => {
    const [gameStatus, setGameStatus] = useState(null);
    return (
        <GameStatusContext.Provider value={[gameStatus, setGameStatus]}>
            {props.children}
        </GameStatusContext.Provider>
    )
}