/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import componets*/
import { Input } from '../../components/formElements/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/edit.module.scss';

/*import assets */

export default function EditPin(props) {

	const [email, setEmail] = useState();

	useEffect(() => {
		setEmail(props.email);
	}, [props.email]);

	return (
		<div className={`${styles.e_edit_modal} modal fade`}
			id="pin"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} modal-header mb-2`}>
						<h4>
							Add pincode
						</h4>
						<button type="button"
							className={
								localStorage.getItem("mode") === "true" ?
									"btn-close"
									:
									"btn-close-dark"
							}
							data-bs-dismiss="modal"
							aria-label="Close">
						</button>
					</div>
					<div className="container">
						<div className="row">
							<div className='col-lg-12'>
								<label className='mb-2'>
									Pincode
								</label>
								<Input
									placeholder="Email address"
									type="number"
									value={email}
									valueChange={(value) => {
										setEmail(value)
									}}
									className={`${styles.e_edit_input}`}
								/>
							</div>


						</div>
						<div className="row pt-4 mt-2 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-end">
									<SecondaryBtn
										label="Cancel"
										type={2}
										className={`${styles.e_cancel} e-cancel me-3`}
										cancel="modal"
									/>
									{
										email ?
											<PrimaryBtn
												label="Save"
												type={2}
												className={`${styles.e_modal_submit} e-modal-submit e-save-btn`}
											/>
											:
											<PrimaryBtn
												label="Save"
												type={2}
												className="e-disabled"
											/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

