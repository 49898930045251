import { combineReducers } from "redux";


const initialState = {
    IS_LOGGED: false,
    USER_TOKEN: null,
    ERROR: null,
    WALLET_BALANCE: 0,
    THEME: localStorage.getItem("mode") === 'true' ? "dark" : "light"
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            return {
                IS_LOGGED: true,
                USER_TOKEN: action.payload,
            };
        case 'WALLET_BALANCE':
            return {
                ...state,
                WALLET_BALANCE: action.payload,
            };
        case 'SET_THEME':
            return {
                ...state,
                THEME: action.payload,
            };

        case 'LOGIN_FAILURE':
            return {
                IS_LOGGED: false,
                ERROR: action.payload
            };

        default:
            return state
    }
}

const rootReducer = combineReducers({
    EQUPLUS: Reducer
});
export default rootReducer;