/*
 *   File : buyStock.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations : 22-03-2022
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../../components/formElements/index';
import { SolidBtn } from '../../components/cta/index';
import RadioButton from '../../components/formElements/radio';
import Switch from '../cta/switch';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/buyStock.module.scss';

/*import assets */
import services from '../../services/service';
import Minus from '../../assets/images/dataFormatting/minus.svg';
import Plus from '../../assets/images/dataFormatting/plus.svg';
import MinusDark from '../../assets/images/dataFormatting/minus-dark.svg';
import PlusDark from '../../assets/images/dataFormatting/plus-dark.svg';

/*--- marketting type ---*/
const MARKETTING_TYPE = [
    { id: 1, label: "Market order" },
    { id: 2, label: "When price reaches" }
]

export default function BuyStock(props) {

    const [quantity, setQuantity] = useState(1);
    //eslint-disable-next-line
    const [amount, setAmount] = useState(null);
    //eslint-disable-next-line
    const [typeId, setTypeId] = useState(1);
    const [marketPrice, setMarketPrice] = useState(0);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [isBuy, setIsBuy] = useState(props.isBuy);
    //eslint-disable-next-line
    const [currentPrice, setCurrentPrice] = useState(0);
     //eslint-disable-next-line
    const [change, setChange] = useState(0);
    //eslint-disable-next-line
    const [changePercentage, setChangePercentage] = useState(0);
    //eslint-disable-next-line
    const [unused, setUnUsed] = useState(null);
    const [edited, setEdited] = useState(false);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    useEffect(() => {
        setIsBuy(props.isBuy);
    }, [props.isBuy]);


    useEffect(() => {
        var myModalEl = document.getElementById('buyStock')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setEdited(false);
            setTypeId(1);
            setQuantity(1);
        })
    }, [])

    useEffect(() => {
        var myModalEl = document.getElementById('buyStock')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
            $('#inputRef').focus();
        });
    }, []);

    useEffect(() => {
        if (props.buyData && props.buyData.scrip_id) {
            // setCurrentPrice(props.buyData?.data[1395].value);
            // setMarketPrice(props.buyData?.data[1395].value);
            // setChange(props.buyData.data[1396].value);
            // setChangePercentage(0);
            // setScripToBuy(props.buyData);
            _getBookingQuantity(props.buyData.scrip_id);
        }
    }, [props.buyData]);
    useEffect(() => {
        if (props.liveData && props.buyData) {
            if ($("#market-price").is(":focus") || edited) {
                setCurrentPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setChange(props.liveData[props.buyData.scrip_code]?.change);
                setChangePercentage(props.liveData[props.buyData?.scrip_code]?.change_percentage);
            } else {
                setCurrentPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setMarketPrice(props.liveData[props.buyData.scrip_code]?.ltp);
                setChange(props.liveData[props.buyData.scrip_code]?.change);
                setChangePercentage(props.liveData[props.buyData?.scrip_code]?.change_percentage);
            }
            //I have the focus
        }
        //eslint-disable-next-line
    }, [props.buyData, props.liveData]);

    /* ----- verify buying skill race api ----*/
    const _verifyBuying = () => {
        setLoader(true);
        let url = "customer/game/skill-race/booking";

        let data = {
            watchlist_id: props.watch_id,
            scrip_id: props.buyData.scrip_id,
            unit: quantity,
            is_buy: isBuy,
            order_type: typeId,
            trigger_price: marketPrice === null ? 0 : marketPrice
        }

        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                $("#buyStock").trigger('click');
                setLoader(false);
                props.success();
                // setTypeId(null);
                setMarketPrice(0);
                setAmount(null);
            } else {
                setError(response.message);
                setLoader(false);
            }
        });
    }

    const _resetData = () => {
        setTypeId(null);
        setMarketPrice(0);
        setAmount(null);
    }

    // useEffect(() => {
    //     // if(props.stockData){
    //     console.log('stock data', props.stockData);
    //     // let scripData = props.stockData[props.buyData.scrip_id]
    //     setCurrentPrice(props.stockData.LastTradePrice)
    //     setChange(props.stockData.Change)
    //     setChangePercentage(props.stockData.ChangePercentage)

    //     // }
    // }, [props.stockData])

    // get available booking quantity
    const _getBookingQuantity = (temp) => {
        let url = 'customer/game/skillrace/scrip-available-booking';
        let data = JSON.stringify({
            scrip_id: temp
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setUnUsed(response.data.total_booking - response.data.current_booking);
            }
        })
    }

    const _joinGame = (entryFee) => {
        let url = 'customer/game/skil-race/join';
        services(false, url).then((response) => {
            if (response.status_code === 200) {
                localStorage.setItem('skillrace_joined', true);
                _verifyBuying();
            } else {
                setError(response.message);
            }
        });
    }

    return (
        <div className={`${styles.e_buy_stock_modal} e-kyc-modal modal fade`}
            id="buyStock"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true"
        >
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4'>
                                    <h4 className='ps-0'>
                                        {isBuy ? "Buy" : "Sell"}
                                    </h4>
                                </div>
                                <div className='col-lg-4 text-center col-md-4'>
                                    <div className='d-inline '>
                                        <span className={
                                            isBuy === false ?
                                                `${styles.e_toggle_label} pe-3 e-secondary-color`
                                                :
                                                `${styles.e_toggle_label_false} pe-3 e-secondary-color`}>
                                            SELL
                                        </span>
                                        <Switch
                                            className={`${styles.e_toggle}`}
                                            onChange={(e) => {
                                                props.setBuy(e.target.checked);
                                            }}
                                            checked={isBuy}
                                        />
                                        <span className={
                                            isBuy === true ?
                                                `${styles.e_toggle_label} ps-3 e-secondary-color`
                                                :
                                                `${styles.e_toggle_label_false} ps-3 e-secondary-color`
                                        }>
                                            BUY
                                        </span>
                                    </div>
                                </div>
                                <div className='col-lg-4 text-end col-md-4' >
                                    <button
                                        type="button"
                                        className={
                                            localStorage.getItem("mode") === "true" ?
                                                "btn-close"
                                                :
                                                "btn-close-dark"
                                        }
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        handleClick={() => _resetData()}
                                    >
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            {
                                unused ?
                                    <p className='pt-2 e-secondary-color'>
                                        Unused entries on this scrip: {unused}
                                    </p>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="row gx-0">
                        <div className='col-lg-4 col-md-4'>
                            <h6 className='pt-1 mt-1 e-secondary-color'>
                                {props.buyData?.scrip_code}
                            </h6>
                            <div className='pt-4 mt-2'>
                                <h5 className='e-secondary-color'>
                                    {props.liveData[props.buyData?.scrip_code]?.ltp}
                                </h5>
                                <span className={
                                    props.liveData[props.buyData?.scrip_code]?.change && props.liveData[props.buyData?.scrip_code]?.change >= 0 ?
                                        'e-green'
                                        :
                                        props.liveData[props.buyData?.scrip_code]?.change && props.liveData[props.buyData?.scrip_code]?.change < 0 ?
                                            'e-red'
                                            :
                                            ''
                                }>
                                    {props.liveData[props.buyData?.scrip_code]?.change}
                                    ( {props.liveData[props.buyData?.scrip_code]?.change_percentage}%)
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <label className='mb-1'>
                                Type
                            </label>
                            <div className='d-flex'>
                                {
                                    MARKETTING_TYPE.map((item) => (
                                        <RadioButton
                                            value={typeId}
                                            checked={item.id === typeId}
                                            label={item.label}
                                            id={item.id}
                                            onClick={(value) => {
                                                setTypeId(Number(value));
                                            }} />
                                    ))
                                }
                            </div>
                            {/* <RadioButton label="When price reaches" id='2' /> */}
                        </div>
                    </div>
                    <div className='row '>

                        <div className='col-lg-4 offset-lg-4 col-md-4 offset-md-4 col-4 offset-0'>
                            <label className='mb-1'>
                                Enter qty
                            </label>
                            {/* <Input
                searchBox={true}
                onSearch={() => {
                  if (quantity && marketPrice) {
                    if (localStorage.getItem('skillrace_joined')) _verifyBuying();
                    else _joinGame();
                  }
                }}
                id="inputRef"
                placeholder="Quantity"
                type="number"
                value={quantity}
                valueChange={(value) => {
                  setQuantity(value);

                }}
                className={`${styles.e_input}`}
              /> */}



                            {/* <label className='mb-1'>{typeId === 1 ? 'Market' : 'Trigger'} price</label> */}
                            <span className='e-span e-edit-scrip-btn-input d-flex justify-content-between'>
                                <img src={
                                    localStorage.getItem("mode") === "true" ?
                                        Minus
                                        :
                                        MinusDark
                                }
                                    draggable={false}
                                    className={'e-minus '}
                                    alt=""
                                    onClick={() => {
                                        if (quantity > 1) {
                                            let quan = quantity - 1
                                            setQuantity(quan)
                                        } else {
                                            setQuantity(1)
                                        }
                                    }} />
                                <input
                                    onSearch={() => {
                                        if (quantity && marketPrice) {
                                            if (localStorage.getItem('skillrace_joined')) {
                                                _verifyBuying();
                                            } else {
                                                _joinGame();
                                            }
                                        }
                                    }}
                                    onInput={(e) => {
                                        e.target.value = Math.abs(e.target.value)
                                    }}
                                    type="number"
                                    value={quantity}
                                    id="inputRef"
                                    placeholder="Quantity"
                                    style={{ padding: "0px 8px !important " }}
                                    className={`e_table_input`}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 38) {
                                            // $(`#e-plus-${row.scrip_id}`).trigger('click');
                                            //eslint-disable-next-line
                                            setQuantity(quantity++)

                                        }
                                        else if (e.keyCode === 40) {
                                            if (quantity > 2) {
                                                // $(`#e-minus-${row.scrip_id}`).trigger('click');
                                                //eslint-disable-next-line
                                                setQuantity(quantity--)
                                            }
                                            else {
                                                setQuantity(1)
                                            }
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setQuantity(1)
                                        } else {
                                            setQuantity(e.target.value);
                                        }
                                    }}
                                />
                                <img src={
                                    localStorage.getItem("mode") === "true" ?
                                        Plus
                                        :
                                        PlusDark
                                }
                                    draggable={false}
                                    className='e-plus'
                                    alt=""
                                    onClick={() => {

                                        let quan = quantity + 1
                                        setQuantity(quan)

                                    }}
                                />
                            </span>

                        </div>
                        <div className='col-lg-4 col-md-4 col-4'>
                            <div className=''>
                                <label className='mb-1'>
                                    {
                                        typeId === 1 ?
                                            'Market'
                                            :
                                            'Trigger'
                                    }
                                    price
                                </label>
                                <Input
                                    id="market-price"
                                    readOnly={typeId === 1 ? true : false}
                                    placeholder="Price"
                                    type="number"
                                    value={marketPrice}
                                    valueChange={(value) => {
                                        setMarketPrice(value);
                                        setEdited(true);
                                    }}
                                    className={
                                        typeId === 1 ? `${styles.e_disabled_input} ${styles.e_input}`
                                            :
                                            `${styles.e_input}`
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row pt-4 mt-2'>
                        <div className='col-lg-12'>
                            <div className="d-flex justify-content-end">
                                {
                                    isBuy ?
                                        <SolidBtn
                                            label="Confirm buy"
                                            color={1}
                                            className={loader ? "e-butn-loader e-disabled" : ""}
                                            handleClick={() => {
                                                if (localStorage.getItem('skillrace_joined')) {
                                                    _verifyBuying();
                                                } else {
                                                    _joinGame();
                                                }
                                            }}
                                            disabled={quantity ? false : true}
                                        />
                                        :
                                        <SolidBtn
                                            label="Confirm sell"
                                            color={2}
                                            className={loader ? "e-butn-loader e-disabled" : ""}
                                            handleClick={() => {
                                                if (localStorage.getItem('skillrace_joined')) {
                                                    _verifyBuying();
                                                } else {
                                                    _joinGame();
                                                }
                                            }}
                                            disabled={quantity ? false : true}
                                        />
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
}

