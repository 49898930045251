
// import style 
import style from '../../styles/components/switch.module.scss';


const Switch = (props) => {
	return (
		<label className={`${style.e_switch} ${props.className}`} >
			<input
				type="checkbox"
				onChange={(e) => props.onChange(e)}
				className={props.className}
				disabled={props.disabled}
				checked={props.checked}
				{...props}
			/>
			<span className={`${style.e_slider}`}></span>
		</label>
	)
}

export default Switch;