
// import style 
import style from '../../styles/components/switch.module.scss';


const SwitchV2r = (props) => {
    return (
        <label className={`${style.e_v2r_switch} e-v2r-switch ${props.className}`} style={props.disabled ? { cursor: "no-drop" } : {}}>
            <input
                type="checkbox"
                onChange={(e) => props.onChange(e)}
                className={props.className}
                disabled={props.disabled}
                checked={props.checked}
                {...props}
            />
            <span className={`${style.e_slider}`} style={props.disabled ? { cursor: "no-drop" } : {}}></span>
        </label>
    )
}

export default SwitchV2r;