/*
 *   File : search.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Searcj box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 17-01-2022
 */
/*import package*/
import { useState, useEffect, useRef } from 'react';
import $ from 'jquery';

/*import components */
import { SecondaryBtn } from '../../components/cta/index';
import EmptyScreen from '../emptyScreen/empty';

/*import style*/
import styles from '../../styles/components/search.module.scss';

/*import assets */
// import CLogo from '../../assets/images/scrip/wipro.svg';
// import Tata from '../../assets/images/scrip/tata.png';
// import Yamaha from '../../assets/images/scrip/yamaha.png';
// import WIndows from '../../assets/images/scrip/windows.png';


// Service import 


const SearchBox = (props) => {

	const [value, setValue] = useState('');
	//eslint-disable-next-line
	const [suggestion, setSuggestion] = useState(false);
	const [close, setClose] = useState(false);
	const [cursor, setCursor] = useState(0);
	const [pointer, setPointer] = useState(0);
	const [height, setHeight] = useState(0);
	const wrapRef = useRef(null);
	var tempSearchArray = [];

	const closeMenu = (event) => {
		if (wrapRef.current && !wrapRef.current.contains(event.target)) {
			setClose(false);
		};
	}

	useEffect(() => {
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};

	}, []);

	// useEffect(() => {
	// 	if (props.from === 'skill-race') searchWatchList();
	// 	//eslint-disable-next-line
	// }, [value])

	useEffect(() => {
		setClose(true)
	}, [props.scrip])

	// useEffect(() => {
	// 	if (props.scrip.length <= 0) {
	// 		setClose(false)
	// 	}
	// }, [props.scrip])

	const valueChange = (e) => {
		setValue(e.target.value);

		props.valueChange(e.target.value);

	}

	// return search suggestion array
	const listScrips = () => {
		// console.log('search list render', searchList)

		// search box have value then return matching suggestions
		// if (searchList && searchList.length) {
		for (let index = 0; index < props.scrip.length; index++) {
			const element = props.scrip[index];
			tempSearchArray.push(
				<div className={
					index === cursor ?
						`row mb-2 pt-3 ${styles.e_selected_item} e-selected-item`
						:
						'row mb-2 pt-3'
				}
					key={index}
					id={"c-dropdown-palate" + index}
					onClick={() => {
						if (!element.is_in_watchlist) {
							props.result(element.scrip_id, index);
							setClose(false);
							setValue('');
							setCursor(0);
							setHeight(0);
							setPointer(0)

						} else {
							props.fail();
						}

					}}>
					<div className='col-xl-8 col-lg-7 col-md-8'>
						<h6 className="e-secondary-color">
							{element.name}
						</h6>
						<p className={`${styles.e_company}`}
							style={{ color: index === cursor ? 'fff' : null }}>
							{element.symbol}
						</p>
					</div>
					<div className='col-xl-4 col-lg-5 col-md-4 text-end'>
						{
							!element.is_in_watchlist ?

								<SecondaryBtn
									loading={
										props.loader && props.current === index ?
											true
											:
											false
									}
									label="+ Add"
									id={`e-btn-${index + 1}`}
									type={1}
								/>
								:
								null
						}

					</div>
				</div>
			);
		}
		// }
		// search box have no values then return all the  available options
		// else {
		//   return (
		//     <div className='row mb-2' >
		//       <div className='col-xl-12 col-lg-7 col-md-8'>
		//         <EmptyScreen title="No scrip found" note="Try with another name" />
		//       </div>
		//     </div>

		//   )
		// }
		return tempSearchArray;
	}

	const listVotingScrips = () => {
		// console.log('search list render', searchList)

		// search box have value then return matching suggestions
		// if (searchList && searchList.length) {
		for (let index = 0; index < props.scrip.length; index++) {
			const element = props.scrip[index];
			tempSearchArray.push(
				<div className={
					index === cursor ?
						`row mb-2 pt-3 `
						:
						'row mb-2 pt-3'
				}
					key={index}
					id={"c-dropdown-palate" + index} >
					<div className='col-xl-8 col-lg-7 col-md-8'>
						<h6 className="e-secondary-color">
							{element.scrip_name}
						</h6>
						<p className={`${styles.e_company}`}
							style={{ color: index === cursor ? 'fff' : null }}>
							{element.scrip_code}
						</p>
					</div>
					<div className='col-xl-4 col-lg-5 col-md-4 text-end'>
						{
							props.chosen.some((item) => item.scrip_id === element.scrip_id) ?
								null
								:

								<SecondaryBtn
									label="+ Add"
									id={`e-btn-${index + 1}`}
									type={1}
									className={props.chosen.filter((item) => item.scrip_id !== 0).length >= 20 ? "e-disabled" : ""}
									handleClick={() => {
										let tempChosen = props.chosen
										let idxChosen = tempChosen.findIndex((item) => item.scrip_id === 0)
										// if (idxChosen === -1) {
										// 	tempChosen.push(element)
										// }
										tempChosen[idxChosen]["scrip_id"] = element.scrip_id
										tempChosen[idxChosen]["scrip_code"] = element.scrip_code
										tempChosen[idxChosen]["scrip_name"] = element.scrip_name
										tempChosen[idxChosen]["scrip_image"] = element.scrip_image
										props.result(tempChosen)

									}} />
						}
					</div>
				</div>
			);
		}
		// }
		// search box have no values then return all the  available options
		// else {
		//   return (
		//     <div className='row mb-2' >
		//       <div className='col-xl-12 col-lg-7 col-md-8'>
		//         <EmptyScreen title="No scrip found" note="Try with another name" />
		//       </div>
		//     </div>

		//   )
		// }
		return tempSearchArray;
	}

	const handleKeyDown = (e) => {
		// var el = $("#c-dropdown-palate" + cursor).scrollTop(10);
		var y = $('#e-dropdown').scrollTop();
		console.log('y', y);
		var tmpCursor = pointer;
		var offset = height;
		var tmC = cursor
		if (e.keyCode === 38) { //up
			tmC = cursor - 1;
			tmpCursor = tmpCursor - 1;
			offset = offset - 60;
		} else if (e.keyCode === 40) { //down
			tmC = cursor + 1;
			tmpCursor = tmpCursor + 1;
			offset = offset + 60;
		} else if (e.keyCode === 13) {
			$(`#c-dropdown-palate${tmC}`).trigger('click');

		}
		setCursor(tmC);
		if (tmpCursor === 3) {
			let newHeight = offset + y;
			$('#e-dropdown').scrollTop(newHeight);
			tmpCursor = 0;
		}
		setHeight(offset);
		setPointer(tmpCursor);
	}

	return (
		<div className={`${styles.e_input_wrap} position-relative`} ref={wrapRef} >
			<input id={props.id}
				value={value}
				type={props.type}
				//eslint-disable-next-line
				className={`${styles.e_search_box}` + " " + props.className}
				onChange={(e) => { valueChange(e) }}
				placeholder={props.placeholder}
				onFocus={() => {
					setSuggestion(true);
					props.onFocus()
				}}
				onKeyDown={e => handleKeyDown(e)}
				onBlur={() => { setCursor(0); }}
			/>

			<>
				{
					value && value?.length > 0 ?
						<button
							className={`${styles.e_search_close} pe-3`}
							onClick={() => {
								setValue('');
								setClose(false)
							}}>
						</button>
						:
						null
				}
				{
					close ?
						props.scrip?.length > 0 ?
							<div className={`${styles.e_search_wrapper} e-search-wraper`} id="e-dropdown">
								<p className='pb-2'>
									Results
								</p>
								{
									props.voting ?
										listVotingScrips()
										:
										listScrips()}
							</div>
							:
							value?.length > 0 ?
								<div className={`${styles.e_search_wrapper} e-search-wraper`} id="e-dropdown">
									<EmptyScreen
										title="Nothing found"
										note="    "
										className="py-5"
									/>
								</div>
								:
								null


						:
						null
				}
			</>
		</div>
	);
}
export default SearchBox;


