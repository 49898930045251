import { takeLatest, put, delay } from "redux-saga/effects";


function* _setTheme(data) {
    yield delay(1000); // delay for 1 second
    try {

        yield put({
            type: 'SET_THEME',
            payload: data.payload,
            balance: data.balance
        });
    } catch (err) {
        // console.log(err)
    }
}


export function* _theme() {
    yield takeLatest('SET_THEME', _setTheme);
}


