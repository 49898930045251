
/*
 *   File : slot.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : slot Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 04-02-2022
 */
/* import style */
import { useEffect, useState } from 'react';
import style from '../../styles/components/slot.module.scss';
import RadioButton from '../formElements/radio';

const Slot = (props) => {
  const [id, setId] = useState(null)
  //eslint-disable-next-line
  const [name, setName] = useState(null);
  const [slotData, setSlotData] = useState([]);
  const [gameId, setGameId] = useState(null)

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setSlotData(props.data)
    }
    //eslint-disable-next-line
  }, [props.data])

  useEffect(() => {
    if (props.from === "summary") {
      setId(props.id)
    } else if (props.id && !props.multiple && !props.from) {
      setGameId(props.id)
    }
    //eslint-disable-next-line
  }, [props.id])

  useEffect(() => {
    setName(props.name)
    //eslint-disable-next-line
  }, [props.name])

  return (
    <div className={`${style.e_slot_modal} e-slot-modal text-start mt-2 ${props.className}`} ref={props.ref}>
      <p>Slots</p>
      {
        <div className={`${style.e_option_wrapper}`}>
          {
            slotData.map((item, key) => {
              return (
                <RadioButton
                  label={`${item.start_time} - ${item.end_time}`}
                  id={props.from === "summary" ? item.slot_id : item.game_id}
                  value={props.from === "summary" ? id : gameId}
                  color={
                    item.slot_id === props.activeId ?
                      "#9c1fe9"
                      :
                      localStorage.getItem("mode") === "false" ?
                        "#130727"
                        :
                        "#ffffff"
                  }
                  // disabled={props.multiple ? props.gameStatus === "cooling" ? false : !item.active : false}
                  checked={props.from === "summary" ?
                    item.slot_id === id ? true : false
                    :
                    item.game_id === gameId ? true : false}

                  onClick={(id) => {
                    console.log("slot", item.slot_id, id)
                    props.Selected(item.slot_id, `${item.start_time} - ${item.end_time}`, item.game_id)
                    setId(item.slot_id);
                    setName(`${item.start_time} - ${item.end_time}`)
                    setGameId(item.game_id)
                  }} />
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default Slot;