// Asset import 
import Illustration from '../../assets/images/nodata/no-data.png';

// Style import 
import style from '../../styles/components/nodata.module.scss';

const EmptyScreen = (props) => {

    return (
        <div className={`${style.e_nodata_wrapper} ${props.className} text-center`}>
            <img src={Illustration}
                className={style.e_img}
                alt="no data"
                width="140px"
                height="140px"
                draggable={false}
            />
            <h6 className={`${style.e_title} e-secondary-color`}>
                {
                    props.title ?
                        props.title
                        :
                        "Nothing found"
                }
            </h6>
            <p className={`${style.e_note} e-link-colors`}>
                {
                    props.note ?
                        props.note
                        :
                        "No data found try later"
                }
            </p>
        </div>
    )

}

export default EmptyScreen