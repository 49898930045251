/*
 *   File : game-detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Game Detail Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-01-2022
 */

/*import packages*/
import React from 'react';
import $ from 'jquery';

/*import componets*/
import { CheckBox } from '../../components/formElements/index';
import { LinkText } from '../../components/cta/index';
import { WatchVideo } from '../../components/modal/index';

/*import styles*/
import styles from '../../styles/components/game-detail.module.scss';

/*import assets */
import Icon from '../../assets/images/modal/bull.svg';
import Explore from '../../assets/images/modal/icon.svg';
import Watch from '../../assets/images/modal/watch.png';
import Arrow from '../../assets/images/modal/down-arrow.svg';



export default function GameDetailModal(props) {

	const _scrollToBottom = () => {
		var ele = document.getElementById("m2e-footer");
		ele.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest"
		});
	}

	var VIDEO_EXPRESSION = /((http(s)?:\/\/)?)(www\.)?((youtube\.com\/)|(youtu.be\/))[\S]+/g;

	return (
		<div className={`${styles.e_game_detail_modal} e-kyc-modal modal fade e-game-detail-modal`}
			id="m2e-how-to-modal"
			tabIndex="-1"
			aria-labelledby="kyc" aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} modal-header mb-2`}>
						<div className='row' style={{ width: "100%" }}>
							<div className='col-lg-9 col-md-8 col-sm-6 col-8 text-start'>
								<h4>
									How to play?
								</h4>
							</div>
							<div className='col-lg-3 col-md-4 col-sm-6 col-4 text-end pe-0'>
								<div className='d-inline-flex justify-content-end'>
									{
										VIDEO_EXPRESSION.test(props?.m2e?.settings?.video_url) ?
											<a href={props?.m2e?.settings?.video_url} target="_blank" rel="noreferrer">
												<LinkText
													label="Watch a video"
													right={true}
													image={Watch}
													className={`${styles.e_watch_btn}`} />
											</a>
											:
											null
									}
									{/* <PrimaryBtn label="Watch a video" target="#watchVideo" toggle="modal" /> */}
									<button type="button"
										className={
											localStorage.getItem("mode") === "true" ?
												"btn-close ms-2 mt-1"
												:
												"btn-close-dark ms-2 mt-2"
										}
										data-bs-dismiss="modal"
										aria-label="Close">
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="container e-sm-margin pt-3 px-3">
						<div className="row">
							<div className='col-lg-1 col-md-1 col-sm-1 col-1'>
								<img alt=""
									src={Icon}
									className="e-game-modal-bull"
									draggable={false}
								/>
							</div>
							<div className='col-lg-11 col-11 position-relative'>
								<div className={`${styles.e_content_wrapper} e-home-card-wrapper`} >
									<div className={`${styles.e_game_wrapper} e-game-modal-title-wrapper`}
										style={{ backgroundColor: props?.m2e?.settings?.background_color, }}>
										<div className={`${styles.e_arrow_wrap} position-absolute`}>
											<div className='position-relative'>
												<img src={Arrow}
													alt=""
													className={` ${styles.e_arrow_content}`}
													id="topButton"
													draggable={false}
													onClick={() => {
														_scrollToBottom();
													}} />
											</div>
										</div>
										<div className='row'>
											<div className='col-lg-8 col-md-8 col-sm-8 col-8 text-start'>
												<h2 className='pt-5 e-game-modal-title'>
													M2E
												</h2>
												<h6>
													Entry fee
													<span> ₹{props?.m2e?.basic_info?.unit_fee}</span>  /qty
												</h6>
											</div>
											<div className='col-lg-4 col-md-4 col-sm-4 col-4  text-end'>
												<img alt=""
													src={props?.m2e?.settings?.image_url}
													className="e-game-modal-img"
													width="150px"
													height="150px"
													draggable={false} />
											</div>
										</div>
									</div>
									<div className='text-start'>
										<p className='pt-3 e-game-modal-content'
											dangerouslySetInnerHTML={{ __html: `${props?.m2e?.settings?.about}` }}>
										</p>
									</div>
									<div className="text-center mt-5">
										{/* <Link to='/m2e' */}
										<LinkText
											label={
												localStorage.getItem("demo") === "false" ?
													"Start game"
													:
													"Play demo"
											}
											className={
												localStorage.getItem("demo") === "false" ?
													`${styles.e_btn} mt-2`
													:
													`${styles.e_demo_btn} mt-2`
											}
											right={true}
											image={Explore}
											// className={`${styles.e_btn}`}
											handleClick={() => window.location.href = '/m2e'} />
										{/* </Link> */}
									</div>
									<div className='row '>
										<div className={`${styles.e_check_wrapper} offset-lg-4 col-lg-4 offset-md-3 offset-sm-2 offset-xs-4 mt-2 mb-3`}>
											<CheckBox
												checkboxText="Don't show this again"
												checkedClass="e-check-box-checkmark"
												className={`${styles.e_check}`}
												onChange={(check) => {
													localStorage.setItem('customer_status_m2e', check);
													setTimeout(() => {
														$("#m2e-how-to-modal").trigger("click")
													}, 500)
													setTimeout(() => {
														window.location.href = "/m2e"
													}, 1000)
												}} />
										</div>
									</div>
									<div id="m2e-footer"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<WatchVideo />
		</div>
	)
}

