/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect } from 'react';
import $ from 'jquery'

/*import componets*/

/*import styles*/
import styles from '../../styles/components/edit.module.scss';
import { PrimaryBtn } from '../cta';

/*import assets */


const NotificationPopup = React.memo(function NotificationPopup(props) {


    useEffect(() => {
        let modal = document.getElementById('notification')
        modal.addEventListener('hidden.bs.modal', function (event) {
            props.modalClose();

            $("body").removeAttr("style")
            $(".fade").removeClass("modal-backdrop")
        })
        //eslint-disable-next-line
    }, [])

    return (
        <div className={`${props && props.style === 1 ? styles.e_edit_modal : styles.e_edit_modal_popup} modal fade`} id="notification" tabIndex="-1" aria-labelledby="kyc" aria-hidden="true">
            {
                props.style === 1 ?
                    <div className={`${styles.e_modal_dialog_popup_image} e-modal-popup modal-dialog modal-dialog-centered`}>
                        <div className={`${styles.e_popup_modal_content_image} modal-content`}>
                            <div className={`${styles.e_modal_body} position-relative`} >

                                <button type="button"
                                    className={
                                        `${styles.e_btn_close} btn-close`
                                    }
                                    data-bs-dismiss="modal"
                                    id="btn-close"
                                    aria-label="Close"
                                    onClick={() => {
                                        $("#notification").trigger("click")
                                    }} >
                                </button>


                                <div className="row">
                                    <div className='col-lg-12 text-center px-0' >
                                        <img src={props.image}
                                            className={styles.e_image}
                                            alt="no data"
                                            draggable={false}

                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <div className={`${styles.e_modal_dialog_popup} e-modal-popup modal-dialog modal-dialog-centered`}>
                        <div className={`${styles.e_popup_modal_content} modal-content`}>
                            <div className={`${styles.e_popup_header} modal-header row`}>
                                <div className='col-12 d-flex justify-content-center position-relative'>
                                    <h4>{props.title}</h4>

                                </div>
                            </div>
                            <div className={styles.e_modal_body}>
                                <div className="container" >

                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            <p className={`${styles.e_description} mb-0 e-secondary-color`}>
                                                {props.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <PrimaryBtn
                                                label="Continue"
                                                cancel="modal"
                                                id="btn-close"
                                                className={styles.e_continue}
                                                handleClick={() => {
                                                    $("#notification").trigger("click")
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
});
export default NotificationPopup

