/*
 *   File : datepicker.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Datepicker
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import package*/
import { DatePicker } from 'antd';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';

import moment from 'moment';
import { useEffect, useState } from 'react';

/*import style*/

const Datepicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (date, dateString) => {
    if (date) {
      var tempDate = moment(date, 'DD-MM-YYYY');
      setSelectedDate(tempDate);
      props.onChange(tempDate);
    }
    else {
      setSelectedDate(null);
      props.onChange(null);
    }
  }


  useEffect(() => {
    if (props.value) {
      if (props.converted) { }
      var tempDate = moment(props.value, 'YYYY-MM-DD');
      // console.log('props.value', props.value)
      // console.log('temp date', tempDate)
      setSelectedDate(tempDate)
    } else {
      setSelectedDate(null)
    }
    //eslint-disable-next-line
  }, [props.value])

  return (
    <>
      <DatePicker
        value={selectedDate}
        placeholder={props.placeholder}
        format={props.format ? props.format : "DD-MM-YYYY"}
        disabledDate={props.disabledDate}
        minDate={moment().subtract(500, "years")}
        maxDate={moment().subtract(18, "years")}
        className={props.className}
        onChange={handleChange}
        showToday={false}
        defaultPickerValue={
          props.isDecadeBack && !selectedDate ?
            moment().subtract(18, "years")
            :
            null
        }
      />
    </>
  );
}

export default Datepicker;