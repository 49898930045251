/*
 *   File : bankAccount.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Bank Account Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 01-03-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import RadioButton from '../formElements/radio';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/preference.module.scss';

/*import assets */
import services from '../../services/service';


export default function BankPreference(props) {

    const [accounts, setAccounts] = useState([]);
    const [defaultId, setDefaultId] = useState();
    const [toast, setToast] = useState();
    //eslint-disable-next-line
    const [currentDefaultKey, setCurrentDefaultKey] = useState();

    useEffect(() => {
        if (props.data) {
            let tId;
            let tKey;
            //eslint-disable-next-line
            props.data.map((item, key) => {
                if (item.is_default) {
                    tId = item.id;
                    tKey = key;
                }
            })
            setCurrentDefaultKey(tKey);
            setDefaultId(tId);
            setAccounts(props.data)
        }
    }, [props.data])

    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 3000)
        }
    }, [toast])

    useEffect(() => {
        if (defaultId) {
            _setDefault();
        }
        //eslint-disable-next-line
    }, [defaultId])

    const _setDefault = (key, id) => {
        let url = 'customer/profile/make-bank-account-default';
        let data = JSON.stringify({
            bank_detail_id: id
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setToast({ data: 'Update default account', type: "success" });
                $('#bank-preference').trigger('click');
                props.success(accounts);
            } else {
                setToast({ data: response.message, type: "fail" })
            }
        })
    }


    return (
        <div className={`${styles.e_preference_modal} e-game-detail-modal e-kyc-modal modal fade`}
            id="bank-preference"
            tabIndex="-1"
            aria-labelledby="kyc"
            aria-hidden="true"
        >
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_modal_content} modal-content`}>
                    <div className={`${styles.e_modal_header} modal-header mb-2`}>
                        <h4>
                            Set bank preference
                        </h4>
                        <button
                            type="button"
                            className={
                                localStorage.getItem("mode") === "true" ?
                                    "btn-close"
                                    :
                                    "btn-close-dark"
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div className="container">

                        {
                            accounts?.map((item, key) => {
                                return (
                                    <div className='col-lg-12 mb-3'>
                                        <div className={` px-3 py-1 ${styles.e_bank_card} e-home-card-wrapper`} >
                                            <RadioButton
                                                radioClass={`${styles.e_radio_class}`}
                                                id={item.bank_name + key}
                                                label={item.bank_name}
                                                checked={item.id === defaultId}
                                                onClick={() => {
                                                    _setDefault(key, item.id);
                                                    setDefaultId(item.id);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
            {
                toast ?
                    <Toast
                        data={toast.data}
                        type={toast.type}
                    />
                    :
                    null
            }
        </div>
    )
}

