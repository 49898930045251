import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-circular-progressbar/dist/styles.css';

import './styles/config.scss';
import './styles/style.scss';
import './styles/responsive.scss';

import ThemeContextWrapper from './context/themeContextWrapper';

import * as serviceWorker from './serviceWorker';

import { GameStatusProvider } from './context/gameStatusContext';
import { FirebaseProvider } from './context/firebaseContext'
import { Store } from './store';
import { Provider } from 'react-redux';


ReactDOM.render(
  <React.StrictMode>
    <ThemeContextWrapper>
      <GameStatusProvider>
        <FirebaseProvider>
          <Provider store={Store}>
            <App />
          </Provider>
        </FirebaseProvider>
      </GameStatusProvider>
    </ThemeContextWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
