/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { CheckBox } from '../../components/formElements/index';
import { PrimaryBtn, SecondaryBtn, } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/scripmodal.module.scss';

/*import assets */
import services from '../../services/service';
import Result from '../../assets/images/modal/result-modal.png';



const Scrip = React.memo(function Scrip(props) {
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [loading_time, set_loading_time] = useState(0);
    const [success, setSuccess] = useState(false);
    let total = [];
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        var myModalEl = document.getElementById('scrip')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            // alert('alert')
            setSuccess(false);
            set_loading_time(0);
            setLoader(false);
        })
    }, [])

    useEffect(() => {
        const len = props.data.length
        if (len < 1) {
            $("#close-btn").trigger("click")
        }
    }, [props.data])

    useEffect(() => {
        var myModalEl = document.getElementById('scrip')
        myModalEl.addEventListener('shown.bs.modal', (event) => {
            let storageValue = localStorage.getItem('submit_confirm');
            if (storageValue !== null) {
                if (storageValue === 'true') setChecked(true);
                else setChecked(false);
            }
        })
        //eslint-disable-next-line
    }, [])


    /* ---- book game api ----*/
    const _bookGame = async () => {
        setLoader(true);
        let count = 0;
        let interval = setInterval(() => {

            if (count < 80) {
                count = count + 10
                set_loading_time(count);
            }
        }, 1000);
        if (props.type === 1) {
            //eslint-disable-next-line
            var url = 'customer/game/m2e-book';
            //eslint-disable-next-line
            var data = {
                "booking_data": props.data.filter((data) => (data.performer_unit !== 0) || (data.under_performer_unit !== 0))
            }
        } else {
            //eslint-disable-next-line
            var url = 'customer/m2m-book';
            //eslint-disable-next-line
            var data = {
                "slot_id": props.id,
                "booking": props.data.filter((data) => (data.performer_unit !== 0) || (data.under_performer_unit !== 0))
            }
        }
        services(true, url, data).then((response) => {
            // console.log("response", response)
            if (response.status_code === 200) {
                set_loading_time(100);
                setSuccess(true);
                props.updatedData(response.data);
                // props.success();
                // setLoader(false)
                // $('#scrip').trigger('click');
            } else {
                clearInterval(interval);
                set_loading_time(0);
                setLoader(false);
                //   setSuccess(false)
                //   set_loading_time(0);
                // setLoader(false);
                setError(response.message)
            }
        });
    }

    const findTotal = (temp) => {
        var sum;
        if (total && total.length) {
            var lodash = require('lodash');
            sum = lodash.sum(total);
        }
        // if (sum) sum = sum.toLocaleString('en-IN');
        if (props.type === 2) {
            var finalSum = parseInt(sum) * props.id.length;
            // console.log("sum", parseInt(sum) * 6)
            return finalSum.toLocaleString('en-IN');
        } else {
            if (sum) sum = sum.toLocaleString('en-IN');
            return sum
        }

        // setTotal(sum);
    }

    const checkHandler = () => {
        localStorage.setItem('submit_confirm', !checked);
        props.setConfirmation(!checked);
        setChecked(!checked);
    }


    // // console.log("Rendering scrip...")
    return (
        <div className={`${styles.e_scrip_modal} e-kyc-modal modal fade`} id="scrip" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="kyc" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                {
                    !loader ?
                        <div className={`${styles.e_modal_content} modal-content`}>
                            <div className={`${styles.e_modal_header} modal-header mb-2`}>
                                <h4 className='ps-0 e-scrip-submit-modal-title'>Preview</h4>
                                <CheckBox
                                    checkboxText="Don't show confirmation again "
                                    className="e-submit-modal-checkbox"
                                    check={checked}
                                    onChange={(e) => { checkHandler() }}
                                />
                                <button
                                    type="button"
                                    id="close-btn"
                                    className={localStorage.getItem("mode") === "true" ? "btn-close" : "btn-close-dark"}
                                    data-bs-dismiss="modal"
                                    aria-label="Close">
                                </button>
                            </div>
                            <div className='row pt-4'>
                                <div className='col-lg-3 pe-0 col-4'>
                                    <label className='mb-2'>
                                        Scrip
                                    </label>
                                </div>
                                <div className='col-lg-3 px-0 col-3'>
                                    <label className='mb-2'>
                                        Gainer qty.
                                    </label>
                                </div>
                                <div className='col-lg-4 px-0 col-3'>
                                    <label className='mb-2'>
                                        Loser qty.
                                    </label>
                                </div>
                                <div className='col-lg-2 ps-0 col-2 text-end '>
                                    <label className='mb-2'>
                                        Amount
                                    </label>
                                </div>
                            </div>
                            <div className={` pe-3 ${styles.e_modal_body}`}>
                                {
                                    props.data.filter((data) => (data.performer_unit !== 0) || (data.under_performer_unit !== 0)).map((item, key) => {
                                        total.push((item.performer_unit ? parseInt(item.performer_unit) : 0 + item.under_performer_unit ? parseInt(item.under_performer_unit) : 0) * props.unitPrice)
                                        return (
                                            <div className="row pt-1">
                                                <div className='col-lg-3 pe-0 col-4'>
                                                    <h6 className='e-secondary-color'>
                                                        {item.scrip_code}
                                                    </h6>
                                                </div>
                                                <div className='col-lg-3 px-0 col-3 ps-1'>
                                                    <h6 className='e-secondary-color'>
                                                        {
                                                            item.performer_unit !== 0 ?
                                                                item.performer_unit
                                                                :
                                                                '-'
                                                        }
                                                    </h6>
                                                </div>
                                                <div className='col-lg-4 px-0 col-3 ps-3'>
                                                    <h6 className='e-secondary-color'>
                                                        {
                                                            item.under_performer_unit !== 0 ?
                                                                item.under_performer_unit
                                                                :
                                                                '-'
                                                        }
                                                    </h6>
                                                </div>
                                                <div className='col-lg-2 ps-0 col-2 text-end '>
                                                    <h6 className='e-secondary-color'>
                                                        ₹ {(item.performer_unit ? parseInt(item.performer_unit) : 0 + item.under_performer_unit ? parseInt(item.under_performer_unit) : 0) * props.unitPrice}</h6>
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                                <div className='row pt-4 mt-2'>
                                    <div className='col-lg-12'>
                                        <div className="d-flex justify-content-between">

                                            <div className='d-flex pt-3'>
                                                <label className=''>
                                                    Total
                                                </label>
                                                <h6 className='mt-0 ps-1 e-secondary-color'>
                                                    {
                                                        props.type === 2 ?
                                                            <>₹{findTotal()} - (Booking in {props?.id.length} slots)
                                                            </>
                                                            :
                                                            <>₹{findTotal()}</>
                                                    }
                                                </h6>
                                            </div>

                                            <PrimaryBtn
                                                label="Book now"
                                                type={2}
                                                className={loader ? "e-btn-loader ms-1" : "ms-1"}
                                                handleClick={() => _bookGame()}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={`${styles.e_modal_content} modal-content`}>
                            <div className={`${styles.e_modal_header} modal-header mb-2`}>
                                <h4 className='ps-0 '>Confirmation</h4>
                                {/* <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close">
                                </button> */}
                            </div>
                            <div className={`col-lg-12 text-center ${styles.e_loader_content} `}>
                                {
                                    !success ?
                                        <>
                                            <div className={`${styles.e_loader_wrapper}`}>
                                                <div className={`${styles.e_loader}`}>
                                                    <div className={`${styles.e_progress}`} style={{ width: `${loading_time}%` }}></div>
                                                </div>
                                            </div>
                                            <p className='d-block e-secondary-color'>Placing order...</p>
                                        </>
                                        :
                                        <div className={`${styles.e_success_wrpper}`}>
                                            <img src={Result} height="250px" alt="" draggable={false} />
                                            <p className='d-block my-3 e-secondary-color'>
                                                Booking successful!
                                            </p>
                                            <SecondaryBtn

                                                label="Close"
                                                cancel='modal'
                                                handleClick={() => {
                                                    props.success(true);
                                                }}
                                            />
                                        </div>
                                }

                            </div>
                        </div>

                }

            </div>
            {
                error !== null ?
                    <Toast data={error} />
                    :
                    null
            }
        </div>
    )
})
export default Scrip