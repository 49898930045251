/*
 *   File : fileUpload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea
 *   Integrations :
 *   Version : 1.0
 *   Created : 14-01-2022
 */

/*import packages*/
import React from 'react';


//  service import
import services from '../../services/service';


const FileUpload = (props) => {

	const uploadDocument = async (e) => {
		if (e.target.files[0]) {
			props.uploadStatus(true);
			let presigned_url = await getPresignedUrl(e.target.files[0].name);
			//eslint-disable-next-line
			let uploadResponse = uploadPresignedFile(presigned_url, e.target.files[0]);
		}
	}

	const getPresignedUrl = async (fileName) => {
		var date = new Date();
		let tmpRepsonse;
		let url = 'customer/app/presigned-url-upload';
		let data = JSON.stringify({
			key: props.key ? props.key + date.toISOString() + fileName : 'user-app' + date.toISOString() + "|" + fileName,
			is_public: props.is_public
		})
		await services(true, url, data).then(async (response) => {
			if (response.status_code === 200) {
				tmpRepsonse = await response.data;
			}
		})
		return tmpRepsonse;
	}

	const uploadPresignedFile = (uploadInfo, file) => {
		var formData = new FormData();
		formData.append("key", uploadInfo.upload_info.fields.key);
		formData.append("x-amz-algorithm", uploadInfo.upload_info.fields['x-amz-algorithm']);
		formData.append("x-amz-credential", uploadInfo.upload_info.fields['x-amz-credential']);
		formData.append("x-amz-date", uploadInfo.upload_info.fields['x-amz-date']);
		formData.append("policy", uploadInfo.upload_info.fields['policy']);
		formData.append("x-amz-signature", uploadInfo.upload_info.fields['x-amz-signature']);
		formData.append("file", file, file.name);

		var requestOptions = {
			method: 'POST',
			body: formData,
			redirect: 'follow'
		};

		fetch(uploadInfo.upload_info.url, requestOptions)
			.then(response => response.text())
			.then(result => {
				props.uploadedFile(uploadInfo.view_info, file.name);
				props.uploadStatus(false);
			})
			.catch(error => {
				console.log('error', error);
				props.uploadStatus(false);
			});
	}


	return (
		<input
			id={props.id}
			className={props.className}
			type="file"
			accept={props.accept}
			onChange={(e) => uploadDocument(e)}
		/>
	);
}

export default FileUpload;
