import React from 'react';
import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


/*import style*/
const CircularProgress = (props) => {

  const [text, setText] = useState(null);
  const [maxValue, setMaxValue] = useState(0);

  const [current, setCurrent] = useState(0);

  // useEffect(()=>{
  //   if(props.text !== null){
  //     let time = props.text
  //     let hours = time.split(':');
  //     let current = `${hours[0]}${0}`
  //     // console.log('current', typeof(current))
  //     console.log('minusType',typeof Number(hours[1]));
  //     var fg = hours[1].length;
  //     fg = fg - 1;    
  //     console.log('minut', fg)
  //     if(Number(hours[0]) === 0  && Number(hours[1]) > 30){
  //       current = 10;
  //     }
  //     else if (Number(hours[0]) === 0  && fg === 0 ){
  //       current =0;
  //     }
  //     setHour(current);
  //     setText(props.text)
  //   }
  // },[props.text])

  useEffect(() => {
    if (props.text !== null) {
      const hms = props.text;
      const [hours, minutes, seconds] = hms.split(':');
      const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
      setText(props.text);
      if (maxValue === 0) {
        setMaxValue(totalSeconds);
      }
      if (maxValue !== 0) {
        setCurrent(current + 1);
      }

    }
    //eslint-disable-next-line
  }, [props.text])

  useEffect(() => {
    setCurrent(0);
  }, [props.color])

  // console.log(props)
  return (
    <CircularProgressbar
      maxValue={maxValue}
      minValue={0}
      value={current}
      text={text}

      styles={buildStyles({
        pathColor: props.color,
        // Rotation of path and trail, in number of turns (0-1)
        pathTransition: 'none',
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: 'butt',
      })}
    >
    </CircularProgressbar>
  )
}

export default CircularProgress;