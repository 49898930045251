/*
 *   File : faqAccordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Faq
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 03-03-2022
 */

/* import packages */
import React, { useState, useEffect } from 'react';


/* import components */


/*Style Import*/
import styles from '../../styles/components/faqAccordion.module.scss';


const FaqAccordion = (props) => {
    const [id, setId] = useState();
    const [accordionData, setAccordionData] = useState([])

    useEffect(() => {
        setAccordionData(props.data)
    }, [props])


    return (
        <>
            <div className={`${styles.e_faq_accordion} e-faq-accordion`}>
                <div className="accordion" id="accordionDescription">
                    <div className="row">
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                accordionData?.map((item, key) => {
                                    if (key % 2 === 0) {
                                        return (

                                            <div >

                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_accordion_header} accordion-header`}
                                                        id={"collapseDescription" + item.faq_id}>
                                                        <button type="button"
                                                            className={`${styles.e_accordion_button} e-acc-btn`}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${key}`}
                                                            aria-expanded="false"
                                                            aria-controls={`collapse${key}`}>
                                                            <div className="row" onClick={() => { setId(item.faq_id); }}>
                                                                {
                                                                    item.faq_id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-11" >
                                                                                <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                                                    {item.question.en}
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-lg-1" >
                                                                                <span className={`${styles.e_accordion_icon} e-arr-down-faq`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.faq_id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-11" >
                                                                                <span className={`${styles.e_accordion_title_active}`}>
                                                                                    {item.question.en}
                                                                                </span>

                                                                            </div>
                                                                            <div className="col-lg-1" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.faq_id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className={`${styles.e_accordion_wrapper} e-acc-wraper`}>
                                                                            <span className={`${styles.e_accordion_content} e-secondary-color`}>
                                                                                {item.answer.en}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>

                                        )
                                    }

                                })
                            }
                        </div>
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                accordionData?.map((item, key) => {
                                    if (key % 2 !== 0) {
                                        return (
                                            <div >

                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_accordion_header} accordion-header`}
                                                        id={"collapseDescription" + item.faq_id}>
                                                        <button type="button"
                                                            className={`${styles.e_accordion_button} e-acc-btn`}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${key}`}
                                                            aria-expanded="false"
                                                            aria-controls={`collapse${key}`}>
                                                            <div className="row"
                                                                onClick={() => {
                                                                    setId(item.faq_id);
                                                                }}>
                                                                {
                                                                    item.faq_id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-11" >
                                                                                <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                                                    {item.question.en}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-lg-1" >
                                                                                <span className={`${styles.e_accordion_icon} e-arr-down-faq`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.faq_id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-11" >
                                                                                <span className={`${styles.e_accordion_title_active}`}>
                                                                                    {item.question.en}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-lg-1" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.faq_id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className={`${styles.e_accordion_wrapper} e-acc-wraper`}>
                                                                            <span className={`${styles.e_accordion_content} e-secondary-color`}>
                                                                                {item.answer.en}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}


export default FaqAccordion;