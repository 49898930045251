/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React from 'react';

/*import componets*/

/*import styles*/
import styles from '../../styles/components/kyc.module.scss';

/*import assets */

export default function WatchVideo(props) {


  return (
    <div className={`${styles.e_kyc_modal} e-kyc-modal modal fade`} id="watchVideo" tabIndex="-1" aria-labelledby="kyc" aria-hidden="true">
      <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
        <div className={`${styles.e_modal_content} modal-content`}>
          <div className={`${styles.e_modal_header} modal-header mb-2`}>
            {/* <h4>KYC details</h4> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"

            >
            </button>
          </div>
          <div className="container">
            <div className='row'>
              <div className='col-lg-12'>
                <iframe id="Geeks3"
                  title="video"
                  src=
                  "https://www.youtube.com/embed/V5he1JXiQbg"
                  frameBorder="0" allowFullScreen>
                </iframe>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

