/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
 */

/* import packages */
import { useState, useEffect } from 'react';
import moment from 'moment';

/* import components */
import { Rangepicker } from '../../components/formElements/index';
import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';
import SelectBox from '../dropdown/select';

// import services
import services from '../../services/service';

/*Style Import*/
//eslint-disbale-next-line
import styles from '../../styles/components/reportAccordion.module.scss';

//import services
import devconfig from '../../config/config';

const FREQUENCY_OPTIONS = [
    { value: 0, label: 'All' },
    { value: 1, label: 'V2R Daily' },
    { value: 2, label: 'V2R Weekly' },
    { value: 3, label: 'V2R Bi-weekly' },
    { value: 4, label: 'V2R Monthly' },
]
// Bookings Game options
const GAME_OPTIONS = [
    { label: 'M2E', value: 1 },
    { label: 'M2M', value: 2 },
    { label: 'Skill race', value: 3 },
    { label: 'V2R', value: 4 }
]

const BOOKING_TYPE_OPTIONS = [
    { value: null, label: 'All' },
    { value: 1, label: 'Gainer' },
    { value: 2, label: 'Loser' },
]

const STATUS_OPTIONS = [
    { value: null, label: 'All' },
    { value: true, label: 'Won' },
    { value: false, label: 'Lost' }
]

const SKILLRACE_TRADE_OPTIONS = [
    { value: null, label: 'All' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Completed' },
    { value: 3, label: 'Pending' },
]

const TRADE_OPTIONS = [
    { value: null, label: 'All' },
    { value: 1, label: 'Open' },
    { value: 2, label: 'Won' },
    { value: 3, label: 'Lost' },
    { value: 4, label: 'Cancelled' },
]

const SKILLRACE_COOKINGTYPE_OPTIONS = [
    { value: null, label: 'All' },
    { value: 1, label: 'Market order' },
    { value: 2, label: 'Trigger order' },
]
const ReportAccordion = () => {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [toast, setToast] = useState(null);
    const [walletFilter, setWalletFilter] = useState([]);
    const [typeId, setTypeId] = useState(null);
    const [gameType, setGameType] = useState({ label: 'M2E', value: 1 });
    const [bookingType, setBookingType] = useState({ label: 'All', value: null });
    const [winningType, setWinningType] = useState({ value: null, label: 'All' });
    const [tradeType, setTradeType] = useState({ label: 'All', value: null });
    const [scripId, setScripId] = useState({ value: null, label: 'All' });
    const [scripDataFilter, setScripDataFilter] = useState([]);
    const [p_Status, setP_Status] = useState({ value: "0", label: "All" });
    const [winningTypeM2m, setWinningTypeM2m] = useState({ value: null, label: 'All' })
    const [scripIdM2m, setScripIdM2m] = useState({ value: null, label: 'All' });
    const [startDateM2m, setStartDateM2m] = useState(null);
    const [endDateM2m, setEndDateM2m] = useState(null);
    const [statusFilter, setStatusFilter] = useState([]);
    const [click, setClick] = useState("1");
    const [slotOptions, setSlotOptions] = useState([]);
    const [slotId, setSlotId] = useState({});
    const [loader, setLoader] = useState(false);
    const [frequencyId, setFrequencyId] = useState(FREQUENCY_OPTIONS[0])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        _getSlots();
        _getFilters();

    }, []);

    useEffect(() => {
        _getScripFilter(gameType.value);
    }, [gameType]);


    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                setToast(null);
            }, 3000);
        }
    }, [toast]);

    const _getSlots = () => {
        const url = "customer/game-slots-filter"

        services(false, url).then((response) => {
            if (response.status_code === 200) {
                let tempSlot = []
                tempSlot.push({ label: "All", value: null })
                response.data.map((item) => {
                    return (
                        tempSlot.push({
                            label: `${item.start_time} - ${item.end_time}`,
                            value: item.slot_id
                        })
                    )
                })
                setSlotOptions(tempSlot);
                setSlotId(tempSlot[0]);
            } else {
                setSlotOptions([]);
            }
        })
    }

    const _getScripFilter = (temp) => {
        let url = 'customer/report/get-scrips';
        let data = JSON.stringify({
            game_type_id: temp
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                let tArray = [{ value: null, label: 'All' }];
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    tArray.push({ value: element.scrip_id, label: element.scrip_code })
                }
                setScripDataFilter(tArray);
            } else {
                setScripDataFilter([]);
            }
        })
    }


    // API - Get wallet filter data
    const _getFilters = () => {
        let url = "customer/transaction/filter-data";
        services(false, url).then((response) => {
            if (response.status_code === 200) {
                let arr = [];
                let statusArr = [];

                arr = response.data.types.map((item, key) => {
                    return (
                        { value: item.id, label: item.name }
                    )
                })

                statusArr = response.data.status.map((item, key) => {
                    return (
                        { value: item.id, label: item.name }
                    )
                })

                setWalletFilter(arr);
                setStatusFilter(statusArr);
                setTypeId(arr[0]);
                setP_Status(statusArr[0]);
            } else {
            }
        });
    }

    // wallet report download;
    const _downloadWalletReport = () => {
        setLoader(true)
        let url = 'customer/report/wallet';
        let data = JSON.stringify({
            "transaction_type": typeId.value,
            "from_date": startDate,
            "to_date": endDate,
            'status': Number(p_Status.value),
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }

    //  () for download bookings report
    const _downloadBooking = () => {
        setLoader(true);
        let url = 'customer/report/bookings';
        let data = {}

        if (gameType?.value === 3) {
            data = JSON.stringify({
                "game_type": gameType.value,
                "from_date": startDate,
                "to_date": endDate,
                "scrip_id": scripId.value,
                "winner_status": winningType.value,
                "trade_status": tradeType.value,
                "order_type": bookingType.value,
            })
        } else {
            data = JSON.stringify({
                "game_type": gameType.value,
                "from_date": startDate,
                "to_date": endDate,
                "scrip_id": scripId.value,
                "winner_status": winningType.value,
                "trade_status": tradeType.value,
                "booking_type": bookingType.value,
                "slot_id": slotId.value,
                "frequency_id": frequencyId.value
            })
        }
        // if (gameType?.value === 3) {
        //   data.order_type = bookingType.value
        // }
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        });
    }

    //   () for download m2e report
    const _downloadM2E = () => {
        setLoader(true);
        let url = 'customer/game-results';
        let data = JSON.stringify({
            "game_type": 1,
            "start_date": startDate,
            "end_date": endDate,
            "scrip_code": scripId.label === "All" ? null : scripId.label,
            "winner_status": winningType.value,
            "slot_id": 0,
            "is_download": true
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }

    //   () for download m2e report
    const _downloadM2m = () => {
        setLoader(true);
        let url = 'customer/game-results';
        let data = JSON.stringify({
            "game_type": 2,
            "game_id": null,
            "start_date": startDateM2m,
            "end_date": endDateM2m,
            "scrip_code": scripIdM2m.label === "All" ? null : scripIdM2m.label,
            "winner_status": winningTypeM2m.value,
            "slot_id": slotId.value,
            "is_download": true
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        });
    }

    // () for download skill race report
    const _downloadSkillrace = () => {
        setLoader(true);
        let url = 'customer/game-results';
        let data = JSON.stringify({
            "game_type": 3,
            "game_id": 0,
            "start_date": startDate,
            "end_date": endDate,
            "scrip_code": null,
            "winner_status": winningType.value,
            "slot_id": 0,
            "is_download": true

        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }

    // () for download v2r report
    const _downloadV2r = () => {
        setLoader(true);
        let url = 'customer/report/v2r-report';
        let data = JSON.stringify({
            "from_date": startDate,
            "to_date": endDate,
            "frequency_id": frequencyId.value

        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }

    // () for download v2r summary
    const _dowloadV2rSummary = () => {
        setLoader(true);
        let url = 'customer/report/v2r-booking-summary';
        let data = JSON.stringify({
            "from_date": startDate,
            "to_date": endDate,
            "v2r_frequency_id": frequencyId.value,
            "scrip_id": scripId.value,

        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            } else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }
    const _downloadSummary = () => {
        setLoader(true);
        let url = 'customer/report/m2e-booking-summary';
        let data = JSON.stringify({
            from_date: startDate,
            to_data: endDate,
            scrip_id: scripId.value
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            }
            else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }

    const _downloadM2mSummary = () => {
        setLoader(true);
        let url = 'customer/report/m2m-booking-summary';
        let data = JSON.stringify({
            from_date: startDate,
            to_data: endDate,
            scrip_id: scripId.value,
            slot_id: slotId?.value
        })
        services(true, url, data).then((response) => {
            if (response.status_code === 200) {
                window.open(response.data.url);
                setLoader(false);
            }
            else {
                setToast(response.message);
                setLoader(false);
            }
        })
    }





    const resetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setTypeId(walletFilter[0]);
        setGameType({ value: 1, label: 'M2E' });
        setWinningType({ value: null, label: "All" });
        setBookingType({ value: null, label: 'All' });
        setTradeType({ value: null, label: 'All' });
        setScripId({ value: null, label: 'All' });
        setSlotId(slotOptions[0]);
        setLoader(false);
        setFrequencyId(FREQUENCY_OPTIONS[0]);
    }

    return (
        <>
            <div class="accordion e-result-accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <div className='row'
                            onClick={() => {
                                setClick("1");
                                resetFilters();
                            }}>
                            {
                                click !== "1" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                Wallet report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row"
                            onClick={() => {
                                setClick(null);
                            }}>
                            {
                                click === "1" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                Wallet report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                    </h2>
                    {
                        click === "1" ?
                            <div>
                                <div class="accordion-body">

                                    <div className='row'>
                                        <div className='col-xl-3 pe-0 col-lg-5 e-reports-filter-wrapper '>
                                            <p className='mb-0 pb-2  e-secondary-color'>
                                                Type
                                            </p>
                                            <SelectBox
                                                isSearchable={false}
                                                options={walletFilter}
                                                value={typeId}
                                                selectchange={(temp) => setTypeId(temp)}
                                                placeholder="Type"
                                                className="me-2"
                                            />
                                        </div>
                                        <div className='col-xl-3 pe-0 col-lg-5 e-reports-filter-wrapper '>
                                            <p className='mb-0 pb-2  e-secondary-color'>
                                                Status
                                            </p>
                                            <SelectBox
                                                isSearchable={false}
                                                options={statusFilter}
                                                value={p_Status}
                                                selectchange={(temp) => setP_Status(temp)}
                                                placeholder="Status"
                                                className="me-2"
                                            />
                                        </div>
                                        <div className='col-xl-4  pe-0 col-lg-5 col-md-6'>
                                            <p className='mb-0 pb-2  e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY")))
                                                        return true
                                                }
                                                )}
                                                onDatesChange={(start, end) => {
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2'>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadWalletReport()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                {/* <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                            onClick={()=>{setStartDate(null); setEndDate(null)}}>
                            P&L Report
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p className='mb-0 pb-2 e-secondary-color'>Pick a range</p>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-5 col-md-6'>
                                    <Rangepicker className="e-date-picker" />
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 pt-1'>
                                    <PrimaryBtn label="Download" type={1}   className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingThree" >
                        <div className='row'
                            onClick={() => {
                                setClick("2");
                                resetFilters()
                            }}>
                            {
                                click !== "2" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                Bookings report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row"
                            onClick={() => {
                                setClick(null);
                                resetFilters();
                            }}>
                            {
                                click === "2" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                Bookings report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                    </h2>
                    <div >
                        {
                            click === "2" ?
                                <div class="accordion-body">

                                    <div className='row'>
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-0 py-2 e-secondary-color'>
                                                    Game type
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={GAME_OPTIONS}
                                                    value={gameType}
                                                    selectchange={(temp) => {
                                                        setGameType(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Game type"
                                                />
                                            </div>
                                        </div>
                                        {
                                            gameType.value === 2 ?

                                                <div className='col-lg-3 pe-0'>
                                                    <div className='e-reports-filter-wrapper'>
                                                        <p className='mb-0 py-2 e-secondary-color'>
                                                            Slot
                                                        </p>
                                                        <SelectBox
                                                            className="me-2"
                                                            options={slotOptions}
                                                            value={slotId}
                                                            selectchange={(temp) => {
                                                                setSlotId(temp);
                                                            }}
                                                            isSearchable={false}
                                                            placeholder="Slot"
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            gameType.value === 4 ?
                                                <div className='col-lg-3 pe-0'>
                                                    <div className='e-reports-filter-wrapper'>
                                                        <p className='mb-0 py-2 e-secondary-color'>
                                                            Frequency
                                                        </p>
                                                        <SelectBox
                                                            className="me-2"
                                                            options={FREQUENCY_OPTIONS}
                                                            value={frequencyId}
                                                            selectchange={(temp) => {
                                                                setFrequencyId(temp);
                                                            }}
                                                            isSearchable={false}
                                                            placeholder="Frequency"
                                                        />
                                                    </div>
                                                </div>

                                                :
                                                null
                                        }
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-0 py-2 e-secondary-color'>
                                                    Scrip
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={scripDataFilter}
                                                    value={scripId}
                                                    selectchange={(temp) => {
                                                        setScripId(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Scrip"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='py-2 mb-0 e-secondary-color'>
                                                    {
                                                        gameType.value === 3 || gameType.value === 4 ?
                                                            "Order type"
                                                            :
                                                            "Booking type"
                                                    }
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={
                                                        gameType.value === 3 || gameType.value === 4 ?
                                                            SKILLRACE_COOKINGTYPE_OPTIONS
                                                            :
                                                            BOOKING_TYPE_OPTIONS
                                                    }
                                                    value={bookingType}
                                                    selectchange={(temp) => {
                                                        setBookingType(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder={
                                                        gameType.value === 3 || gameType.value === 4 ?
                                                            "Order type"
                                                            :
                                                            "Booking type"
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {
                                            gameType.value === 3 ?
                                                <div className='col-lg-3 pe-0 '>
                                                    <div className='e-reports-filter-wrapper'>
                                                        <p className=' mb-0 py-2 e-secondary-color'>
                                                            Winning  status
                                                        </p>
                                                        <SelectBox
                                                            className="me-2"
                                                            options={STATUS_OPTIONS}
                                                            value={winningType}
                                                            selectchange={(temp) => {
                                                                setWinningType(temp);
                                                            }}
                                                            isSearchable={false}
                                                            placeholder="Winning status"
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className=' mb-0 py-2 e-secondary-color'>
                                                    Trade status
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={
                                                        gameType.value === 1 ?
                                                            TRADE_OPTIONS
                                                            :
                                                            SKILLRACE_TRADE_OPTIONS
                                                    }
                                                    value={tradeType}
                                                    selectchange={(temp) => {
                                                        setTradeType(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Trade status"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-5 col-md-6 pe-0 '>
                                            <p className='mb-0 py-2 e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY")))
                                                        return true
                                                }
                                                )}
                                                onDatesChange={(start, end) => {
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20' style={{ marginTop: "34px" }}>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadBooking()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                {/* <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                            onClick={()=>{setStartDate(null); setEndDate(null)}}>
                            Scrip votings report
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p className='mb-0 pb-2 e-secondary-color'>Pick a range</p>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-5 col-md-6'>
                                    <Rangepicker className="e-date-picker" />
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 pt-1'>
                                    <PrimaryBtn label="Download" type={1}   className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
                            onClick={()=>{setStartDate(null); setEndDate(null)}}>
                            M2M report
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p className='mb-0 pb-2 e-secondary-color'>Pick a range</p>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-5 col-md-6'>
                                    <Rangepicker className="e-date-picker" />
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 pt-1'>
                                    <PrimaryBtn label="Download" type={1}   className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingSix">
                        <div className='row'
                            onClick={() => {
                                setClick("3");
                                resetFilters();
                            }}>
                            {
                                click !== "3" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2E report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row"
                            onClick={() => {
                                setClick(null);
                                resetFilters();
                            }}>
                            {
                                click === "3" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2E report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        {/* <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
                            onClick={() => { resetFilters() }}>
                            M2E report
                        </button> */}
                    </h2>
                    <div>
                        {
                            click === "3" ?
                                <div class="accordion-body">

                                    <div className='row'>
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-0 pb-2 e-secondary-color'>
                                                    Scrip
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={scripDataFilter}
                                                    value={scripId}
                                                    selectchange={(temp) => {
                                                        setScripId(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Scrip"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-2 e-secondary-color'>
                                                    Winning  status
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={STATUS_OPTIONS}
                                                    value={winningType}
                                                    selectchange={(temp) => {
                                                        setWinningType(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Winning  status"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                            <p className='mb-0 pb-2 e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY")))
                                                        return true
                                                })}
                                                onDatesChange={(start, end) => {
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2 pe-0'>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadM2E()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingEight">
                        <div className='row'
                            onClick={() => {
                                setClick("4");
                                resetFilters();
                            }}>
                            {
                                click !== "4" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2E booking summary
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row" onClick={() => {
                            setClick(null);
                            resetFilters();
                        }}>
                            {
                                click === "4" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2E booking summary
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        {/* <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight"
                            onClick={() => { resetFilters() }}>
                            M2E booking summary
                        </button> */}
                    </h2>
                    <div >
                        {
                            click === "4" ?
                                <div class="accordion-body">

                                    <div className='row'>
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-0 pb-2 e-secondary-color'>
                                                    Scrip
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={scripDataFilter}
                                                    value={scripId}
                                                    selectchange={(temp) => {
                                                        setScripId(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Scrip"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                            <p className='mb-0 pb-2 e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true
                                                }
                                                )}
                                                onDatesChange={(start, end) => {
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2'>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadSummary()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingSix">
                        <div className='row'
                            onClick={() => {
                                setClick("5");
                                resetFilters();
                            }}>
                            {
                                click !== "5" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2M report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row"
                            onClick={() => {
                                setClick(null);
                                resetFilters()
                            }}>
                            {
                                click === "5" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2M report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        {/* <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
                            onClick={() => { resetFilters() }}>
                            M2E report
                        </button> */}
                    </h2>
                    <div>
                        {
                            click === "5" ?
                                <div class="accordion-body">

                                    <div className='row'>
                                        <div className='col-lg-2 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-0 pb-2 e-secondary-color'>
                                                    Scrip
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={scripDataFilter}
                                                    value={scripIdM2m}
                                                    selectchange={(temp) => {
                                                        setScripIdM2m(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Scrip"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-2 e-secondary-color'>
                                                    Winning  status
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={STATUS_OPTIONS}
                                                    value={winningTypeM2m}
                                                    selectchange={(temp) => {
                                                        setWinningTypeM2m(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Winning  status"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-2 pe-0 e-m2m-slot e-reports-filter-wrapper '>
                                            <p className='mb-0 pb-2 e-secondary-color '>
                                                Slot
                                            </p>
                                            <SelectBox
                                                className="me-2"
                                                options={slotOptions}
                                                value={slotId}
                                                selectchange={(temp) => {
                                                    setSlotId(temp);
                                                }}
                                                isSearchable={false}
                                                placeholder="Slot"
                                            />
                                        </div>
                                        <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                            <p className='mb-0 pb-2 e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true
                                                })}
                                                onDatesChange={(start, end) => {
                                                    setStartDateM2m(start);
                                                    setEndDateM2m(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2 pe-0'>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadM2m()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>

                <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingSix">
                        <div className='row'
                            onClick={() => { setClick("6"); resetFilters() }}>
                            {
                                click !== "6" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2M booking summary
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row"
                            onClick={() => {
                                setClick(null);
                                resetFilters();
                            }}>
                            {
                                click === "6" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                M2M booking summary
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        {/* <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"
                            onClick={() => { resetFilters() }}>
                            M2E report
                        </button> */}
                    </h2>
                    <div>
                        {
                            click === "6" ?
                                <div class="accordion-body">

                                    <div className='row'>
                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-0 pb-2 e-secondary-color'>
                                                    Scrip
                                                </p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={scripDataFilter}
                                                    value={scripId}
                                                    selectchange={(temp) => {
                                                        setScripId(temp);
                                                    }}
                                                    isSearchable={false}
                                                    placeholder="Scrip"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 pe-0 e-m2m-slot e-reports-filter-wrapper '>
                                            <p className='mb-0 pb-2 e-secondary-color '>
                                                Slot
                                            </p>
                                            <SelectBox
                                                className="me-2"
                                                options={slotOptions}
                                                value={slotId}
                                                selectchange={(temp) => {
                                                    setSlotId(temp);
                                                }}
                                                isSearchable={false}
                                                placeholder="Slot"
                                            />
                                        </div>
                                        <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                            <p className='mb-0 pb-2 e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true
                                                })}
                                                onDatesChange={(start, end) => {
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2'>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadM2mSummary()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>

                <div class="accordion-item mt-3">
                    <h2 class="accordion-header" id="headingSix">
                        <div className='row'
                            onClick={() => {
                                setClick("7");
                                resetFilters();
                            }}>
                            {
                                click !== "7" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                Skillrace report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>
                        <div className="row"
                            onClick={() => {
                                setClick(null);
                                resetFilters();
                            }}>
                            {
                                click === "7" ?

                                    <>
                                        <div className="col-lg-11 col-10" >
                                            <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                Skillrace report
                                            </span>

                                        </div>
                                        <div className="col-lg-1 col-2" >
                                            <span className={`${styles.e_accordion_down}`}></span>
                                        </div>
                                    </>


                                    :
                                    null
                            }
                        </div>

                    </h2>
                    <div>
                        {
                            click === "7" ?
                                <div class="accordion-body">

                                    <div className='row'>

                                        <div className='col-lg-3 pe-0'>
                                            <div className='e-reports-filter-wrapper'>
                                                <p className='mb-2 e-secondary-color'>Winning  status</p>
                                                <SelectBox
                                                    className="me-2"
                                                    options={STATUS_OPTIONS}
                                                    value={winningType}
                                                    selectchange={(temp) => { setWinningType(temp); }}
                                                    isSearchable={false}
                                                    placeholder="Winning  status"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                            <p className='mb-0 pb-2 e-secondary-color'>
                                                Pick a range
                                            </p>
                                            <Rangepicker
                                                className="e-date-picker"
                                                placeholder={['From', 'To']}
                                                start={startDate}
                                                end={endDate}
                                                disabledDate={(day => {
                                                    if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true
                                                })}
                                                onDatesChange={(start, end) => {
                                                    setStartDate(start);
                                                    setEndDate(end);
                                                }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2 pe-0'>
                                            <PrimaryBtn
                                                label="Download"
                                                type={1}
                                                className={
                                                    loader ?
                                                        "e-report-download-btn e-btn-loader"
                                                        :
                                                        "e-report-download-btn"
                                                }
                                                handleClick={() => _downloadSkillrace()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>

                {
                    devconfig.SHOW_V2R === "true" ?
                        <>
                            <div class="accordion-item mt-3">
                                <h2 class="accordion-header" id="headingSix">
                                    <div className='row'
                                        onClick={() => {
                                            setClick("8");
                                            resetFilters();
                                        }}>
                                        {
                                            click !== "8" ?

                                                <>
                                                    <div className="col-lg-11 col-10" >
                                                        <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                            V2R report
                                                        </span>

                                                    </div>
                                                    <div className="col-lg-1 col-2" >
                                                        <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                                    </div>
                                                </>


                                                :
                                                null
                                        }
                                    </div>
                                    <div className="row"
                                        onClick={() => {
                                            setClick(null);
                                            resetFilters();
                                        }}>
                                        {
                                            click === "8" ?

                                                <>
                                                    <div className="col-lg-11 col-10" >
                                                        <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                            V2R report
                                                        </span>

                                                    </div>
                                                    <div className="col-lg-1 col-2" >
                                                        <span className={`${styles.e_accordion_down}`}></span>
                                                    </div>
                                                </>


                                                :
                                                null
                                        }
                                    </div>

                                </h2>
                                <div>
                                    {
                                        click === "8" ?
                                            <div class="accordion-body">

                                                <div className='row'>

                                                    <div className='col-lg-3 pe-0'>
                                                        <div className='e-reports-filter-wrapper'>
                                                            <p className='mb-0 pb-2 e-secondary-color'>Frequency </p>
                                                            <SelectBox
                                                                className="me-2"
                                                                options={FREQUENCY_OPTIONS}
                                                                value={frequencyId}
                                                                selectchange={(temp) => { setFrequencyId(temp); }}
                                                                isSearchable={false}
                                                                placeholder="Frequency"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                                        <p className='mb-0 pb-2 e-secondary-color'>
                                                            Pick a range
                                                        </p>
                                                        <Rangepicker
                                                            className="e-date-picker"
                                                            placeholder={['From', 'To']}
                                                            start={startDate}
                                                            end={endDate}
                                                            disabledDate={(day => {
                                                                if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true
                                                            })}
                                                            onDatesChange={(start, end) => {
                                                                setStartDate(start);
                                                                setEndDate(end);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2 pe-0'>
                                                        <PrimaryBtn
                                                            label="Download"
                                                            type={1}
                                                            className={
                                                                loader ?
                                                                    "e-report-download-btn e-btn-loader"
                                                                    :
                                                                    "e-report-download-btn"
                                                            }
                                                            handleClick={() => _downloadV2r()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            <div class="accordion-item mt-3">
                                <h2 class="accordion-header" id="headingSix">
                                    <div className='row'
                                        onClick={() => {
                                            setClick("9");
                                            resetFilters();
                                        }}>
                                        {
                                            click !== "9" ?

                                                <>
                                                    <div className="col-lg-11 col-10" >
                                                        <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                            V2R booking summary
                                                        </span>

                                                    </div>
                                                    <div className="col-lg-1 col-2" >
                                                        <span className={`${styles.e_accordion_up}  e-arr-down`}></span>
                                                    </div>
                                                </>


                                                :
                                                null
                                        }
                                    </div>
                                    <div className="row"
                                        onClick={() => {
                                            setClick(null);
                                            resetFilters();
                                        }}>
                                        {
                                            click === "9" ?

                                                <>
                                                    <div className="col-lg-11 col-10" >
                                                        <span className={`${styles.e_accordion_title} e-secondary-color`}>
                                                            V2R booking summary
                                                        </span>

                                                    </div>
                                                    <div className="col-lg-1 col-2" >
                                                        <span className={`${styles.e_accordion_down}`}></span>
                                                    </div>
                                                </>


                                                :
                                                null
                                        }
                                    </div>

                                </h2>
                                <div>
                                    {
                                        click === "9" ?
                                            <div class="accordion-body">

                                                <div className='row'>

                                                    <div className='col-lg-3 pe-0'>
                                                        <div className='e-reports-filter-wrapper'>
                                                            <p className='mb-0 pb-2 e-secondary-color'>Frequency </p>
                                                            <SelectBox
                                                                className="me-2"
                                                                options={FREQUENCY_OPTIONS}
                                                                value={frequencyId}
                                                                selectchange={(temp) => { setFrequencyId(temp); }}
                                                                isSearchable={false}
                                                                placeholder="Frequency"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-3 pe-0'>
                                                        <div className='e-reports-filter-wrapper'>
                                                            <p className='mb-2 e-secondary-color'>Scrip</p>
                                                            <SelectBox
                                                                className="me-2"
                                                                options={scripDataFilter}
                                                                value={scripId}
                                                                selectchange={(temp) => {
                                                                    setScripId(temp);
                                                                }}
                                                                isSearchable={false}
                                                                placeholder="Scrip"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-xl-4 col-lg-5 col-md-6 pe-0'>
                                                        <p className='mb-0 pb-2 e-secondary-color'>
                                                            Pick a range
                                                        </p>
                                                        <Rangepicker
                                                            className="e-date-picker"
                                                            placeholder={['From', 'To']}
                                                            start={startDate}
                                                            end={endDate}
                                                            disabledDate={(day => {
                                                                if (day.isAfter(moment(moment(), "DD-MM-YYYY"))) return true
                                                            })}
                                                            onDatesChange={(start, end) => {
                                                                setStartDate(start);
                                                                setEndDate(end);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-xl-2 col-lg-3 col-md-4 e-sm-mt-20 mt-3 pt-2 pe-0'>
                                                        <PrimaryBtn
                                                            label="Download"
                                                            type={1}
                                                            className={
                                                                loader ?
                                                                    "e-report-download-btn e-btn-loader"
                                                                    :
                                                                    "e-report-download-btn"
                                                            }
                                                            handleClick={() => _dowloadV2rSummary()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>

                        </>
                        :
                        null

                }



            </div>
            {
                toast ?
                    <Toast
                        data={toast}
                        type="fail"
                    />
                    :
                    null
            }
        </>
    );
}


export default ReportAccordion;