import axios from 'axios';
import { Store } from '../store';

export default async function services(is_post, url, data) {

  var API_URL = "";
  let match = url.split("/")[1]
  const IS_DEMO = localStorage.getItem("demo")
  const token = Store.getState().EQUPLUS.USER_TOKEN !== null ? Store.getState().EQUPLUS.USER_TOKEN : localStorage.getItem("access_token");

  if (is_post) {
    if (match === "auth") {
      API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url
    } else {
      if (IS_DEMO === "false" || !IS_DEMO) {
        //eslint-disable-next-line
        API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url
      } else {
        //eslint-disable-next-line
        API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEMO_MODE + url : process.env.REACT_APP_DEMO_PRO_MODE + url
      }
    }

    return await axios.post(
      API_URL, data, {

      'headers': {
        'method': 'post',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }

    ).then(function (response) {
      return response.data
    }).catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data.status_code === 401) {
          localStorage.removeItem("access_token");
          Store.dispatch({ type: "LOGIN_USER", payload: null })
          localStorage.removeItem("is_welcome")
          localStorage.removeItem("maintenance")
          // window.location.reload();
        }
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request", error.request);
      }
    })
  }
  if (IS_DEMO === "false" || !IS_DEMO) {
    //eslint-disable-next-line
    var API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE + url : process.env.REACT_APP_PRO_MODE + url
  } else {
    //eslint-disable-next-line
    var API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEMO_MODE + url : process.env.REACT_APP_DEMO_PRO_MODE + url
  }
  return await axios.get(
    API_URL,
    {
      headers: {
        'method': 'get',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(function (response) {
      return response.data
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data.status_code === 401) {
          sessionStorage.clear();
          localStorage.removeItem("access_token");
          localStorage.removeItem("is_welcome")
          localStorage.removeItem("maintenance")
          Store.dispatch({ type: "LOGIN_USER", payload: null });
          // window.location.reload();
        }
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      }
    });
} 