/*
 *   File : kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Kyc Details Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 18-01-2022
 */

/*import packages*/
import React, { useEffect, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import $ from 'jquery';

/*import componets*/
import { Input, Datepicker, FileUpload } from '../../components/formElements/index';
import Select from '../../components/dropdown/select';
import { PrimaryBtn, SecondaryBtn, } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/kyc.module.scss';

/*import assets */
import Trash from '../../assets/images/modal/trash-bin.png';

import services from '../../services/service';
import { Toast } from '../note';


export default function KycModal(props) {
	let navigate = useNavigate();

	const [pan, setPan] = useState("");
	const [aadhaar, setAadhar] = useState("");
	//eslint-disable-next-line
	const [uploadPan, setUploadPan] = useState(null);
	//eslint-disable-next-line
	const [uploadAadhar, setUploadAadhar] = useState(null);
	//eslint-disable-next-line
	const [selected, setSelected] = useState({});
	const [dob, setDob] = useState();
	const [panUrl, setPanUrl] = useState("");
	const [aadharUrl, setAadharUrl] = useState("");
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
	//eslint-disable-next-line
	const [upload, setUpload] = useState(false);
	//eslint-disable-next-line
	const [clear, setClear] = useState(false);
	const [panUploader, setPanUploader] = useState(false);
	const [aadhaarUploader, setAadhaarUploader] = useState(false);
	const [panName, setPanName] = useState("");
	const [aadhaarName, setAadhaarName] = useState("");

	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000)
		}
	}, [error])
	const options = [
		{
			value: 1, label: 'Male',
		},
		{
			value: 2, label: 'Female'
		},
		{
			value: 3, label: 'Other'
		},
	]

	useEffect(() => {
		if (props.data) {
			setSelected({ value: props.data.gender?.gender_id, label: props.data.gender?.gender });
			setPan(props.data.pan_number);
			setAadhar(props.data.aadhar_number);
			if (props.data.dob && props.data.dob !== '-') setDob(moment(props.data.dob, 'DD-MM-YYYY'));

		}
	}, [props.data])

	useEffect(() => {
		let modal = document.getElementById('kyc')
		modal.addEventListener('hidden.bs.modal', function (event) {
			props.modalClose();
			$("body").removeAttr("style");
			$(".fade").removeClass("modal-backdrop");
		})
		//eslint-disable-next-line
	}, [])

	/*----------update kyc api ----*/
	const _updateKyc = (isSkipped = false) => {
		setLoader(true);
		// var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/);
		// if (pattern.test(pan)) {
		let url = 'customer/profile/update-kyc';
		let data;
		console.log('dob', dob,)
		if (isSkipped) {
			data = JSON.stringify({
				gender_id: null,
				pan_number: null,
				aadhar_number: null,
				dob: null,
				pan_url: null,
				aadhar_url: null,
				skip_kyc: isSkipped
			})
		} else {
			data = JSON.stringify({
				gender_id: selected?.value ? selected?.value : null,
				pan_number: pan ? pan : null,
				aadhar_number: aadhaar ? aadhaar : null,
				dob: dob ? dob.format('YYYY-MM-DD') : null,
				pan_url: panUrl ? panUrl : null,
				aadhar_url: aadharUrl ? aadharUrl : null,
				skip_kyc: isSkipped
			})
		}
		services(true, url, data).then((response) => {

			if (response.status_code === 200) {
				// message.success("Details added successfully");
				if (props.kyc) {
					navigate('/profile');
					$(".fade").removeClass("modal-backdrop")
				}
				else if (props.signup === "true") {
					$('#close-btn').trigger('click');
					props.update();
				}
				else {
					if (response.data.bank_status === false) {
						// window.location.href = '/profile?tabIndex=4'
						$('#close-btn').trigger('click');
						$('#kyc').trigger('click');
						props.update();
					} else {
						$('#close-btn').trigger('click');
						$('#kyc').trigger('click');
						props.update();
					}
				}
				setLoader(false);
				setUpload(false)
				setSelected({});
				setDob(null);
				setPan("");
				setAadhar("");
				setAadharUrl("");
				setPanUrl("");
				setUploadPan(null);
				setUploadAadhar(null);
				// window.location = "/";
			} else {
				setError(response.message);
				setLoader(false);
			}
		});
		// } else {
		// 	setError("Enter valid pan number");
		// 	setLoader(false);
		// }
	}

	// const getFileName = (temp) => {

	// 	let names = temp.split('/');
	// 	console.log(names)
	// 	return names[1]
	// }

	return (
		<div className={`${styles.e_kyc_modal} e-kyc-modal modal fade`}
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			id="kyc"
			tabIndex="-1"
			aria-labelledby="kyc"
			aria-hidden="true">
			<div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
				<div className={`${styles.e_modal_content} modal-content`}>
					<div className={`${styles.e_modal_header} modal-header mb-2 px-2`}>
						<h4 className='e-secondary-color'>
							KYC details
						</h4>
						<button
							id="close-btn"
							type="button"
							className={localStorage.getItem("mode") === "true" ? "btn-close" : "btn-close-dark"}
							data-bs-dismiss="modal"
							aria-label="Close"
							onClick={() => setClear(true)}
						>
						</button>
					</div>
					<div className="container px-1">
						<div className="row">
							<div className='col-lg-6 col-md-6 e-select-gender'>
								<label className='mb-2 e-secondary-color'>
									Gender
								</label>
								<Select
									value={selected}
									options={options}
									placeholder="Gender"
									selectchange={(temp) => {
										setSelected(temp)
									}}
									isSearchable={false}
								/>
							</div>
							<div className='col-lg-6 col-md-6'>
								<label className='mb-2  e-secondary-color'>
									Date of birth
								</label>

								<Datepicker
									value={dob}
									id="e-dob"
									placeholder="dd-mm-yyyy"
									disabledDate={current => {
										return current > moment().subtract(18, "years").endOf('day')
									}}
									isDecadeBack={true}
									onChange={(dateString) => {
										console.log('data', dateString)
										setDob(dateString)
									}}
									className={`${styles.e_input_bg}`}
								/>
							</div>
							<div className='col-lg-6 col-md-6 pt-3 mt-2'>
								<label className='mb-2  e-secondary-color'>
									PAN card number
								</label>
								<Input
									isCapital={true}
									placeholder="PAN number"
									type="text"
									value={pan}
									valueChange={(value) => {
										setPan(value)
									}}
									className={`${styles.e_input_bg} e-bg-white`}
								/>
							</div>
							<div className='col-lg-6 col-md-6 pt-3 mt-2 position-relative'>
								<label className='mb-2  e-secondary-color'>
									Upload PAN
								</label>
								<div className="e-uploader" >
									{
										panUrl ?

											<p className='text-center mb-0'>{panName}
												<img src={Trash}
													draggable={false}
													alt=""
													className='e-upload-clear'
													onClick={() => {
														setUploadPan(false);
														setPanUrl("");
														setUpload(false)
													}}
												/>
											</p>
											:
											<p className='text-center mb-0'
												onClick={() => {
													$('#e-upload').click()
												}}>
												{
													panUploader ?
														<span className='e-btn-loader'>
															<span>
																Uploading</span>
														</span>
														:
														'Upload PAN (PDF, PNG, JPEG)'
												}
											</p>
									}
									<FileUpload
										accept="application/pdf, image/*"
										className="hidden"
										id="e-upload"
										key="pan-card"
										uploadStatus={(status) => setPanUploader(status)}
										uploadedFile={(temp, name) => {
											setPanUrl(temp);
											setPanName(name)
										}}
									/>
								</div>
							</div>
							<div className='col-lg-6 col-md-6 pt-3 mt-2'>
								<label className='mb-2  e-secondary-color'>
									Aadhaar number
								</label>
								<Input
									placeholder="Aadhaar number"
									type="number"
									value={aadhaar}
									valueChange={(value) => {
										setAadhar(value)
									}}
									blurEvent={true}
									onBlur={() => {
										if (aadhaar.length < 12) {
											setError('Aadhaar  number must have 12 digits')
										}
									}}
									className={`${styles.e_input_bg} e-bg-white`}
								/>
							</div>
							<div className='col-lg-6 col-md-6 pt-3 mt-2 position-relative'>
								<label className='mb-2  e-secondary-color'>
									Upload aadhaar
								</label>
								<div className="e-uploader" >
									{
										aadharUrl ?
											<p className='text-center mb-0'>
												{aadhaarName}
												<img src={Trash}
													draggable={false}
													alt=""
													className='e-upload-clear'
													onClick={() => {
														setUploadAadhar(false);
														setAadharUrl("");
													}}
												/>
											</p>
											:
											<p className='text-center mb-0' onClick={() => { $('#e-upload-aadhar').click() }}>
												{
													aadhaarUploader ?
														<span className='e-btn-loader'>
															<span>Uploading</span>
														</span>
														:
														'Upload Aadhaar (PDF, PNG, JPEG)'
												}

											</p>
									}
									<FileUpload
										accept="application/pdf, image/*"
										className="hidden"
										id="e-upload-aadhar"
										key="aadhaar-card"
										uploadStatus={(status) => setAadhaarUploader(status)}
										uploadedFile={(temp, name) => {
											setAadharUrl(temp);
											setAadhaarName(name)
										}}
									/>
								</div>
							</div>

						</div>
						<div className='row mt-5 pt-3'>
							<div className='col-lg-12'>
								<div className="d-flex justify-content-end">
									{
										props?.signup ?
											<SecondaryBtn
												label="Remind me later"
												type={2}
												className="me-3"
												// cancel="modal"
												handleClick={() => {
													_updateKyc(true);
												}}
											/>
											:
											<SecondaryBtn
												label={props?.signup === "true" ? "Remind me later" : "Cancel"}
												type={2}
												className="me-3 e-secondary-color"
												cancel="modal"
											/>
									}

									{
										selected?.value || dob ||
											pan || panUrl ||
											aadhaar || aadharUrl ?
											<PrimaryBtn
												label="Save details"
												type={2}
												className={loader ? "e-btn-loader ms-1" : "ms-1"}
												handleClick={() => _updateKyc()}
											/>
											:
											<PrimaryBtn
												label="Save details"
												type={2}
												className="ms-1 e-disabled"

											/>
									}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			{
				error !== null ?
					<Toast data={error}
						type="fail" />
					:
					null
			}
		</div>
	)
}

