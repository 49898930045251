// import { useState } from "react";
import { useEffect } from "react";
// import "react-step-progress-bar/styles.css";

// import styles
import style from '../../styles/components/progress.module.scss';

const Progress = (props) => {
	// const [show, setShow] = useState(false)

	useEffect(() => {
		if (props.percent) {
			setTimeout(() => {
				document.getElementById(props.id).style.width = props.percent + '%';
			}, 200)
		}
		//eslint-disable-next-line
	}, [props.percent])
	return (
		<div className={`${style.e_progress_track} position-relative`}>
			<div className={`${style.e_progress_bar}`} id={props.id ? props.id : 'progress'} ></div>
			{
				props.home ?
					<>
						<div className={style.e_progress_label} style={{ left: `${props.left}%` }} ></div>
						{/* {
							show ?
								<div style={{ position: "absolute", left: `${props.left}%`, top: "-20px", fontSize: "13px", color: "white" }}>Target points: {props.target}</div>
								:
								null
						} */}
					</>
					:
					null
			}
		</div>
	);
}
export default Progress;