/*import package*/
import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Modal } from 'bootstrap';
import OneSignal from 'react-onesignal';
import $ from 'jquery';
// Firebase 
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


/*import components*/
import { GameDetailModal, GameDetailM2MModal, GameDetailSkillRaceModal, GameDetailV2rModal, GameDetailV2rModalWeekly, GameDetailV2rModalBiweekly, GameDetailV2rModalMonthly } from './components/modal/index';

import services from './services/service.js';


/*import containers */

import { BlockedModal } from './components/modal';
import { FireBaseContext } from './context/firebaseContext.js';
import devconfig from './config/config';
import { Loader } from './components/loader';
import { useSelector } from 'react-redux';
import { Store } from './store';

const Login = lazy(() => import('./containers/auth/index.js'));
const Signup = lazy(() => import('./containers/auth/signup.js'));
const Home = lazy(() => import('./containers/home/index.js'));
const M2EGameDetail = lazy(() => import('./containers/home/m2e-game-detail.js'));
const M2MGameDetail = lazy(() => import('./containers/home/m2m-game-detail.js'));
const SkillRaceDetail = lazy(() => import('./containers/home/skill-race-detail.js'));
const V2RDetail = lazy(() => import('./containers/home/v2r-detail.js'));
const Profile = lazy(() => import('./containers/profile/index.js'));
const Bookings = lazy(() => import('./containers/profile/bookings.js'));
const Footer = lazy(() => import('./components/nvaigation/footer.js'));
const InnerHeader = lazy(() => import('./components/nvaigation/inner-header.js'));
const Wallet = lazy(() => import('./containers/wallet/index.js'));
const Scrips = lazy(() => import('./containers/scrips/index.js'));
const ScripVoting = lazy(() => import('./containers/scrips/scrip-voting.js'));
const ScripDetail = lazy(() => import('./containers/scrips/scrip-detail.js'));
const TradingViewChart = lazy(() => import('./containers/scrips/tradingview-chart.js'));
const Faq = lazy(() => import('./containers/other/faq.js'));
const PrivacyPolicy = lazy(() => import('./containers/other/privacy-policy.js'));
const TermsConditions = lazy(() => import('./containers/other/terms-condition.js'));
const M2EResults = lazy(() => import('./containers/m2e/m2e-results.js'));
const GlobalLeaderboard = lazy(() => import('./containers/other/global-leaderboard.js'));
const ResultHistory = lazy(() => import('./containers/result-history/index.js'));
const M2EUserResults = lazy(() => import('./containers/m2e/m2e-user-results.js'));
const PageNotFound = lazy(() => import('./containers/404/404.js'));
const V2RHeader = lazy(() => import('./components/nvaigation/v2r-header'));


const App = React.memo(function App(props) {

	const dispatch = useDispatch();
	const AUTH_TOKEN = useSelector(state => state.EQUPLUS.USER_TOKEN);
	const THEME = useSelector(state => state.EQUPLUS.THEME);
	const [check, setCheck] = useState(localStorage.getItem("mode"));
	const [profileData, setProfileData] = useState({});
	const [gameData, setGameData] = useState({});
	//eslint-disable-next-line
	const [loader, setLoader] = useState(false);
	const [lastUpdate, setLastUpdate] = useState(new Date());
	const [balance, setBalance] = useState("");
	const [status, setStatus] = useState(false);
	const [added, setAdded] = useState(false);
	const [mobile, setMobile] = useState(null);
	//eslint-disable-next-line
	const [analytics, setAnalytics] = useContext(FireBaseContext);


	const updateWalletUpdateTime = () => {
		setLastUpdate(new Date());
	}

	if (THEME === "light") {
		$("body").addClass("light");
	} else if (THEME === "dark") {
		$("body").removeClass("light");

	}

	useEffect(() => {
		if (localStorage.getItem("access_token")) {
			Store.dispatch({
				type: 'LOGIN_USER',
				payload: localStorage.getItem("access_token")
			})
		}
		//eslint-disable-next-line
	}, [localStorage.getItem("access_token")]);

	useEffect(() => {

		OneSignal.init({
			// appId: '1788cec7-0782-43dc-9601-175b1f4fabee' // http://evoque-equplus.surge.sh/skill-race
			// appId: "b7c6d2a1-e344-43a2-b13d-e2674faf1c96" //localhost 3001
			// appId: "82dad1dc-60b7-43c8-9071-505441e48134" //localhost
			appId: devconfig.REACT_APP_ONESIGNAL_APP_ID
		});
	}, []);

	//initializing firebase analytics
	useEffect(() => {
		const firebaseConfig = {
			apiKey: "AIzaSyDUl8zWs50lRWCfYGcBQUcqp0DdsrZetO4",
			authDomain: "stocksports-8f237.firebaseapp.com",
			projectId: "stocksports-8f237",
			storageBucket: "stocksports-8f237.appspot.com",
			messagingSenderId: "710512680762",
			appId: "1:710512680762:web:ef0f3959bbc3742d767a03",
			measurementId: "G-FFFY360GYL"
		};

		// Initialize Firebase
		const app = initializeApp(firebaseConfig);
		const analyticsTemp = getAnalytics(app);
		setAnalytics(analyticsTemp);
	});

	useEffect(() => {
		const itemStr = sessionStorage.getItem('state_checked')
		const item = JSON.parse(itemStr)
		if (item) {
			const now = new Date()
			if (now.getTime() > item.expiry) {
				sessionStorage.removeItem('state_checked');
				getIPDetails();
			}
		}
		else {
			getIPDetails();
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (JSON.parse(localStorage.getItem("mode")) === true) {
			dispatch({ type: "SET_THEME", payload: "dark" })
			// setCheck(JSON.parse(localStorage.getItem("mode"))
		} else if (JSON.parse(localStorage.getItem("mode")) === false) {
			dispatch({ type: "SET_THEME", payload: "light" })
			// setCheck(JSON.parse(localStorage.getItem("mode")));
		} else {
			dispatch({ type: "SET_THEME", payload: "light" })
		}

		//eslint-disable-next-line
	}, [localStorage.getItem("mode")]);



	// get user   ip details
	const getIPDetails = () => {
		fetch('https://ipapi.co/json/').then(function (response) {
			if (response) {
				response.json().then(jsonData => {
					checkGameAvailable(jsonData.ip, jsonData.region);

				});
			}
		}).catch(function (error) {
		});
	}

	const checkGameAvailable = (ip, state) => {
		let url = 'customer/auth/check-blocked-states';
		let data = JSON.stringify({
			ip,
			state
		});
		services(true, url, data).then((response) => {
			if (response.status_code === 200) {
				const now = new Date();
				const item = {
					blocked: response.data.blocked,
					expiry: now.setHours(23, 59, 59, 59),
				}
				sessionStorage.setItem('state_checked', JSON.stringify(item))
				if (response.data.blocked) {
					const modal = new Modal(document.getElementById("blocked-state"), {});
					modal.show();
				}
			}
		});
	}

	return (
		<>
			<Suspense fallback={
				<div style={{ marginTop: "20%" }}>
					<Loader />
				</div>
			}>
				<div className='container-fluid gx-0'>
					{
						mobile ?
							null
							:
							<Router>
								{
									AUTH_TOKEN ?
										window.location.pathname === "/v2r" ?
											<V2RHeader
												Check={(data) => {
													localStorage.setItem("mode", data);
													if (data === false) {
														dispatch({ type: "SET_THEME", payload: "light" })
													} else {
														dispatch({ type: "SET_THEME", payload: "dark" })
													}
													setCheck(data);

												}}
												balance_set={(temp) => {
													setBalance(temp.balance)
												}}
												profileData={(data) => {
													setProfileData(data)
												}}
												gameData={(data) => {
													setGameData(data)
												}}
												loader={(data) => {
													setLoader(data);
												}}
												status={status}
												added={added}
												lastUpdate={lastUpdate}
												balance={balance} />
											:
											<InnerHeader
												Check={(data) => {
													localStorage.setItem("mode", data);
													setCheck(data);

												}}
												balance_set={(temp) => {
													setBalance(temp.balance)
												}}
												profileData={(data) => {
													setProfileData(data)
												}}
												gameData={(data) => {
													setGameData(data)
												}}
												loader={(data) => {
													setLoader(data);
												}}
												status={status}
												added={added}
												lastUpdate={lastUpdate}
												balance={balance} />
										:
										null
								}
							</Router>
					}
				</div>
				<div className='container-fluid p-0'>
					{
						AUTH_TOKEN ?
							<>
								<Router>
									<div className={mobile ? 'e-body pt-0' : 'e-body'}>
										<Routes>
											<Route
												path="/"
												element={
													<Home
														profile={profileData}
													/>
												}
											/>

											<Route exact
												path="/profile"
												element={
													<Profile
														profile={profileData}
														update={(data) => {
															setStatus(data);
															setTimeout(() => {
																setStatus(false)
															}, 3000)
														}}
														Added={() => {
															setAdded(true)
														}}
													/>
												}
											/>
											<Route exact
												path="/wallet"
												element={
													<Wallet profile={profileData}
														balance={(data) => {
															setBalance(data)
														}}
														Added={(data) => {
															setAdded(true)
														}}
													/>
												}
											/>
											<Route exact
												path="/scrips"
												element={
													<Scrips />
												}
											/>
											<Route exact
												path="/m2e"
												element={
													<M2EGameDetail
														updateWalletUpdateTime={() => {
															updateWalletUpdateTime()
														}}
													/>
												}
											/>
											<Route exact
												path="/m2m"
												element={
													<M2MGameDetail
														updateWalletUpdateTime={() => {
															updateWalletUpdateTime()
														}}
													/>
												}
											/>
											<Route exact
												path="/skill-race"
												element={
													<SkillRaceDetail
														balance={balance}
														updateWalletUpdateTime={() => {
															updateWalletUpdateTime()
														}}
													/>
												}
											/>
											<Route exact
												path="/v2r"
												element={
													<V2RDetail
														balance={balance}
														updateWalletUpdateTime={() => {
															updateWalletUpdateTime()
														}}
														theme={check}
													/>
												}
											/>
											<Route exact
												path="/bookings"
												element={
													<Bookings />
												}
											/>
											<Route exact
												path="/scrip-voting"
												element={
													<ScripVoting />
												}
											/>
											<Route exact
												path="/scrip-detail"
												element={
													<ScripDetail
														theme={check}
													/>
												}
											/>
											<Route exact
												path="/global-leaderboard"
												element={
													<GlobalLeaderboard />
												}
											/>
											<Route exact
												path="/results-history"
												element={
													<ResultHistory />
												}
											/>
											<Route exact
												path="/scrip-chart"
												element={
													<TradingViewChart
														setMobile={(data) => {
															setMobile(data)
														}}
														theme={check}
													/>
												}
											/>
											<Route exact
												path="/faq"
												element={
													<Faq />
												}
											/>
											<Route exact
												path="/privacy-policy"
												element={
													<PrivacyPolicy />
												}
											/>
											<Route exact
												path="/terms-conditions"
												element={
													<TermsConditions />
												}
											/>
											<Route exact
												path="/results"
												element={
													<M2EResults
													/>
												}
											/>
											<Route exact
												path="/m2e-user-results"
												element={
													<M2EUserResults
													/>
												}
											/>
											<Route path="*"
												element={
													<PageNotFound />
												}
											/>
										</Routes>
									</div>
									{/* v2r game detail modal */}
									<GameDetailV2rModal
										title="V2R Daily"
										v2r={gameData.v2r_daily}
									/>

									<GameDetailV2rModalWeekly
										title="V2R Weekly"
										v2r={gameData.v2r_weekly}
									/>
									<GameDetailV2rModalBiweekly
										title="V2R Bi-weekly"
										v2r={gameData.v2r_bi_weekly}
									/>
									<GameDetailV2rModalMonthly
										title="V2R Monthly"
										v2r={gameData.v2r_monthly}
									/>
								</Router>

								{/* M2e game detail modal */}
								<GameDetailModal
									m2e={gameData.m2e}
								/>

								{/* M2m game detail modal */}
								<GameDetailM2MModal
									m2m={gameData.m2m}
								/>

								{/* Skillrace game detail modal */}
								<GameDetailSkillRaceModal
									skill_race={gameData.skillrace}
								/>



							</>
							:
							<>
								<Router>
									<Routes>
										<Route path="*"
											element={
												<Login />
											}
										/>
										<Route exact
											path="/"
											element={
												<Login />
											}
										/>
										<Route exact
											path="/signup"
											element={
												<Signup />
											}
										/>
										<Route exact
											path="/scrip-chart"
											element={
												<TradingViewChart
													setMobile={(data) => {
														setMobile(data)
													}}
													theme={check} />
											}
										/>
									</Routes>
								</Router>
								{/* <Footer/> */}
							</>
					}
				</div>
				{
					mobile ?
						null
						:

						AUTH_TOKEN ?
							<Footer
								show_m2e={
									gameData.m2e?.settings?.active_web === false ?
										false
										:
										true
								}
								show_m2m={
									gameData.m2m?.settings?.active_web === false ?
										false
										:
										true
								}
								show_skillrace={
									gameData.skillrace?.settings?.active_web === false ?
										false
										:
										true
								}
								show_v2r={
									devconfig.SHOW_V2R === "true" ?
										true
										:
										false
								} />
							:
							null

				}
				<BlockedModal />
			</Suspense>
		</>
	)
})

export default App;