
import { all, fork } from "redux-saga/effects";

import { _login } from "./login";
import { _getBalance } from "./balance";
import { _theme } from "./theme";

export function* rootSaga() {
    yield all([
        fork([
            _login(),
            _getBalance(),
            _theme()
        ])
    ]);
}